import { useState } from "react";
import { Col, Modal, ProgressBar, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import JokerIcon from '../../asserts/IMAGES/joker-icon.svg'
import JokerIconModal from '../../asserts/IMAGES/logo-icon.svg'
import LaunchImage from '../../asserts/IMAGES/launch-image.png'

function LaunchPad({balances}) {
    const [show, setShow] = useState(false);
    const [showsm, setShowSM] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseSM = () => setShowSM(false);
    const handleShowSM = () => {setShowSM(true); setShow(false)};
    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <div className="py-md-5 py-4 p-24 app-contain">
                <Row className="justify-content-center">
                    <Col md={8} lg={6}>
                        <div className="box p-4 launch-pad">
                            <div className="text-center">
                                <img src={LaunchImage} alt="LaunchImage" className="img-fluid" />
                            </div>

                            <div className="launch-pad-heading mb-3 flex g-3">
                                <div>
                                    <img src={JokerIcon} style={{minWidth: '42px'}} alt="Icon1" />
                                </div>
                                <div>
                                    <h4 className="mb-1">Launch Pad</h4>
                                    <h5 className="text-gray mb-0">Joker</h5>
                                </div>
                            </div>

                            <div className="launch-pad-card mb-3">
                                <div className="flex border-bottom justify-content-between p-3">
                                    <span>Total Sale</span>
                                    <span>100000 Joker</span>
                                </div>
                                <div className="p-3 d-flex flex-column">
                                    <div className="d-flex w-100 justify-content-between">
                                        <span className="text-gray">Starts On</span>
                                        <span className="text-end d-flex flex-column">25 - Feb -2024 <small className="text-12 text-gray">02:00 PM</small></span>
                                    </div>
                                    <div className="d-flex w-100 justify-content-between">
                                        <span className="text-gray">Ends On</span>
                                        <span className="text-end d-flex flex-column">25 - Feb -2024 <small className="text-12 text-gray">02:00 PM</small></span>
                                    </div>
                                </div>
                            </div>

                            <button className="btn btn-grad w-100" onClick={handleShow}>Participate</button>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal show={show} size="lg" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><img src={JokerIconModal} alt="JokerIconModal" /> Joker Protocol</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-4 justify-content-between">
                        <Col xs={'auto'}>
                            <h5 className="mb-2 text-gray">Minimum Stake amount</h5>
                            <h4 className="mb-0">0.00001 ETH</h4>
                        </Col>
                        <Col xs={'auto'} className="text-end">
                            <h5 className="mb-2 text-gray">Your Balance</h5>
                            <h4 className="mb-2">0.00 ETH</h4>
                            <h4 className="mb-0">0.00 JOKER</h4>
                        </Col>
                    </Row>
                    <Row className="mb-4 justify-content-between">
                        <Col xs={'auto'}>
                            <h4 className="mb-0 text-green-light">Sales in Progress</h4>
                        </Col>
                        <Col xs={'auto'} className="text-end">
                            <h5 className="mb-2 text-gray">Time Left</h5>
                            <h4 className="mb-0">0.30d : 5h : 10m : 49s</h4>
                        </Col>
                    </Row>
                    <div className="py-3">
                        <div className="flex text-600 mb-2 text-14 text-gray justify-content-between">
                            <span>Start</span>
                            <span>End</span>
                        </div>
                        <ProgressBar now={60} />
                        <div className="flex text-600 mt-2 text-14 text-gray justify-content-between">
                            <span>0%</span>
                            <span>0/10 ETH</span>
                        </div>
                    </div>
                    <div className="text-end">
                        <button className="btn btn-grad btn-xs" onClick={handleShowSM}>Participate</button>
                    </div>
                    <div className="mb-4">
                        <h5 className="mb-2 text-gray">Your Contribution</h5>
                        <h4 className="mb-0">0 ETH</h4>
                    </div>
                    <div>
                        <h5 className="mb-2 text-gray">Total Allocation</h5>
                        <h4 className="mb-0">100000 JOKER</h4>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showsm} centered onHide={handleCloseSM}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <div className="mb-md-5 mb-4">
                            <img src={JokerIcon} width={60} className="mb-20" alt="JokerIcon" />
                            <h3>JOKER</h3>
                            <h4 className="text-gray text-uppercase">SALE</h4>
                        </div>

                        <div className="py-3">
                            <h4 className="text-gray">spendable ETH Balance: 0.00 ETH</h4>
                            <h4 className="text-gray">Minimum Stake Amount: 0.0001 ETH</h4>
                        </div>
                    </div>
                    <div className="swap-card p-3 bg-body mb-4">
                        <input placeholder="0.00" className="input-reset" />
                    </div>

                    <button className="btn btn-grad w-100" onClick={handleCloseSM}>Participate</button>    
                </Modal.Body>
            </Modal>
        </LayoutAfterLogin>
    );
}

export default LaunchPad;