import { Col, Row, Table } from "react-bootstrap";
import Icon1 from '../../asserts/IMAGES/joker-icon.svg';
import Icon2 from '../../asserts/IMAGES/status-icon2.svg';
import Icon3 from '../../asserts/IMAGES/credits-icon.svg';
import Icon4 from '../../asserts/IMAGES/treasury-icon.svg';
import jokerLogo from '../../asserts/IMAGES/jokerRedLogo.svg';
import dimeLogo from '../../asserts/IMAGES/dimeCyanLogo.svg';
import creditsLogo from '../../asserts/IMAGES/creditsBlackLogo.svg';
import WaveLines from '../../asserts/IMAGES/wave-lines.svg';
import ProgressBar from "./Snippets/CircleTimer";
import Timer from "./Snippets/Timer";
import Traju from "../../asserts/IMAGES/traju.png";
import JockerCard from "./Snippets/JokerCard";
import CreditsCard from "./Snippets/CreditsCard";
import DimeCard from "./Snippets/DimeCard";
import DimeCreditJokerCard from "./Snippets/DimeCreditJoker";
import TreasuryCard from "./Snippets/TreasuryCard";
import { LineChart } from "./Snippets/LineChart";
import tauIcon from '../../asserts/IMAGES/tau-original.png';
import stasisImage from "../../asserts/IMAGES/stasisimage.png";
import ChartComponent from './Snippets/PieChart2';
import TransferIcon from '../../asserts/IMAGES/transfer-icon.svg';
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
// import fireDb from "../../abi/firebase";

import { useState, useEffect } from "react";

import { CheckAllowance, DecimalPointer, NumberAbbreviation, PendingModal, getCreditsPrice, getDAIPrice, getDimeContractDetails, getDimePrice, getJokerContractDetails, getJokerPrice, getJokerSupply, getTAUPrice, getTauSupply, getTokenBalance } from "../../abi/CommonSolFunctions";
import { CHAIN_URL, TAU_UFragmentPolicy_Address, TAU_UFragmentPolicy_ABI, DIME_TREASURY_ABI, DIME_TREASURY_Address, ECO_Reserve_Treasury_Address, Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, DAI_Chainlink_Oracle_Address, DIME_Token_Address, DIME_Token_ABI, DIME_Chainlink_Oracle_Address, JOKER_Token_ABI, JOKER_Token_Address, JOKER_Chainlink_Oracle_Address,  CREDITS_Token_Address, CREDITS_Token_ABI, CREDITS_Chainlink_Oracle_Address, API_KEY, Network_Name, Burn_Vault_Address, Zero_Address, Treasury_wallet_address, Credits_Pool_Address, Communitywallet_Address, DIME_LP_Token_Address, LP_Token_ABI, ADSB_1_Address, ADSB_1_ABI, pancakeOracleAddress, pancakeOracleABI, TXN_ID } from "../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, createTxnDimePrice, fetchLast10Data, gepriceIncrease, getPriceIncreaselast7days, getPriceOneWeek, recordUserVisits } from "../../abi/firebasecode";
import { CircularProgressbar } from "react-circular-progressbar";
import ChartIcon from '../../asserts/IMAGES/chatJokericon.svg';
import BalanceScale from "./Snippets/balanceScaleJoker&Dime";
import DimeBalanceScale from "./Snippets/balanceScaleDime";
import { useDimeMarketCapContext, useJokerMarketCapContext, usePriceContext } from "../../abi/usingContext";

function Stasis({ selectedTab }) {
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    const { address,isConnected } = useWeb3ModalAccount();
    const[jokerPrice,setjokerPrice] = useState(0);
    const[dimePrice,setdimePrice] = useState("");
   

    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);
    const [Oneyeardates1, setOneyeardates1] = useState(0);
    const [Oneyearprices1, setOneyearprices1] = useState(0);
    

    
    const[joker1Month,setjoker1Month] = useState("");
    const[dime1month,setDime1Month] = useState("");

    const { tokenPrice, settokenPrice } = usePriceContext();
    console.log("fullprice",tokenPrice)

    const { DimeMarketCap, setDimeMarketCap } = useDimeMarketCapContext();
    const { JokerMarketCap, setJokerMarketCap} = useJokerMarketCapContext();

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const [dimeTxn, setdimeTxn] = useState([]);
    const [jokerTxn, setjokerTxn] = useState([]);

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);


    const { walletProvider } = useWeb3ModalProvider();

    const fetchtxnHash = async() =>{
        let dimetokentxn = await fetchLast10Data("DIME");
        setdimeTxn(dimetokentxn)
        let jokertokentxn = await fetchLast10Data("JOKER");
        setjokerTxn(jokertokentxn)
        console.log("dimetokentxn",dimetokentxn);
    }

    useEffect(()=>{fetchtxnHash()},[])

    const getAssetDetails = async() =>{  
        try{        
      
          let jokerprice =await  getJokerPrice();
          setjokerPrice(jokerprice);
          let dimeprice = await getDimePrice();
          setdimePrice(dimeprice);
            
        } catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{getAssetDetails()},[]);

  


    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }
        // Calculate elapsed percentage
    // useEffect(() => {
    //     const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    //     const elapsedSeconds = currentTime - dimeRebaseEpoch;
    //     const totalSeconds = 21600; // Total seconds for dimeRebaseEpoch + 21600

    //     // Calculate percentage
    //     const percentage = (elapsedSeconds / totalSeconds) * 100;
    //     setElapsedPercentage(percentage);

    //     const currentTime2 = Math.floor(Date.now() / 1000); // Current time in seconds
    //     const elapsedSeconds2 = currentTime2 - (tauRebaseEpoch);
    //     const totalSeconds2 = 86400; // Total seconds for dimeRebaseEpoch + 21600

    //     // Calculate percentage
    //     const percentage2 = (elapsedSeconds2 / totalSeconds2) * 100;
    //     setElapsedPercentage2(percentage2);
    // }, []);

    const getallvalues = async() =>{
        let oneyearvalue = await getPriceOneWeek("JokerPrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices(prices)
        setjoker1Month(await getPriceIncreaselast7days("JokerPrice","1month"));
      }
      useEffect(()=>{getallvalues()},[])

      const getallvalues1 = async() =>{
        let oneyearvalue = await getPriceOneWeek("DimePrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates1(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices1(prices)
        setDime1Month(await getPriceIncreaselast7days("DimePrice","1month"));
      }
      useEffect(()=>{getallvalues1()},[])
    
      

    const dynamicPrinciple2 = async(principle) => {
        if (isConnected) {
            try {
               
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = ethersProvider.getSigner()
                const StabilserContract = new ethers.Contract(ADSB_1_Address, ADSB_1_ABI, signer);
                console.log("StabilserContract",StabilserContract)
                await showPopUp("yes", TransactionPending, "", "");

                

                let lastprice ;
                let chainlinkPricecontract = new ethers.Contract(pancakeOracleAddress,pancakeOracleABI,provider);
                if(principle === 1){
                    lastprice = ethers.utils.formatUnits(await chainlinkPricecontract.getDimePrice(), 0);
                }else if(principle ===2){
                    lastprice = ethers.utils.formatUnits(await chainlinkPricecontract.getJokerPrice(), 0);
                }
                
                const depositTx = await StabilserContract.checkEquilibrium();         
                await depositTx.wait();             
                console.log("depositTx", depositTx.hash);
                await getAssetDetails();
                await TxnId_PopUp(depositTx.hash);

                let updatedPrice;
                if(principle === 1){
                    updatedPrice = ethers.utils.formatUnits(await chainlinkPricecontract.getDimePrice(), 0);
                    await createTxnDimePrice("DIME",depositTx.hash,updatedPrice,lastprice);
                }else if(principle ===2){
                    updatedPrice = ethers.utils.formatUnits(await chainlinkPricecontract.getJokerPrice(), 0);
                    await createTxnDimePrice("JOKER",depositTx.hash,updatedPrice,lastprice);
                }

                // await balanceOfTokens();
               
            } catch (err) {
                console.log("error", err);
                showPopUp("not", TransactionPending, "", (err.reason).toString());
                
            }
        }
    }
    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
    }

    const formatEntryTime = (timestamp) => {
        const currentTime = new Date().getTime();
        const timeDifference = (currentTime - timestamp * 1000) / 1000;
    
        const secondsAgo = Math.floor(timeDifference);
        const minutesAgo = Math.floor(timeDifference / 60);
        const hoursAgo = Math.floor(timeDifference / 3600);
        const daysAgo = Math.floor(timeDifference / (3600 * 24));
    
        if (daysAgo > 0) {
            return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
        } else if (hoursAgo > 0) {
            return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
        } else if (minutesAgo > 0) {
            return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
        } else {
            return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
        }
    };
    
      

    
    return ( 
        <div className="p-24 app-contain">
                <Row className="g-3 mb-24">        
                <BalanceScale />
         
            </Row>
            <Row className="g-3 mb-24">
               
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="mb-3 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                                <img src={dimeLogo}  height={'42px'} style={{minWidth: '42px'}} alt="jokerLogo" />
                            </Col>
                            <Col>
                                <div className="status-card h-100 d-flex align-items-center">
                                    <div>
                                        <h5>$
                                        <DecimalPointer number={tokenPrice.dimePrice ? (tokenPrice.dimePrice/1e18).toFixed(18) : "0.00"}/>
                                        </h5>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="sc-chart">
                                        <LineChart color={dime1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates1?Oneyeardates1:'0'} prices={Oneyearprices1?Oneyearprices1:'0'} name="Dime"/>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                <button className="btn btn-grad py-2" disabled={ parseFloat(JokerMarketCap) > parseFloat(2*DimeMarketCap) ? false : true } 
                                 onClick={()=>dynamicPrinciple2(1)}>DY 1</button>
                                {/* <button class="btn pb-1  outline btn-sm"  disabled={ parseFloat(JokerMarketCap) > parseFloat(2*DimeMarketCap) ? false : true } 
                                onClick={()=>dynamicPrinciple2(1)}>DY 1</button> */}
                                </div>
                            </Col>
                        </Row>
                        
                        
                        <hr className="my-md-3 my-2" />
                        <h5 className="text-center">Buyback DIME by burning JOKER</h5>
                        <Table responsive striped hover>
                        <thead>
                            <tr>
                                {/* <th><div className="sort float-end">Contract</div></th> */}
                                <th><div className="sort">Last Price</div></th>                                                             
                                <th><div className="sort">Txn Hash</div></th>
                                <th><div className="sort float-end">Created</div></th> 
                                <th><div className="sort">Updated Price </div></th>
                            </tr>
                        </thead>
                        <tbody>
                      
                          {dimeTxn[0]?(<>
                          {dimeTxn.map((data,i)=>{
                            return(<tr> 
                                <td>$<DecimalPointer number={data.lastPrice ? (data.lastPrice/1e18) : "0.00"}/></td>                                                                
                                <td><button type="button" className="btn-link"><a href={`https://sepolia.etherscan.io/tx/${data.txnHash}`} target="_black" rel="noreferrer">{data.txnHash.substring(0, 4)}...{data.txnHash.slice(-4)}</a></button></td>
                                <td className="text-end">{formatEntryTime(data.entryTime)}</td> 
                                <td className="text-center">$<DecimalPointer number={data.UpdatedPrice ? (data.UpdatedPrice/1e18) : "0.00"}/></td>                                 
                            </tr>)
                            
                          })}</>):(<></>)}
                            
                          
                        </tbody>
                    </Table>
                    <h5 className="text-center pt-3"><button type="button" className="btn-link"><a href={`https://sepolia.etherscan.io/address/${ADSB_1_Address}`} target="_black" rel="noreferrer">Click to view full txn history</a></button> </h5>
                    
                    </div>
                </Col>
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="mb-3 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                                <img src={jokerLogo} height={'42px'}  style={{minWidth: '42px'}} alt="jokerLogo" />
                            </Col>
                            <Col>
                                <div className="status-card h-100 d-flex align-items-center">
                                    <div>
                                        <h5>$
                                            
                                            <DecimalPointer number={tokenPrice.jokerPrice ? (tokenPrice.jokerPrice/1e18) : "0.00"}/>
                                            </h5>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="sc-chart">
                                        <LineChart color={joker1Month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name="Joker"/>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">

                                <button className="btn btn-grad py-2" disabled={ parseFloat(JokerMarketCap) > parseFloat(2*DimeMarketCap) ? true : false} 
                                    onClick={()=>dynamicPrinciple2(2)} >DY 2</button>
                                    {/* <button className="btn  py-0 btn-grad w-100" disabled={ parseFloat(JokerMarketCap) > parseFloat(2*DimeMarketCap) ? true : false} 
                                    onClick={()=>dynamicPrinciple2(2)} >Dynamic Principle 2</button> */}
                                    {/* <h5>Total Tax </h5>
                                    <h6>$<NumberAbbreviation number={formatValues(taxRevenue*jokerPrice/1e17)}/> </h6> */}
                                </div>
                            </Col>
                        </Row>
                      
                        <hr className="my-md-3 my-2" />
                                <h5 className="text-center">Buyback JOKER by burning DIME</h5>
                        <Table responsive striped hover>
                        <thead>
                            <tr>
                                {/* <th><div className="sort float-end">Contract</div></th> */}
                                <th><div className="sort">Last Price</div></th>                                                             
                                <th><div className="sort">Txn Hash</div></th>
                                <th><div className="sort float-end">Created</div></th> 
                                <th><div className="sort">Updated Price</div></th>
                            </tr>
                        </thead>
                        <tbody>
                      
                        {jokerTxn[0]?(<>
                          {jokerTxn.map((data,i)=>{
                            return(<tr> 
                                <td>$<DecimalPointer number={data.lastPrice ? (data.lastPrice/1e18) : "0.00"}/></td>                                                                
                                <td><button type="button" className="btn-link"><a href={`https://sepolia.etherscan.io/tx/${data.txnHash}`} target="_black" rel="noreferrer">{data.txnHash.substring(0, 4)}...{data.txnHash.slice(-4)}</a></button></td>
                                <td className="text-end">{formatEntryTime(data.entryTime)}</td> 
                                <td className="text-center">$<DecimalPointer number={data.UpdatedPrice ? (data.UpdatedPrice/1e18) : "0.00"}/></td>                                 
                            </tr>)
                            
                          })}</>):(<></>)}
                            {/* <tr> 
                                <td>28083682</td>                                                                
                                <td><button type="button" className="btn-link">0xba...atwd</button></td>
                                <td className="text-end">2 minutes ago</td> 
                                <td className="text-center">28083682</td>                                 
                            </tr>
                            <tr> 
                                <td>28083682</td>                                                                
                                <td><button type="button" className="btn-link">0xba...atwd</button></td>
                                <td className="text-end">2 minutes ago</td> 
                                <td className="text-center">28083682</td>                                 
                            </tr>
                            <tr> 
                                <td>28083682</td>                                                                
                                <td><button type="button" className="btn-link">0xba...atwd</button></td>
                                <td className="text-end">2 minutes ago</td> 
                                <td className="text-center">28083682</td>                                 
                            </tr> */}
                          
                        </tbody>
                    </Table>
                    <h5 className="text-center pt-3"><button type="button" className="btn-link"><a href={`https://sepolia.etherscan.io/address/${ADSB_1_Address}`} target="_black" rel="noreferrer">Click to view full txn history</a></button> </h5>
                    </div>
                </Col>
             
            </Row>

        
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>


           
               
        </div>
     );
}

export default Stasis;