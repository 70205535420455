import { Col, Row, Dropdown } from "react-bootstrap";
import DollerIcon from '../../../asserts/IMAGES/doller-icon.svg';
import DaiIcon from '../../../asserts/IMAGES/dai.svg';
import Icon3 from '../../../asserts/IMAGES/creditsBlackLogo.svg';
import Tooltip from "../../../Snippets/Tooltip";
import TransactionPending from '../../../asserts/IMAGES/transsationPending.svg';
import { useState, useEffect } from "react";
import creditslogo from '../../../asserts/IMAGES/creditsBlackLogo.svg';

import { CheckAllowance, PendingModal, SuccessModal, getGasBalance, getTokenBalance, convertEpochToDateTime, NumberAbbreviation } from "../../../abi/CommonSolFunctions";
import { CHAIN_URL, JOKER_Token_ABI, JOKER_Token_Address, TXN_ID,  Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, Carbonix_Finance_Address, Carbonix_Finance_ABI, DIME_Chainlink_Oracle_Address, DAI_Chainlink_Oracle_Address, DIME_Token_ABI, DIME_Token_Address, JOKER_Chainlink_Oracle_Address, CREDITS_Token_Address, CREDITS_Token_ABI, API_KEY, Network_Name } from "../../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, recordUserVisits } from "../../../abi/firebasecode";
import { NumericFormat } from "react-number-format";

function Repay({ selectedTab, balanceOfTokens }) {

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
        
    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    const[daiBalance,setdaiBalance] = useState("")
    const[creditsBalance,setCreditsBalance] = useState("");
    const[dimePrice,setdimePrice] = useState("")
    const[daiPrice,setdaiPrice] = useState("")
    const[allowan,setAllowance] = useState("")
    const[allowan2,setAllowance2] = useState("")


    const[repayAmt, setrepayAmt] = useState(0.00);
    const[userDeposit,setUserDeposit] = useState(0);
    const[userDebt,setUserDebt] = useState(0);
    const[selectedCoin,setSelectedCoin] = useState("DAI");
    const[isStableCoin,setisStableCoin] = useState(true);

    const getAssetDetails = async() =>{
        if(selectedTab === 'Repay')
            await recordUserVisits(address, "Vault - Repay");
        
            if(isConnected){
                console.log("useeffect repay");
                try{
              const DimePriceContract = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
              const DaiPriceContract = new ethers.Contract(DAI_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
              const daiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
              const creditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
              const CarbonixContract = new ethers.Contract(Carbonix_Finance_Address, Carbonix_Finance_ABI, provider);
      
              let dimeprice = ethers.utils.formatUnits(await DimePriceContract.getChainlinkDataFeedLatestAnswer(),0);
              setdimePrice(dimeprice);
              let daiprice = ethers.utils.formatUnits(await DaiPriceContract.getChainlinkDataFeedLatestAnswer(),0);
              setdaiPrice(daiprice);
              
              let allowance =  ethers.utils.formatUnits(await daiContract.allowance(address,Carbonix_Finance_Address),0);
              console.log("allowance", allowance)
              setAllowance(allowance);
      
              let allowance2 =  ethers.utils.formatUnits(await creditsContract.allowance(address,Carbonix_Finance_Address),0);
              console.log("allowance2", allowance2)
              setAllowance2(allowance2);
      
              let daibalance1 = ethers.utils.formatUnits(await daiContract.balanceOf(address),18);
                setdaiBalance(daibalance1);
                console.log("bal",daibalance1);
                let creditsbalance1 = ethers.utils.formatUnits(await creditsContract.balanceOf(address),0);
                setCreditsBalance(creditsbalance1)  
              const userDeposit = ethers.utils.formatUnits(await CarbonixContract.getCdpTotalDeposited(address),0);
              setUserDeposit(userDeposit);
              const userDebt = ethers.utils.formatUnits(await CarbonixContract.getCdpTotalDebt(address),0);
              setUserDebt(userDebt);
              console.log("debt",userDebt);
            }catch(err){
                console.log(err)
            }
            }
       
        
    }

    const approveDai = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const DaiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            let givenAmount = ethers.utils.parseEther(repayAmt.toString());
            const approveTx = await DaiContract.approve(Carbonix_Finance_Address,givenAmount);
            await approveTx.wait();
          
            console.log("minttx",approveTx.hash);
            await getAssetDetails();
            await TxnId_PopUp(approveTx.hash);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const approveCredits = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const creditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            let givenAmount = ethers.utils.parseEther(repayAmt.toString());
            const approveTx = await creditsContract.approve(Carbonix_Finance_Address,givenAmount);
            await approveTx.wait();
          
            console.log("minttx",approveTx.hash);
            await getAssetDetails();
            await TxnId_PopUp(approveTx.hash);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const repay = async() => {
        if (isConnected) {
            try {
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = ethersProvider.getSigner()
                const CarbonixContract = new ethers.Contract(Carbonix_Finance_Address, Carbonix_Finance_ABI, signer);
                
                await showPopUp("yes", TransactionPending, "", "");
                let givenAmount = ethers.utils.parseEther(repayAmt.toString()); // Convert value to wei
                console.log("givenAmt", givenAmount);
                let repayTx = null;
                if(isStableCoin){
                    repayTx = await CarbonixContract.repay(givenAmount,0);
                }else{
                    repayTx = await CarbonixContract.repay(0,givenAmount);
                }             
                await repayTx.wait();
              
                console.log("minttx", repayTx.hash);
                await getAssetDetails();
                await resetState();
                await TxnId_PopUp(repayTx.hash);
                await balanceOfTokens();
                let nm = isStableCoin?"DAI":"CREDITS"
                await createTxn(nm, repayTx.hash,"Repay",address,Carbonix_Finance_Address);
            } catch (err) {
                showPopUp("not", TransactionPending, "", (err.reason).toString());
                console.log("error", err);
            }
        }
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const handlechange = (event) =>{ 
            setrepayAmt(event.target.value);
    }

    const handleInput = (value) => {
        setrepayAmt((( userDebt*value)/100)/1e18);
        console.log(value);
    }

    const handleSelect = (item) => {
        setSelectedCoin(item);
        if(item === "DAI"){
            setisStableCoin(true);
        }else{
            setisStableCoin(false);
        }
    };

    const resetState = async() =>{
        setrepayAmt(0.00);
        await balanceOfTokens();
    }

    useEffect(()=>{getAssetDetails()},[address, isConnected, selectedTab]);

    let buttonDisableCondition = !parseFloat(userDebt);
    return ( 
        <div className="p-24">
            <Row className="justify-content-center">
            <Col style={{maxWidth: '700px'}}>
                    <div className="box mb-20 p-3 p-md-4">
                        <div className="swap-card mb-20 d-flex align-items-center">
                            <div>
                                {isStableCoin ? <img src={DaiIcon} alt="Icon1" height="42px"/> : <img src={Icon3} alt="Icon1" height="42px"/>}
                            </div>
                            <div className="flex-grow-1 ms-2 ps-3 py-2">
                                <NumericFormat disabled={buttonDisableCondition} type="text" value={repayAmt ? repayAmt : ""} placeholder="0.00" className="input-reset" allowNegative={false} onChange={(e) => handlechange(e)}/>
                            </div>
                            {!buttonDisableCondition && (
                            <Dropdown onSelect={handleSelect}>
                                <Dropdown.Toggle variant="grad" className="py-2" id="dropdown-basic">
                                    {selectedCoin}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="DAI">DAI</Dropdown.Item>
                                    <Dropdown.Item eventKey="CREDITS">CREDITS</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> 
                            )}
                            
                        </div>

                        <Row className="mb-20 g-2">
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(25)}><span>25%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(50)}><span>50%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(75)}><span>75%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(100)}><span>100%</span></button>
                            </Col>
                        </Row>

                        
                    
                        {isStableCoin ? 
                            (allowan < (parseFloat(repayAmt) * 1e18) ? 
                                <button disabled={buttonDisableCondition} className="btn btn-grad py-2 w-100" onClick={approveDai}>Approve DAI</button> 
                                : 
                                <button disabled={buttonDisableCondition} className="btn btn-grad py-2 w-100" onClick={repay}>Repay</button>
                            )
                            :
                            (allowan2 < (parseFloat(repayAmt) * 1e18) ? 
                                <button disabled={buttonDisableCondition} className="btn btn-grad py-2 w-100" onClick={approveCredits}>Approve CREDITS</button> 
                                : 
                                <button disabled={buttonDisableCondition} className="btn btn-grad py-2 w-100" onClick={repay}>Repay</button>
                            )
                        }

<div className="mt-3 text-center  pb-2">
                            {buttonDisableCondition ? <h5 className="text-red mb-0">{isConnected?"There are no borrowed CREDITS left to repay":"Connect Your Wallet"}</h5> : <></>}
                        </div>

                        
                        <div className="swap-card p-3">
                    <div className="box mb-2 p-3 p-md-4 border">
                    {/* <h4 className="text-center pb-1">DAI</h4> */}
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Your Wallet balance: </h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={daiBalance ? (parseFloat(daiBalance)).toFixed(4) : "0.0000"}/> &nbsp;<img src={DaiIcon} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Your collateral balance:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={userDeposit ? (userDeposit / 1e18).toFixed(4) : "0.0000"} /> &nbsp;<img src={DaiIcon} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                        <div className="flex mb-0 justify-content-between ">
                            <h5 className="mb-0 text-gray">Available to withdraw:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={(userDeposit && userDebt) ? ((userDeposit - userDebt) / 1e18).toFixed(4) : "0.0000"}/> &nbsp;<img src={DaiIcon} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                    </div>

                 
                    <div className="box mb-0 p-3 p-md-4 border" style={{width:'100%'}}>
                    {/* <h4 className="text-center pb-1">CREDITS</h4> */}
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray ">Borrowed:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={userDebt ? (userDebt / 1e18).toFixed(4) : "0.0000"}/> &nbsp;<img src={creditslogo} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Available to Borrow:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={(userDeposit && userDebt) ? (((userDeposit / 2) - userDebt) / 1e18).toFixed(4) : "0.0000"} /> &nbsp;<img src={creditslogo} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                        <div className="flex mb-0 justify-content-between">
                            <h5 className="mb-0 text-gray">Your Wallet Balance:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={creditsBalance ? (creditsBalance / 1e18).toFixed(4) : "0.0000"} /> &nbsp;<img src={creditslogo} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                    </div>
                    </div>
                    </div>

                </Col>
            </Row>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>
        </div>
     );
}

export default Repay;