import { Col, Row } from "react-bootstrap";
import Icon1 from '../../asserts/IMAGES/joker-icon.svg';
import Icon2 from '../../asserts/IMAGES/status-icon2.svg';
import Icon3 from '../../asserts/IMAGES/credits-icon.svg';
import Icon4 from '../../asserts/IMAGES/treasury-icon.svg';
import jokerLogo from '../../asserts/IMAGES/jokerRedLogo.svg';
import dimeLogo from '../../asserts/IMAGES/dimeCyanLogo.svg';
import creditsLogo from '../../asserts/IMAGES/creditsBlackLogo.svg';
import WaveLines from '../../asserts/IMAGES/wave-lines.svg';
import ProgressBar from "./Snippets/CircleTimer";
import Timer from "./Snippets/Timer";
import Traju from "../../asserts/IMAGES/traju.png";
import JockerCard from "./Snippets/JokerCard";
import CreditsCard from "./Snippets/CreditsCard";
import DimeCard from "./Snippets/DimeCard";
import DimeCreditJokerCard from "./Snippets/DimeCreditJoker";
import TreasuryCard from "./Snippets/TreasuryCard";
import { LineChart } from "./Snippets/LineChart";
import tauIcon from '../../asserts/IMAGES/tau-original.png';
import stasisImage from "../../asserts/IMAGES/stasisimage.png";
import ChartComponent from './Snippets/PieChart2';
// import fireDb from "../../abi/firebase";

import { useState, useEffect } from "react";

import { CheckAllowance, NumberAbbreviation, getCreditsPrice, getDAIPrice, getDimeContractDetails, getDimePrice, getJokerContractDetails, getJokerPrice, getJokerSupply, getTAUPrice, getTauSupply, getTokenBalance } from "../../abi/CommonSolFunctions";
import { CHAIN_URL, TAU_UFragmentPolicy_Address, TAU_UFragmentPolicy_ABI, DIME_TREASURY_ABI, DIME_TREASURY_Address, ECO_Reserve_Treasury_Address, Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, DAI_Chainlink_Oracle_Address, DIME_Token_Address, DIME_Token_ABI, DIME_Chainlink_Oracle_Address, JOKER_Token_ABI, JOKER_Token_Address, JOKER_Chainlink_Oracle_Address,  CREDITS_Token_Address, CREDITS_Token_ABI, CREDITS_Chainlink_Oracle_Address, API_KEY, Network_Name, Burn_Vault_Address, Zero_Address, Treasury_wallet_address, Credits_Pool_Address, Communitywallet_Address, DIME_LP_Token_Address, LP_Token_ABI } from "../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { gepriceIncrease, getPriceIncreaselast7days, getPriceOneWeek, recordUserVisits } from "../../abi/firebasecode";
import { CircularProgressbar } from "react-circular-progressbar";
import ChartIcon from '../../asserts/IMAGES/chatJokericon.svg';
import BalanceScale from "./Snippets/balanceScale";
import DimeBalanceScale from "./Snippets/balanceScaleDime";

function Stasis({ selectedTab }) {
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    const { address } = useWeb3ModalAccount();
    const[jokerPrice,setjokerPrice] = useState(0);
    const[daiPrice,setdaiPrice] = useState("");
    const[dimePrice,setdimePrice] = useState("");
    const[tauPrice,settauPrice] = useState("");
    const[creditsPrice,setcreditsPrice] = useState("");
    const[daiBalanceT,setdaiBalanceT] = useState("");
    const[jokerBalanceT,setjokerBalanceT] = useState("");
    const[dimeBalanceT,setdimeBalanceT] = useState("");
    const[creditsBalanceT,setcreditsBalanceT] = useState("");
    const[treasuryValue,settreasuryValue] = useState("");
    const[dimeRebaseEpoch, setdimeRebaseEpoch] = useState(0);
    const [elapsedPercentage, setElapsedPercentage] = useState(0);
    const[tauRebaseEpoch, settauRebaseEpoch] = useState(0);
    const [elapsedPercentage2, setElapsedPercentage2] = useState(0);

    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);
    const [Oneyeardates1, setOneyeardates1] = useState(0);
    const [Oneyearprices1, setOneyearprices1] = useState(0);
    const [Oneyeardates2, setOneyeardates2] = useState(0);
    const [Oneyearprices2, setOneyearprices2] = useState(0);
    const [Oneyeardates3, setOneyeardates3] = useState(0);
    const [Oneyearprices3, setOneyearprices3] = useState(0);

    
    const[joker1Month,setjoker1Month] = useState("");
    const[dime1month,setDime1Month] = useState("");
    const[credit1month,setCredit1Month] = useState("");
    const[tau1month,setTau1Month] = useState("");

    const [burnFees, setBurnFees] = useState(0);
    const [taxRevenue, setTaxRevenue] = useState(0);
    const [liquidityFees, setLiquidityFees] = useState(0);
    const [treasuryFees, setTreasuryFees] = useState(0);
    const [burnVaultBalance, setBurnVaultBalance] = useState(0);
    const [totalJokerBurned, setTotalJokerBurned] = useState(0);
    const [DimeTreasury,setDimeTreasury] = useState(0);
    const [DimeCirculation,setDimeCirculation] = useState(0);
    const[TreasuryDaiBal,setTreasuryDaiBal] = useState("")
    const[CreditsTreasury,setCreditsTreasury] = useState(0)
    const[CreditsMint,setCreditsMint] = useState(0)
    const[TotalBacking,setTotalBacking] = useState(0)
    const [TauTotalSuuply ,setTauTotalSuuply] = useState(0)
    const[zeroAddress,setzeroAddress] = useState(0)
    const[JokerTotalSupply,setJokerTotalSupply] = useState(0)

    const getAssetDetails = async() =>{  
        try{        
          const DaiPriceContract = new ethers.Contract(DAI_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
          const JokerPriceContract = new ethers.Contract(JOKER_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
          const DimePriceContract = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
          const CreditsPriceContract = new ethers.Contract(CREDITS_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
          const DimeBondTreasuryContract = new ethers.Contract(DIME_TREASURY_Address, DIME_TREASURY_ABI, provider);
          const TAUUFragmentContract = new ethers.Contract(TAU_UFragmentPolicy_Address, TAU_UFragmentPolicy_ABI, provider);
          
          let daiprice = await getDAIPrice();
          setdaiPrice(daiprice);
          let jokerprice =await  getJokerPrice();
          setjokerPrice(jokerprice);
          let dimeprice = await getDimePrice();
          setdimePrice(dimeprice);
          let creditsprice = await getCreditsPrice();
          setcreditsPrice(creditsprice);
          let tauprice = await getTAUPrice();
          settauPrice(tauprice);

          let daiBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
          setdaiBalanceT(daiBalanceT);
          let jokerBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,JOKER_Token_Address,JOKER_Token_ABI);
          setjokerBalanceT(jokerBalanceT);
          let dimeBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,DIME_Token_Address,DIME_Token_ABI);
          setdimeBalanceT(dimeBalanceT);
          let creditsBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,CREDITS_Token_Address,CREDITS_Token_ABI);
          setcreditsBalanceT(creditsBalanceT);
          let TreasuryValue = ((daiBalanceT/1e18) * daiprice) + ((jokerBalanceT/1e9) * jokerprice) + ((dimeBalanceT/1e9) * dimeprice) + ((creditsBalanceT/1e18) * creditsprice);
          settreasuryValue(TreasuryValue);

          

          let dimeRebaseTime  = ethers.utils.formatUnits(await DimeBondTreasuryContract.lastEpoch(),0);
          setdimeRebaseEpoch(dimeRebaseTime);
          let tauRebasetime  = ethers.utils.formatUnits(await TAUUFragmentContract.lastRebaseTimestampSec(),0);
          settauRebaseEpoch(parseFloat(tauRebasetime)+86400);


          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const elapsedSeconds = currentTime - dimeRebaseTime;
        const totalSeconds = 21600; // Total seconds for dimeRebaseEpoch + 21600

        // Calculate percentage
        const percentage = (elapsedSeconds / totalSeconds) * 100;
        setElapsedPercentage(percentage);

        // const currentTime2 = Math.floor(Date.now() / 1000); // Current time in seconds
        const elapsedSeconds2 =  (tauRebasetime) - currentTime;
        const totalSeconds2 = 86400; // Total seconds for dimeRebaseEpoch + 21600

        // Calculate percentage
        const percentage2 = (elapsedSeconds2 / totalSeconds2) * 100;
        setElapsedPercentage2(percentage2);
        let ownerbalance = await getTokenBalance(Communitywallet_Address,JOKER_Token_Address,JOKER_Token_ABI)
        setJokerTotalSupply((parseFloat(await getJokerSupply())-parseFloat(ownerbalance))/1e9);

          
          
        } catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{getAssetDetails()},[]);

    const getAssetDetails2 = async() =>{   
        try{
        const CreditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);

        let creditMintValue  = ethers.utils.formatUnits(await CreditsContract.hasMinted(Credits_Pool_Address),0);
        let totalbacking  = await getTokenBalance(Credits_Pool_Address,DAI_TOKEN_Address,DAI_TOKEN_ABI)
        setCreditsMint(creditMintValue);
        setTotalBacking(totalbacking);
        setTauTotalSuuply(await getTauSupply())
    } catch(err){
        console.log(err);
    }

    }
    useEffect(()=>{getAssetDetails2()},[]);

    const feesFetch = async() => { 
        try{
        let burnFeesFetch = await getJokerContractDetails('totalBurn');
        let taxRevenueFetch = await getJokerContractDetails('totalTax');
        let liquidityFeesFetch = await getJokerContractDetails('_getnumTokensSellToAddToLiquidity');
        let treasuryFeesFetch = await getTokenBalance(Treasury_wallet_address, JOKER_Token_Address, JOKER_Token_ABI);
        let burnVaultBalanceFetch = await getTokenBalance(Burn_Vault_Address, JOKER_Token_Address, JOKER_Token_ABI);
        let zeroAddressJokerBalanceFetch = await getTokenBalance(Zero_Address, JOKER_Token_Address, JOKER_Token_ABI);
        let totalJokerBurned = parseFloat(burnFeesFetch) + parseFloat(zeroAddressJokerBalanceFetch);
        let dimetreasury = await getTokenBalance(ECO_Reserve_Treasury_Address, DIME_Token_Address, DIME_Token_ABI);
        let treasueryDimeBal = await getTokenBalance(DIME_TREASURY_Address, DAI_TOKEN_Address, DAI_TOKEN_ABI);
        let dimecirculation = parseFloat(await getDimeContractDetails('totalSupply'))-parseFloat(dimetreasury);
        let treasueryDaiBal = await getTokenBalance(DIME_TREASURY_Address, DAI_TOKEN_Address, DAI_TOKEN_ABI);
        let dimelp = await getTokenBalance(DIME_TREASURY_Address,DIME_LP_Token_Address,LP_Token_ABI);
        let creditstreasury = await getTokenBalance(ECO_Reserve_Treasury_Address, CREDITS_Token_Address, CREDITS_Token_ABI);
        setTreasuryDaiBal(parseFloat(treasueryDaiBal)+parseFloat(dimelp));
        setDimeCirculation(dimecirculation);
        setBurnFees(burnFeesFetch);
        setTaxRevenue(taxRevenueFetch);
        setLiquidityFees(liquidityFeesFetch);
        setTreasuryFees(treasuryFeesFetch);
        setBurnVaultBalance(burnVaultBalanceFetch);
        setTotalJokerBurned(totalJokerBurned);
        setDimeTreasury(dimetreasury);
        setCreditsTreasury(creditstreasury);
        setzeroAddress(zeroAddressJokerBalanceFetch)
    } catch(err){
        console.log(err);
    }
    }

    useEffect(() => {
        feesFetch();
    }, [])

    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }
        // Calculate elapsed percentage
    // useEffect(() => {
    //     const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    //     const elapsedSeconds = currentTime - dimeRebaseEpoch;
    //     const totalSeconds = 21600; // Total seconds for dimeRebaseEpoch + 21600

    //     // Calculate percentage
    //     const percentage = (elapsedSeconds / totalSeconds) * 100;
    //     setElapsedPercentage(percentage);

    //     const currentTime2 = Math.floor(Date.now() / 1000); // Current time in seconds
    //     const elapsedSeconds2 = currentTime2 - (tauRebaseEpoch);
    //     const totalSeconds2 = 86400; // Total seconds for dimeRebaseEpoch + 21600

    //     // Calculate percentage
    //     const percentage2 = (elapsedSeconds2 / totalSeconds2) * 100;
    //     setElapsedPercentage2(percentage2);
    // }, []);

    const getallvalues = async() =>{
        let oneyearvalue = await getPriceOneWeek("JokerPrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices(prices)
        setjoker1Month(await getPriceIncreaselast7days("JokerPrice","1month"));
      }
      useEffect(()=>{getallvalues()},[])

      const getallvalues1 = async() =>{
        let oneyearvalue = await getPriceOneWeek("DimePrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates1(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices1(prices)
        setDime1Month(await getPriceIncreaselast7days("DimePrice","1month"));
      }
      useEffect(()=>{getallvalues1()},[])
      const getallvalues2 = async() =>{
        let oneyearvalue = await getPriceOneWeek("CreditPrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates2(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices2(prices)
        setCredit1Month(await getPriceIncreaselast7days("CreditPrice","1month"));
      }
      useEffect(()=>{getallvalues2()},[])
      const getallvalues3 = async() =>{
        let oneyearvalue = await getPriceOneWeek("TauPrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates3(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices3(prices)
        setTau1Month(await getPriceIncreaselast7days("TauPrice","1month"));
      }
      useEffect(()=>{getallvalues3()},[])

      const userVisit = async () => {
        if(selectedTab === "Stasis")
            await recordUserVisits(address, "Stasis Dashboard");
      }

      useEffect(()=>{userVisit()},[address, selectedTab])

      
    const chartData = {
        labels: ['Circulation', 'BurnVault','Burned'],
        values: [JokerTotalSupply?JokerTotalSupply:10, burnVaultBalance?(burnVaultBalance/1e9):20,zeroAddress?(zeroAddress/1e9):5],
        colors: ['cyan', '#FF9800','Red'],
      };
    
    return ( 
        <div className="p-24 app-contain">
            <Row className="g-3 mb-24">
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="mb-3 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                                <img src={jokerLogo} height={'42px'}  style={{minWidth: '42px'}} alt="jokerLogo" />
                            </Col>
                            <Col>
                                <div className="status-card h-100 d-flex align-items-center">
                                    <div>
                                        <h5>${jokerPrice ? (jokerPrice/1e8).toFixed(2) : "0.00"}</h5>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="sc-chart">
                                        <LineChart color={joker1Month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name="Joker"/>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Total Tax </h5>
                                    <h6>$<NumberAbbreviation number={formatValues(taxRevenue*jokerPrice/1e17)}/> </h6>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3 g-3">
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Total Liquidity</h5>
                                    <h6>$<NumberAbbreviation number={formatValues(liquidityFees*jokerPrice/1e17)} /> </h6>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Total Burned</h5>
                                    <h6>$<NumberAbbreviation number={formatValues((burnFees*jokerPrice)/1e17)} /> </h6>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Revenue</h5>
                                    <h6>$<NumberAbbreviation number={formatValues(treasuryFees*jokerPrice/1e17)} /></h6>
                                </div>
                            </Col>
                        </Row>
                        <hr className="my-md-3 my-2" />
                        <div className="box d-flex align-items-center p-3 p-md-3">
                        <div>
                            <h4 className="mb-3">Burn Statistics</h4>
                            <h5 className="text-gray"><span className="dot" style={{backgroundColor: '#20E2D0'}}></span> In Burn Vault : <NumberAbbreviation number= {formatValues(burnVaultBalance/1e9)} /></h5>
                            <h5 className="text-gray"><span className="dot" style={{backgroundColor: '#EE6A5F'}}></span> Total Burned : <NumberAbbreviation number= {formatValues(totalJokerBurned/1e9)} /></h5>
                        </div>
                        <div className="ms-auto">
                            <div className="circle-timer circle-timer-pie">
                                <CircularProgressbar value={(burnVaultBalance/totalJokerBurned)*100} />
                                    <img src={jokerLogo}  height={'35px'} style={{minWidth: '35px'}}  alt='icon' />    
                            </div>
                        </div>
                    </div>
                        {/* <div className="d-flex align-items-center">
                            <div>
                                <h4 className="mb-3">DIME Rebase</h4>
                                <h5 className="text-gray">Next Seigniorage</h5>
                                <Timer count={"2024-03-28T01:02:03"} />
                            </div>
                            <div className="ms-auto">
                                <ProgressBar
                                    percentage={30}
                                    startColor="#20E2D0"
                                    endColor="#00C2FF"
                                    gradientId="progress1"
                                />
                            </div>
                        </div> */}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="mb-3 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                                <img src={dimeLogo}  height={'42px'} style={{minWidth: '42px'}} alt="jokerLogo" />
                            </Col>
                            <Col>
                                <div className="status-card h-100 d-flex align-items-center">
                                    <div>
                                        <h5>${dimePrice ? (dimePrice/1e8).toFixed(2) : "0.00"}</h5>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="sc-chart">
                                        <LineChart color={dime1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates1?Oneyeardates1:'0'} prices={Oneyearprices1?Oneyearprices1:'0'} name="Dime"/>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Treasury</h5>
                                    <h6>$<NumberAbbreviation number={(DimeTreasury*dimePrice)/1e17}/></h6>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row className="align-items-center mb-3 g-3">
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Total Burned</h5>
                                    <h6>$24</h6>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Total Burned</h5>
                                    <h6>$24</h6>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Total Burned</h5>
                                    <h6>$24</h6>
                                </div>
                            </Col>
                        </Row> */}
                        {/* <hr className="my-md-3 my-2" /> */}
                        <div className="d-flex align-items-center  mb-3 g-2">
                        <div>
                            <h4 className="mb-3">Equilibrium</h4>
                            <h5 className="text-gray"><span className="dot" style={{backgroundColor: '#20E2D0'}}></span> In Circulation : $<NumberAbbreviation number= {formatValues(DimeCirculation*dimePrice/1e17)} /></h5>
                            <h5 className="text-gray"><span className="dot" style={{backgroundColor: '#EE6A5F'}}></span> Backing : $<NumberAbbreviation number= {formatValues(parseFloat(TreasuryDaiBal)*parseFloat(daiPrice)/1e26)} /></h5>
                        </div>
                        <div className="ms-auto">

                            <div className="circle-timer circle-timer-pie">
                                <CircularProgressbar 
                                value= {((DimeCirculation/1e9) - TreasuryDaiBal/1e18)/(TreasuryDaiBal/1e18)*100}
                                />
                                    <img src={dimeLogo} height={'35px'} style={{minWidth: '35px'}} alt='icon' />    
                            </div>
                        </div>
                        </div>
                        <hr className="my-md-3 my-2" />
                        <div className="d-flex align-items-center p-3 p-md-1">
                        <div>
                            <h4 className="mb-3"> Rebase</h4>
                            <h5 className="text-gray">Next Seigniorage</h5>
                            <Timer count={new Date((Number(dimeRebaseEpoch) + 21600)*1000)} />
                        </div>
                        <div className="ms-auto">
                            <ProgressBar
                                percentage={elapsedPercentage}
                                startColor="#20E2D0"
                                endColor="#00C2FF"
                                gradientId="progress7"
                            />
                        </div>
                        </div>
                    </div>
                </Col>
                {/* <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="align-items-center mb-3 g-3">
                        <Col className="my-auto pt-3" xs={'auto'}>
                                <img src={creditsLogo}  height={'42px'} style={{minWidth: '42px'}} alt="jokerLogo" />
                            </Col>
                            <Col>
                                <Row className="mb-3 g-3">
                                    <Col>
                                        <div className="status-card h-100 d-flex align-items-center">
                                            <div>
                                                <h5>$12.034</h5>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="sc-chart">
                                                    <LineChart color="#20E2D0" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="status-card h-100 text-center">
                                            <h5>$12.034</h5>
                                            <h6>$240000</h6>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h4 className="mb-3">DIME Rebase</h4>
                                        <h5 className="text-gray">Next Seigniorage</h5>
                                        <Timer count={"2024-03-28T01:02:03"} />
                                    </div>
                                    <div className="ms-auto">
                                        <ProgressBar
                                            percentage={30}
                                            startColor="#20E2D0"
                                            endColor="#00C2FF"
                                            gradientId="progress1"
                                        />
                                    </div>
                                </div>
                            </Col>
                            
                        </Row>
                    </div>
                </Col> */}
                 <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="mb-3 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                                <img src={creditsLogo}  height={'42px'} style={{minWidth: '42px'}} alt="jokerLogo" />
                            </Col>
                            <Col>
                                <div className="status-card h-100 d-flex align-items-center">
                                    <div>
                                        <h5>${creditsPrice ? (creditsPrice/1e8).toFixed(2) : "0.00"}</h5>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="sc-chart">
                                        <LineChart color={credit1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates2?Oneyeardates2:'0'} prices={Oneyearprices2?Oneyearprices2:'0'} name="Credits"/>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Treasury</h5>
                                    <h6>$<NumberAbbreviation number={(CreditsTreasury*creditsPrice)/1e26}/></h6>
                                </div>
                            </Col>
                        </Row>
                        
                        <hr className="my-md-3 my-2" />
                        <div className="d-flex align-items-center  mb-3 g-2">
                        <div>
                            <h4 className="mb-3">Equilibrium</h4>
                            <h5 className="text-gray"><span className="dot" style={{backgroundColor: '#20E2D0'}}></span> In Circulation : $<NumberAbbreviation number= {formatValues(CreditsMint*creditsPrice/1e26)} /></h5>
                            <h5 className="text-gray"><span className="dot" style={{backgroundColor: '#EE6A5F'}}></span> Backing : $<NumberAbbreviation number= {formatValues(parseFloat(TotalBacking)*parseFloat(daiPrice)/1e26)} /></h5>
                        </div>
                        <div className="ms-auto">

                            <div className="circle-timer circle-timer-pie" >
                                <CircularProgressbar 
                                value= {100 - parseFloat((TotalBacking/1e18)/((parseFloat(CreditsMint)+parseFloat(TotalBacking))/1e18)*100).toFixed(0)}
                                />
                                    <img src={creditsLogo} height={'35px'} style={{minWidth: '35px'}} alt='icon' />    
                            </div>
                        </div>
                        </div>
                       
                    </div>
                </Col>
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="mb-3 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                                <img src={tauIcon}  height={'42px'} style={{minWidth: '42px'}} alt="jokerLogo" />
                            </Col>
                            <Col>
                                <div className="status-card h-100 d-flex align-items-center">
                                    <div>
                                        <h5>${tauPrice ? (tauPrice/1e8).toFixed(2) : "0.00"}</h5>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="sc-chart">
                                        <LineChart color={tau1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates3?Oneyeardates3:'0'} prices={Oneyearprices3?Oneyearprices3:'0'} name="Tau"/>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Supply</h5>
                                    <h6>$<NumberAbbreviation number={(TauTotalSuuply*tauPrice)/1e26}/></h6>
                                </div>
                            </Col>
                        </Row>
                        
                        
                        <hr className="my-md-3 my-2" />
                        <div className="d-flex align-items-center  mb-3 g-2">
                        <div>
                            <h4 className="mb-3">Rebase</h4>
                            <h5 className="text-gray">Next Seigniorage</h5>
                            <Timer count={new Date((Number(tauRebaseEpoch)) * 1000)} />
                        </div>
                        <div className="ms-auto">
                            <ProgressBar
                                percentage={elapsedPercentage2?Math.abs(elapsedPercentage2):0}
                                startColor="#20E2D0"
                                endColor="#00C2FF"
                                gradientId="progress2"
                            />
                        </div>
                        </div>
                    </div>
                </Col>
                {/* <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="align-items-center mb-3 g-3">
                            <Col xs={'auto'}>
                                <img src={jokerLogo} height={'42px'}  style={{minWidth: '42px'}} alt="jokerLogo" />
                            </Col>
                            <Col>
                                <Row className="mb-3 g-3">
                                    <Col>
                                        <div className="status-card h-100 d-flex align-items-center">
                                            <div>
                                                <h5>$12.034</h5>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="sc-chart">
                                                    <LineChart color="#20E2D0" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="status-card h-100 text-center">
                                            <h5>$12.034</h5>
                                            <h6>$240000</h6>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h4 className="mb-3">DIME Rebase</h4>
                                        <h5 className="text-gray">Next Seigniorage</h5>
                                        <Timer count={"2024-03-28T01:02:03"} />
                                    </div>
                                    <div className="ms-auto">
                                        <ProgressBar
                                            percentage={30}
                                            startColor="#20E2D0"
                                            endColor="#00C2FF"
                                            gradientId="progress1"
                                        />
                                    </div>
                                </div>
                            </Col>
                            
                        </Row>
                    </div>
                </Col> */}
            </Row>

            {/* <Row className="g-3 mb-24">
                <Col md={3} sm={6}>
                    <div className="status-card d-flex align-items-center">
                        <div>
                            <img src={jokerLogo} height={'42px'} style={{minWidth: '42px'}} alt="Icon1" />
                        </div>
  
                        
                        <div>
                            <h5>Joker</h5>
                            <h6>${jokerPrice ? (jokerPrice/1e8).toFixed(2) : "0.00"}</h6>
                        </div>
                        <div className="ms-auto">
                            <div className="sc-chart">
                               
                                <LineChart color={joker1Month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name="Joker"/>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6}>
                    <div className="status-card d-flex align-items-center">
                        <div>
                            <img src={dimeLogo} height={'42px'} style={{minWidth: '42px'}} alt="Icon2" />
                        </div>
                        <div>
                            <h5>Dime</h5>
                            <h6>${dimePrice ? (dimePrice/1e8).toFixed(2) : "0.00"}</h6>
                        </div>
                        <div className="ms-auto">
                            <div className="sc-chart">
                            <LineChart color={dime1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates1?Oneyeardates1:'0'} prices={Oneyearprices1?Oneyearprices1:'0'} name="Dime"/>

                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6}>
                    <div className="status-card d-flex align-items-center">
                        <div>
                            <img src={creditsLogo} height={'42px'} style={{minWidth: '42px'}} alt="Icon3" />
                        </div>
                        <div>
                            <h5>Credits</h5>
                            <h6>${creditsPrice ? (creditsPrice/1e8).toFixed(2) : "0.00"}</h6>
                        </div>
                        <div className="ms-auto">
                            <div className="sc-chart">
                            <LineChart color={credit1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates2?Oneyeardates2:'0'} prices={Oneyearprices2?Oneyearprices2:'0'} name="Credits"/>

                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6}>
                    <div className="status-card d-flex align-items-center">
                        <div>
                            <img src={Icon4} style={{minWidth: '42px'}} alt="Icon4" />
                        </div>
                        <div>
                            <h5>Treasury</h5>
                            <h6>${treasuryValue ? (treasuryValue/1e8).toFixed(2) : "0.00"}</h6>
                        </div>
                        <div className="ms-auto">
                            <div className="sc-chart">
                            <LineChart color={tau1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates3?Oneyeardates3:'0'} prices={Oneyearprices3?Oneyearprices3:'0'} name="Tau"/>

                            </div>
                        </div>
                    </div>
                </Col>
            </Row> */}

            {/* <Row className="g-3 mb-24">
                <Col md={6}>
                    <div className="box d-flex align-items-center p-3 p-md-4">
                        <div>
                            <h4 className="mb-3">DIME Rebase</h4>
                            <h5 className="text-gray">Next Seigniorage</h5>
                            <Timer count={new Date((Number(dimeRebaseEpoch) + 21600)*1000)} />
                        </div>
                        <div className="ms-auto">
                            <ProgressBar
                                percentage={elapsedPercentage}
                                startColor="#20E2D0"
                                endColor="#00C2FF"
                                gradientId="progress1"
                            />
                        </div>

                        
                    </div>
                </Col>
                <Col md={6}>
                    <div className="box d-flex align-items-center p-3 p-md-4">
                        <div>
                            <h4 className="mb-3">TAU Rebase</h4>
                            <h5 className="text-gray">Next Seigniorage</h5>
                            <Timer count={new Date((Number(tauRebaseEpoch) + 21600) * 1000)} />
                        </div>
                        <div className="ms-auto">
                            <ProgressBar
                                percentage={elapsedPercentage2}
                                startColor="#20E2D0"
                                endColor="#00C2FF"
                                gradientId="progress2"
                            />
                        </div>
                    </div>
                </Col>
            </Row> */}
            <hr />

            <Row className="g-3 mb-24">
                {/* <Col md={6}>
                <div className="box">
                    <div className="box d-flex align-items-center justify-content-center p-3 ">
                    <div className="chart-header d-flex align-items-center flex-wrap">
                <div>
                <h4>Equilibrium</h4>
                <div className="p-4">
                        <img src={stasisImage} style={{maxHeight: '360px'}} alt="Traju" className="img-fluid" />
                    </div>
                    </div></div>
                    
                    </div>
                </Col> */}
              
                <Col md={6}>             
                    <BalanceScale />
                </Col>
                <Col md={6}>              
                    <DimeBalanceScale />
                </Col>
              
                <Col md={6}>
                    <JockerCard />
                </Col>
                <Col md={6}>
                    <CreditsCard />
                </Col>
                <Col md={6}>
                    <DimeCard />
                </Col>
                <Col md={6}>
                    <TreasuryCard />
                </Col>
                </Row>
                <Row className="g-3 mb-24">
                <Col md={6}>
                    <DimeCreditJokerCard />
                </Col>
                <Col md={6}>
  <div className="g-3 mb-24" style={{ width: '100%'}}>
    
        <ChartComponent data={chartData} />
      </div>
                    </Col>
            </Row>
        </div>
     );
}

export default Stasis;