import React from 'react';
import { JokerMarketCapProvider, PriceProvider } from './abi/usingContext';  // Adjust the import path as needed
import { DimeMarketCapProvider } from './abi/usingContext';  // Adjust the import path as needed

const CombinedProvider = ({ children }) => {
  return (
    <PriceProvider>
      <DimeMarketCapProvider>
        <JokerMarketCapProvider>
        {children}
        </JokerMarketCapProvider>
      </DimeMarketCapProvider>
    </PriceProvider>
  );
};

export default CombinedProvider;
