import Poll from '../../../asserts/IMAGES/pole.svg'
import Angle from '../../../asserts/IMAGES/pole-angle.svg'
import BalanceWeight from '../../../asserts/IMAGES/balanceWeight.svg'
import JokerIcon from '../../../asserts/IMAGES/jokerRedLogo.svg'
import CreditsIcon from '../../../asserts/IMAGES/creditsBlackLogo.svg'
import DimeIcon from '../../../asserts/IMAGES/dimeCyanLogo.svg'
import { useEffect, useState } from 'react'
import {NumericFormat}  from 'react-number-format';
import { NumberAbbreviation, getCreditsPrice, getCreditsSupply, getDimePrice, getDimeSupply, getJokerPrice, getJokerSupply, getTokenBalance } from '../../../abi/CommonSolFunctions'
import { CREDITS_Token_ABI, CREDITS_Token_Address, DIME_TREASURY_Address, DIME_Token_ABI, DIME_Token_Address, ECO_Reserve_Treasury_Address } from '../../../abi/RefinedABI&ContractAddress'

function BalanceScale() {
    const[jokerMarketCap ,setjokerMarketCap] = useState("");
    const[dimeMarketcap,setDimeMarketcap] = useState("");
    const[creditsMarketcap,setCreditsMarketcap] = useState("");
    const fetchinitailValues = async () => {
        let jokermarketcap = parseFloat(await getJokerSupply()) * parseFloat(await getJokerPrice());
        let dimetreasury = await getTokenBalance(ECO_Reserve_Treasury_Address, DIME_Token_Address, DIME_Token_ABI);
        let creditstreasury = await getTokenBalance(ECO_Reserve_Treasury_Address, CREDITS_Token_Address, CREDITS_Token_ABI);
        let treasueryDimeBal = await getTokenBalance(DIME_TREASURY_Address, DIME_Token_Address, DIME_Token_ABI);

        let dimemarketcap = (parseFloat(await getDimeSupply())-(parseFloat(dimetreasury)+parseFloat(treasueryDimeBal))) * parseFloat(await getDimePrice());
        let creditsmarketcap = (parseFloat(await getCreditsSupply())-parseFloat(creditstreasury)) * parseFloat(await getCreditsPrice());
        setjokerMarketCap((jokermarketcap/1e17));
        setDimeMarketcap(dimemarketcap/1e17);
        setCreditsMarketcap(creditsmarketcap/1e27);
    }
    useEffect(() =>{fetchinitailValues()},[])

    
    console.log("dimemarketcap",dimeMarketcap,jokerMarketCap,creditsMarketcap);
    const jokerCoin = (jokerMarketCap/1e6).toFixed(0);
    const credits = (creditsMarketcap/1e6).toFixed(0);
    const Dime = (dimeMarketcap/1e6).toFixed(0);
    const creditsDim = parseFloat(credits) + parseFloat(Dime);
    const rotate = (jokerCoin - creditsDim)/20; // divided by 50

    console.log("rotate",jokerCoin,creditsDim,rotate);
    const getSuffix = (num) => {
        if (num >= 1000000000) {
          return 'B';
        } else if (num >= 1000000) {
          return 'M';
        } else if (num >= 1000) {
          return 'K';
        } else {
          return '';
        }
      };


      
    return ( 
        
        <div className="box d-flex flex-column balance-diagram-outer align-items-center justify-content-sm-center  p-3" style={{backgroundColor: jokerCoin > creditsDim ? '' : '' }}>
           
                <center><h2>Marketcap</h2></center>
            <div className="balance-diagram">
                <img src={Poll} alt="Poll" className='balance-diagram-poll' />
                <img src={Angle} alt="Angle" className='balance-diagram-angle' style={{transform : `rotate(${rotate}deg)`}} />
                <div className='balance-weight balance-weight-left' style={{transform : `translateY(${-rotate*3}px)`}}>
                    <div className='balance-weight-group'>
                        <img src={BalanceWeight} alt='BalanceWeight' className='balance-weight-image' />

                        <div className='balance-weight-icons'>
                            <img src={CreditsIcon} alt='CreditsIcon' />
                            <img src={CreditsIcon} alt='CreditsIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                            <img src={CreditsIcon} alt='CreditsIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                            <img src={CreditsIcon} alt='CreditsIcon' />
                            <img src={CreditsIcon} alt='CreditsIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                            <img src={CreditsIcon} alt='CreditsIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                        </div>
                    </div>
                    <div className='balance-weight-info'>
                        CREDITS + DIME  <span className='text-muted'> 
                        {/* <NumberAbbreviation number={creditsDim}/> */}
    <NumericFormat value={creditsDim} displayType={'text'} thousandSeparator={true} suffix={getSuffix((creditsDim*1e6))}/>
    </span>
                    </div>
                </div>
                <div className='balance-weight balance-weight-right' style={{transform : `translateY(${rotate*3}px)`}}>
                    <div className='balance-weight-group'>
                        <img src={BalanceWeight} alt='BalanceWeight' className='balance-weight-image' />
                        <div className='balance-weight-icons'>
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                            <img src={JokerIcon} alt='JokerIcon' />
                        </div>
                    </div>
                    <div className='balance-weight-info'>
                        JOKER  <span className='text-muted'> 
                        <NumericFormat value={jokerCoin && jokerCoin} displayType={'text'} thousandSeparator={true} suffix={getSuffix((jokerCoin*1e6))}/>
                        </span>
                    </div>
                    {/* <div style={{paddingLeft:'220px'}}>
                <div style={{ position: 'revert', width: 'fit-content', padding: '10px', backgroundColor: 'cyan', border: '1px solid black', borderRadius: '10px 10px 10px 10px', textAlign: 'right' }}>
            Equilibrium
            <div style={{ position: 'absolute', top: '0', right: '-10px', width: '10px', height: '10px', backgroundColor: 'cyan', border: '1px solid black', borderRadius: '0 10px 0 0' }}></div>
        </div>
        </div> */}
                </div>
               
            </div>

            {jokerCoin && (<>
            <div className='p-2 w-100 text-end'>
                <button class="btn pb-1  outline btn-sm"  style={{backgroundColor:jokerCoin > creditsDim ?'green' :'red'}}>
                    <h5 style={{color:'white'}}>Equilibrium</h5>
                {/* <span style={{ backgroundColor: 'green' }}>Equilibrium</span> */}
                    </button>
            </div>
            </>)}
        </div>
     );
}

export default BalanceScale;