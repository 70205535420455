import { Col, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import SNXLogo from '../../asserts/IMAGES/snx-logo.svg'
import BackIcon from '../../asserts/IMAGES/back-white-icon.svg'
import ArrowDown from '../../asserts/IMAGES/arrowDown.svg'
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import { Link, useLocation } from "react-router-dom";
import Timer from "../Dashboard/Snippets/Timer";
import { useEffect, useState } from "react";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { API_KEY, CHAIN_URL, DAI_TOKEN_ABI, Network_Name, STAKING_ABI, TXN_ID } from "../../abi/RefinedABI&ContractAddress";
import { ethers } from "ethers";
import { CheckAllowance, PendingModal, getTokenBalance } from "../../abi/CommonSolFunctions";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
import { NumericFormat } from "react-number-format";

/* global BigInt */
function Stake({balances, balanceOfTokens}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const location =  useLocation();
  
    const [stateValues,setstatevalues] = useState([]);
    const[stakedAmount,setStakedAmount] = useState("");
    const[walletBalance,setwalletBalance] = useState("");
    const[enteredValue,setenteredValue] = useState("");
    const [activePercentage, setActivePercentage] = useState("");
    const[Allowance, setAllowance] = useState("");
    const[TotalStakedValue,setTotalStakedValue] = useState("");

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    const getAllBalanceFunction = async() =>{
        try{
        if (location.state)
            await recordUserVisits(address, `${location.state.TokenName} Stake`);
        if(isConnected){
            
            if (location.state){
                // setstatevalues(location.state);
                
                const StakingContract = new ethers.Contract(location.state.StakingContract, STAKING_ABI, provider);

                let userDeposited = await StakingContract.userInfo(address);
                setStakedAmount(ethers.utils.formatUnits((userDeposited.amount),0));

                const tokenContract = new ethers.Contract(location.state.depositTokenAddress, DAI_TOKEN_ABI, provider);

                let userbalance = await tokenContract.balanceOf(address);
                setwalletBalance(ethers.utils.formatUnits((userbalance),0));

                let allowance = await CheckAllowance(location.state.depositTokenAddress,DAI_TOKEN_ABI,address,location.state.StakingContract)
                setAllowance(allowance)

                let totalStakedvalue = await getTokenBalance(location.state.StakingContract,location.state.depositTokenAddress,DAI_TOKEN_ABI)
                setTotalStakedValue(totalStakedvalue)
            }
        }
        }catch(error){
            console.error(error);
        }
    }
    useEffect(() =>{getAllBalanceFunction()},[isConnected,address,stateValues]);


    const maxCall = async(percent) =>{
        setActivePercentage(percent);
        setenteredValue((walletBalance*percent)/100/10**location.state.decimalAmount)
    }

    const stake = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const stakingContract = new ethers.Contract(location.state.StakingContract, STAKING_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                // let inputvalue = (enteredValue * (10**(location.state.decimalAmount))).toFixed(0);
                let swaptxn ;
                if(enteredValue>1){
                    let inputvalue = Math.floor(enteredValue);
                    console.log("inputvalue",inputvalue);
                    let givenAmount = ethers.utils.parseEther(inputvalue.toString());
                    swaptxn = await stakingContract.deposit(givenAmount);
                }else{
                    let inputvalue = Math.floor(enteredValue * (10**location.state.decimalAmount));
                    console.log("inputvalue",inputvalue);
                    swaptxn = await stakingContract.deposit(BigInt(inputvalue));
                }
                

             
                
                await swaptxn.wait();
                await TxnId_PopUp(swaptxn.hash);
                await balanceOfTokens();
                await createTxn(location.state.TokenName,swaptxn.hash,"Stake",address,location.state.StakingContract);
                await resetState();
            }catch(err){
                console.log("error",err);
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAllBalanceFunction();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const resetState = async() =>{
        setenteredValue("");
        await balanceOfTokens();
    }

    const approve = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(location.state.depositTokenAddress, DAI_TOKEN_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const approveTxn = await JOKERContract.approve(location.state.StakingContract,BigInt(1000000000 * (10**location.state.decimalAmount)));
                
                await approveTxn.wait();
                await TxnId_PopUp(approveTxn.hash);
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }

    const allowanceTrue = parseInt(Allowance) >= parseInt(enteredValue*(10**location.state.decimalAmount));

    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <div className="p-24 px-xl-5 app-contain">
                <div className="mb-4 mb-lg-5">
                    <Link className="flex g-2 mb-3 text-white" to='/staking/welcome' state={location.state}><img src={BackIcon} alt="icon" /> Back</Link>
                    <Row>
                        <Col md={6} xl={8}>
                            <h2 className="mb-2">Stake {location.state? location.state.TokenName :"Token"}</h2>
                            <h6 className="text-gray mb-0">By staking {location.state? location.state.TokenName :"Token"} and earn rewards in exchange. Your staked {location.state? location.state.TokenName :"Token"} will be locked for 1 day.</h6>
                        </Col>
                    </Row>
                </div>

                <Row className="g-2 mb-20 justify-content-between">
                    <Col xs={'auto time-green'}>
                        <h5 className="flex g-2 text-gray mb-2">Estimated APR </h5>
                        {/* <Timer count={"2024-03-24T01:02:03"} /> */}
                        <h3 className="text-green-light mb-0">{TotalStakedValue?parseFloat(((location.state.blocksperyear*365)/(TotalStakedValue/(10**location.state.decimalAmount)))*100).toFixed(3):'0.0'}%</h3>
                    </Col>
                    <Col xs={'auto'} className="text-end">
                        {/* <h5 className="text-gray mb-2">SNX Bridge</h5>
                        <h3 className="text-green-light mb-0">$2.42</h3> */}
                    </Col>
                </Row>

                <Row className="g-lg-4 g-3 justify-content-center">
                    <Col md={8} sm={6}>
                        <div className="bg-body rounded-4 px-3 py-3">
                            <h5 className="flex g-1">How much {location.state? location.state.TokenName :"Token"} do you want to stake?  </h5>
                            <div className="swap-card mb-20">
                                <div className="mb-20 d-flex  flex-wrap justify-content-between align-items-center">
                                    <h3 className="flex mb-0 g-2">
                                        <img src={location.state? location.state.tokenLogo :""} alt="SNXLogo" height={'42px'}/>
                                        {location.state? location.state.TokenName :"Token"}
                                    </h3>
                                    <div className="text-end ms-auto">
                                        <h6 className="mb-1 font-weight-bold">Enter Amount</h6>
                                        <NumericFormat type="text" style={{maxWidth: '130'}} className="text-gray h3 btn-reset text-end border p-1 rounded-2" value={enteredValue} placeholder="Amount" allowNegative={false} onChange={(e) => setenteredValue(e.target.value)}/>
                                        {/* <h6 className="text-gray mb-0">
    <input style={{ backgroundColor: 'transparent', border: 'none', outline: 'none', paddingRight: '10px' }} value={enteredValue} placeholder="0.00" onChange={(e) => setenteredValue(e.target.value)}
     className="text-gray mb-0" />
    
</h6> */}



                                    </div>
                                </div>

                                <Row className="g-2">
                                                    <Col>
                                    <button className={`btn btn-toggle ${activePercentage === 25 ? 'active' : ''} w-100`} onClick={() => maxCall(25)}>
                                        <span>25%</span>
                                    </button>
                                    </Col>
                                    <Col>
                                    <button className={`btn btn-toggle ${activePercentage === 50 ? 'active' : ''} w-100`} onClick={() => maxCall(50)}>
                                        <span>50%</span>
                                    </button>
                                    </Col>
                                    <Col>
                                    <button className={`btn btn-toggle ${activePercentage === 75 ? 'active' : ''} w-100`} onClick={() => maxCall(75)}>
                                        <span>75%</span>
                                    </button>
                                    </Col>
                                    <Col>
                                    <button className={`btn btn-toggle ${activePercentage === 100 ? 'active' : ''} w-100`} onClick={() => maxCall(100)}>
                                        <span>100%</span>
                                    </button>
                                    </Col>
                                </Row>
                            </div>

                            {/* <div className="mb-3 flex justify-content-between g-2">
                                <h5 className="flex g-1 mb-0">Gas Price  <Tooltip text="Lorem Ipsum" /></h5>
                                <button className="btn text-gray flex g-2 btn-reset">$0.00 <img src={ArrowDown} alt="ArrowDown" /></button>
                            </div> */}
{allowanceTrue ?(<>
    <button type="submit" className="btn mb-3 btn-grad w-100" onClick={()=>stake()} >Stake</button>

</>):(<>
    <button type="submit" className="btn mb-3 btn-grad w-100" onClick={()=>approve()} >Approve</button>

</>)}
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <div className="swap-card flex justify-content-between mb-20 p-3 p-md-4">
                            <h4 className="mb-0 font-600">Your wallet balance</h4>
                            <h3 className="text-green-light mb-0">{walletBalance?parseFloat(walletBalance/(10**location.state.decimalAmount)).toFixed(4):'0.0'}</h3>
                        </div>
                        <div className="swap-card flex justify-content-between mb-20 p-3 p-md-4">
                            <h4 className="mb-0 font-600">Total Deposited</h4>
                            <h3 className="text-green-light mb-0">{stakedAmount?parseFloat(stakedAmount/(10**location.state.decimalAmount)).toFixed(4):'0.0'}</h3>
                        </div>
                    </Col>
                </Row>
            </div>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </LayoutAfterLogin>
    );
}

export default Stake;