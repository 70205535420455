import Header from './Header';

function LayoutAfterLogin(props) {
    // console.log(props.menuActive)
    return ( 
        <div className="app-dashboard">
            <Header menuActive={props.menuActive} balances={props.balances} />

            <div className='app-content'>
                {props.children}
            </div>
        </div>
    )
}

export default LayoutAfterLogin;