import Poll from '../../../asserts/IMAGES/pole.svg'
import Angle from '../../../asserts/IMAGES/pole-angle.svg'
import BalanceWeight from '../../../asserts/IMAGES/balanceWeight.svg'
import JokerIcon from '../../../asserts/IMAGES/jokerRedLogo.svg'
import CreditsIcon from '../../../asserts/IMAGES/creditsBlackLogo.svg'
import DimeIcon from '../../../asserts/IMAGES/dimeCyanLogo.svg'
import DaiIcon from '../../../asserts/IMAGES/daidimelp.svg';
import { useEffect, useState } from 'react'
import {NumericFormat}  from 'react-number-format';
import { NumberAbbreviation, getCreditsPrice, getCreditsSupply, getDAIPrice, getDimePrice, getDimeSupply, getJokerPrice, getJokerSupply, getTokenBalance } from '../../../abi/CommonSolFunctions'
import { CREDITS_Token_ABI, CREDITS_Token_Address, Communitywallet_Address, DAI_TOKEN_ABI, DAI_TOKEN_Address, DIME_LP_Token_Address, DIME_TREASURY_Address, DIME_Token_ABI, DIME_Token_Address, ECO_Reserve_Treasury_Address, Faucet_Address, JOKER_Token_ABI, JOKER_Token_Address, LP_Token_ABI } from '../../../abi/RefinedABI&ContractAddress'

function DimeBalanceScale() {
    
    const[dimeMarketcap,setDimeMarketcap] = useState("");
    const[lpTokenBal,setlpTokenBal] = useState("");
    const fetchinitailValues = async () => {
        let jokermarketcap = parseFloat(await getDimeSupply()) * parseFloat(await getDimePrice());
        let dimetreasury = await getTokenBalance(ECO_Reserve_Treasury_Address, DIME_Token_Address, DIME_Token_ABI);
        let creditstreasury = await getTokenBalance(ECO_Reserve_Treasury_Address, CREDITS_Token_Address, CREDITS_Token_ABI);
        let treasueryDimeBal = await getTokenBalance(DIME_TREASURY_Address, DIME_Token_Address, DIME_Token_ABI);
        let treasueryDimeBalowner = await getTokenBalance(Communitywallet_Address, DIME_Token_Address, DIME_Token_ABI);
        let treasueryDimeBalfaucet = await getTokenBalance(Faucet_Address, DIME_Token_Address, DIME_Token_ABI);


        let dimemarketcap = (parseFloat(await getDimeSupply())-(parseFloat(dimetreasury)+parseFloat(treasueryDimeBal)+parseFloat(treasueryDimeBalowner)+parseFloat(treasueryDimeBalfaucet))) * parseFloat(await getDimePrice());
       
        let treasuerylpBal = parseFloat(await getTokenBalance(DIME_TREASURY_Address, DIME_LP_Token_Address, LP_Token_ABI)) * parseFloat(await getDAIPrice());
        // let treasueryJokerBal = parseFloat(await getTokenBalance(DIME_TREASURY_Address, JOKER_Token_Address, JOKER_Token_ABI)) + parseFloat(await getJokerPrice());
        setlpTokenBal(treasuerylpBal/1e26)


        // let creditsmarketcap = (parseFloat(await getCreditsSupply())-parseFloat(creditstreasury)) * parseFloat(await getCreditsPrice());
        // setjokerMarketCap((treasueryJokerBal/1e17));
        setDimeMarketcap(dimemarketcap/1e17);
        // setCreditsMarketcap(treasueryDaiBal/1e27);
    }
    useEffect(() =>{fetchinitailValues()},[])

    console.log("dimebalancemarketcap",dimeMarketcap,lpTokenBal);
    const lpcoin = parseFloat(lpTokenBal).toFixed(4);
    // const credits = (creditsMarketcap/1e6).toFixed(0);
    const Dime = parseFloat(dimeMarketcap).toFixed(0);
    // const creditsDim = parseFloat(credits) + parseFloat(lpcoin);
    // const rotate = (lpcoin - Dime)/20; // divided by 50
    const rotate = lpcoin > Dime ? 10 : Dime > lpcoin ? -10 : 0
    // const rotate = 0

    console.log("rotatdimee",lpcoin,rotate);
    const getSuffix = (num) => {
        if (num >= 1000000000) {
          return 'B';
        } else if (num >= 1000000) {
          return 'M';
        } else if (num >= 1000) {
          return 'K';
        } else {
          return '';
        }
      };
    return ( 
        
        <div className="box d-flex flex-column balance-diagram-outer align-items-center justify-content-sm-center  p-3" >
           
                <center><h2>DIME</h2></center>
            <div className="balance-diagram">
                <img src={Poll} alt="Poll" className='balance-diagram-poll' />
                <img src={Angle} alt="Angle" className='balance-diagram-angle' style={{transform : `rotate(${rotate}deg)`}} />
                <div className='balance-weight balance-weight-left' style={{transform : `translateY(${-rotate*3}px)`}}>
                    <div className='balance-weight-group'>
                        <img src={BalanceWeight} alt='BalanceWeight' className='balance-weight-image' />

                        <div className='balance-weight-icons'>
                            
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            <img src={DimeIcon} alt='JokerIcon' />
                            
                        </div>
                    </div>
                    <div className='balance-weight-info'>
                        DIME  <span className='text-muted'> 
                        {/* <NumberAbbreviation number={creditsDim}/> */}
    <NumericFormat value={Dime && Dime} displayType={'text'} thousandSeparator={true} suffix={getSuffix((Dime && Dime))}/>
    </span>
                    </div>
                </div>
                <div className='balance-weight balance-weight-right' style={{transform : `translateY(${rotate*3}px)`}}>
                    <div className='balance-weight-group'>
                        <img src={BalanceWeight} alt='BalanceWeight' className='balance-weight-image' />
                        <div className='balance-weight-icons'>
                        <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DaiIcon} alt='DimeIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DaiIcon} alt='DimeIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DaiIcon} alt='DimeIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DaiIcon} alt='DimeIcon' />
                            <img src={DaiIcon} alt='DimeIcon' />
                        </div>
                    </div>
                    <div className='balance-weight-info'>
                        DIME + DAI LP<span className='text-muted'> 
                        <NumericFormat value={lpcoin && lpcoin} displayType={'text'} thousandSeparator={true} suffix={getSuffix((lpcoin && lpcoin))}/>
                        </span>
                    </div>
                </div>
            </div>
            {lpcoin && (<>
                <div className='p-2 w-100 text-end'>
                <button class="btn pb-1  outline btn-sm"  style={{backgroundColor: lpcoin > Dime ?'green' :'red'}}>
                    <h5 style={{color:'white'}}>Equilibrium</h5>
                    </button>
            </div>
            </>)}
            
        </div>
     );
}

export default DimeBalanceScale;