import { Badge, Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import Preview from "../asserts/IMAGES/loginImage.jpg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg'
import bgBackgroundImage from '../asserts/IMAGES/top-bg.svg';
import { ConnectWallet, PendingModal } from "../abi/CommonSolFunctions";
import { AddWallet, checkWalletEligible } from "../abi/firebasecode";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Communitywallet_Address } from "../abi/RefinedABI&ContractAddress";




function AddUser({connectWallet}) {
    const [eligible, seteligibile] = useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const[addressInput,  setaddressinput] = useState("");
    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");
    
    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    
    const addeliglibile = async() =>{
        let eligible1 = await AddWallet(addressInput,true);
       showPopUp("noo","","","")
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }



    
    return ( 
        // <LayoutBeforeLogin>
        <Container fluid className="p-0" style={{ backgroundImage: `url(${bgBackgroundImage})`, backgroundSize: 'cover', minHeight: '100vh' }}>
            <div className="px-md-0 px-3">
        <Row className="app-blogin-row align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Col md={12}>
                <div className="app-blogin-inner">
                    <div className="d-md-none text-center mb-4">
                        <img src={LoginIcon} alt="logo icon" />
                    </div>
                    <div className="mb-4 text-center text-md-start">
                        <h1 className="text-28 text-600 mb-1">Add Eligibility Address!</h1>
                        {/* <p className="text-500">Enter your registered email & password to log in.</p> */}
                    </div>

                    <Form>
                    
                        <Button  variant="grad" className="d-block w-100 mb-2" onClick={()=>connectWallet()} >{isConnected ? 'Connected':'Connect Wallet'}</Button>

                        {address === Communitywallet_Address && isConnected ? (<>
                            <Form.Group className="mb-3">
                                    <Form.Label>Enter Wallet Address:</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Wallet Address" 
                                        value={addressInput} 
                                        onChange={(e)=>setaddressinput(e.target.value)} 
                                    />
                                </Form.Group>

                                <Button  variant="grad" className="d-block w-100 mb-2"  onClick={()=>addeliglibile()}>Add User</Button>

                                </>):(<></>)}
                      
                        
                        {/* <p className="px-md-5 px-4 text-center mb-3">By clicking on log in you agree with the divinedim’s terms of service & privacy guidelines</p> */}
                    </Form>
                </div>
            </Col>
        </Row>
        </div>
        <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

    </Container>
        // </LayoutBeforeLogin>
    )
}

export default AddUser;
