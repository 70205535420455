import LayoutAfterLogin from "../../component/LayoutAL";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Deposit from "./snippet/Deposit";
import Withdraw from "./snippet/Withdraw";
import Borrow from "./snippet/Borrow";
import Liquidate from "./snippet/Liquidate";
import Repay from "./snippet/Repay";
import { useState } from "react";

function Vault({balances, balanceOfTokens}) {
    const [selectedTab, setSelectedTab] = useState('Deposit'); // State to track the selected tab

    const handleTabChange = (tabKey) => {
        setSelectedTab(tabKey);
    };

    return (  
        <LayoutAfterLogin menuActive={"carbonix"} balances={balances}>
            <Tabs
                defaultActiveKey="Deposit"
                id="uncontrolled-tab-example"
                className="justify-content-center"
                onSelect={(eventKey) => handleTabChange(eventKey)}
                >
                <Tab eventKey="Deposit" title="Deposit">
                    <Deposit selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
                <Tab eventKey="Withdraw" title="Withdraw">
                    <Withdraw selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
                <Tab eventKey="Borrow" title="Borrow">
                    <Borrow selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
                <Tab eventKey="Repay" title="Repay">
                    <Repay selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
                <Tab eventKey="Liquidate" title="Liquidate">
                    <Liquidate selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
            </Tabs>
        </LayoutAfterLogin>
    );
}

export default Vault;