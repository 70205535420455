import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
        display: false,
      position: 'top',
    },
    title: {
      display: false
    },
  },
  aspectRatio:3,
  scales: {
      x: {
          ticks: {
              color: "#979797",
              // fontSize: 18,
              stepSize: 1,
              beginAtZero: true
          },
          display: true,
          grid: {
              color: '#333333'
          },
      },
      y: {
          ticks: {
              color: "#979797",
              // fontSize: 18,
              stepSize: 1,
              beginAtZero: true
          },
          display: true,
          grid: {
              color: '#333333'
          }
      }
  }
};

// const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// const createBarGradient = (startColor, endColor) => {
//     const gradient = document.createElement('canvas').getContext('2d');
//     gradient.canvas.height = 1;
//     gradient.canvas.width = 100;

//     const barGradient = gradient.createLinearGradient(0, 0, 100, 0);
//     barGradient.addColorStop(0, startColor);
//     barGradient.addColorStop(1, endColor);

//     return barGradient;
//   };

// export const data = {
//   labels,
//   datasets: [
//     {
//       backgroundColor: 'transparent'
//     },
//     {
//       label: 'Treasury',
//       data: [60,30,40,35,65,43,70,35,45,65,58,45],
//       backgroundColor: '#00C2FF'
//     },
//     {
//       backgroundColor: 'transparent'
//     }
//   ],
// };

export default function Bardata({labels,datas}) {
  const data = {
    labels,
    datasets: [
      {
        backgroundColor: 'transparent'
      },
      {
        label: 'Treasury',
        data: datas,
        backgroundColor: '#00C2FF'
      },
      {
        backgroundColor: 'transparent'
      }
    ],
  };
  return <Bar options={options} data={data} />;
}
