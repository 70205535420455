import { Col, Modal, Row , Spinner} from "react-bootstrap";
import Icon1 from '../../asserts/IMAGES/joker-icon.svg';
import Icon2 from '../../asserts/IMAGES/dimeCyanLogo.svg';
import SwapBtn from '../../asserts/IMAGES/swap-btn.svg';
import EthereumIcon from '../../asserts/IMAGES/Ethereum-icon.svg';
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import ArrowLink from '../../asserts/IMAGES/arrowLink.svg';
import ProgressBar from "../Dashboard/Snippets/CircleTimer";
import Timer from "../Dashboard/Snippets/Timer";
import { useEffect,useState } from "react";
import tauIcon from '../../asserts/IMAGES/tau-original.png';

import { CheckAllowance, PendingModal,   convertEpochToDateTime, getGasBalance, getTokenBalance } from "../../abi/CommonSolFunctions";
import { CHAIN_URL, TAU_Token_ABI, TAU_Token_Address, TXN_ID, WETH_TOKEN_Address, TAU_Bond_ABI, TAU_Bond_Address, DIME_Token_Address, DIME_Token_ABI, DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, TAU_Chainlink_Oracle_Address, Network_Name, API_KEY } from "../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
import LayoutAfterLogin from "../../component/LayoutAL";
import { NumericFormat } from "react-number-format";
/* global BigInt */

function Rollover({ balances, balanceOfTokens }) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    //usestate values
    const [DimeBalance, setDimeBalance] = useState("");
    const [TauBalance, setTauBalance] = useState("");
    const [tauAmount,settauAmount] = useState("");
    const [OutputValue,setOutputValue] = useState("");
    const [tauAllowance,settauAllowance] = useState("");
    const [isReedemption,setisReedemption] = useState(false);
    const [exchangeRate,setExchangeRate] = useState("");


    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);
   
    // const JOKERContract = new ethers.Contract(TAU_Token_Address, TAU_Token_ABI, provider);

    const getAllBalanceFunction = async() =>{
        // if(selectedTab === "Redemption")
            await recordUserVisits(address, "Redemption");
        try{
            if(isConnected){
                let tauBalance = await getTokenBalance(address,TAU_Token_Address,TAU_Token_ABI);
                console.log("DimeBalance",tauBalance)
                let dimeBalance = await getTokenBalance(address,DIME_Token_Address,DIME_Token_ABI);
                setDimeBalance(dimeBalance);
                setTauBalance(tauBalance);
    
                let tauallowance = await CheckAllowance(TAU_Token_Address,TAU_Token_ABI,address,TAU_Bond_Address);
                settauAllowance(tauallowance);
                console.log(tauallowance);
    
                
                const dimeOracle = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
                const TauOracle = new ethers.Contract(TAU_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
                let dimePrice = ethers.utils.formatUnits(await dimeOracle.getChainlinkDataFeedLatestAnswer(),0);
                let tauprice = ethers.utils.formatUnits(await TauOracle.getChainlinkDataFeedLatestAnswer(),0);
                // let dimePrice = 1e8;
                // let tauprice = 10e8;
                const TAUBondContract = new ethers.Contract(TAU_Bond_Address, TAU_Bond_ABI, provider);
                let reseemThreshold = ethers.utils.formatUnits(await TAUBondContract.minRedeemPriceThreshold(),0);
                if(dimePrice > reseemThreshold){
                    setisReedemption(true);
                }
                let exchangeRate = (1 * 1e9 * tauprice)/dimePrice;
                setExchangeRate(exchangeRate /1e9);
                // let secondsleft = ethers.utils.formatUnits(await JOKERContract._secondsLeft(address),0);
                // setSecondsLeft(await convertEpochToDateTime(secondsleft))
                // setLockedTrue(secondsleft)
                // console.log("Seconds left",secondsleft)
    
                // let value = await JOKERContract.checkSwapValue(1*1e9,[TAU_Token_Address,WETH_TOKEN_Address]);
                // let ethAmount = ethers.utils.formatUnits(value,0);
                // setExchangeRate(ethAmount);
                
    
            }
        }catch(e){
            console.log(e);
        }
       
        
    }

    useEffect(() =>{getAllBalanceFunction()},[address,isConnected]);

    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const changeinput = async(e) => {
        settauAmount(e);
        
        try{
           
            const dimeOracle = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
            const TauOracle = new ethers.Contract(TAU_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
            let dimePrice = ethers.utils.formatUnits(await dimeOracle.getChainlinkDataFeedLatestAnswer(),0);
            let tauprice = ethers.utils.formatUnits(await TauOracle.getChainlinkDataFeedLatestAnswer(),0);
            // let dimePrice = 1e8;
            // let tauprice = 10e8;

            let tauTokenMint = (e * 1e18 * tauprice)/dimePrice;
            setOutputValue(tauTokenMint)
        }
        catch(err){
            console.log(err)
            setOutputValue(0);
        }
         
    }

    const maxCall = async() =>{
        if(TauBalance > 0){
            changeinput(TauBalance/1e18)
        }
    }

    const approve = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const TAUContract = new ethers.Contract(TAU_Token_Address, TAU_Token_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const approveTxn = await TAUContract.approve(TAU_Bond_Address,BigInt(1000000000 * 1e18));
                
                await approveTxn.wait();
                await TxnId_PopUp(approveTxn.hash);
            }catch(err){
                console.log("error",err);
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                
                
            }
        }
    }

    const redeem = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner();
                
                const TAUBondContract1 = new ethers.Contract(TAU_Bond_Address, TAU_Bond_ABI, signer);
                console.log(TAUBondContract1);
                await showPopUp("yes",TransactionPending,"","");
                const redeemtxn = await TAUBondContract1.withdraw(BigInt((parseFloat(tauAmount)*1e18).toFixed(0)));
                
                await redeemtxn.wait();
                await TxnId_PopUp(redeemtxn.hash);
                await balanceOfTokens();
                await createTxn("TAU",redeemtxn.hash,"Redemption",address,TAU_Bond_Address);
                await resetState();
            }catch(err){
                console.log("error",err);
                console.log("error",err.TypeError);
                let errorData ;
                if(err.reason){
                    errorData = err.reason;
                }else 
                {
                    errorData = err;
                }
                showPopUp("not",TransactionPending,"",(errorData).toString());
                console.log("error",err);
                
            }
        }
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAllBalanceFunction();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const resetState = async() =>{
        settauAmount("");
        setOutputValue("");
        await balanceOfTokens();
    }

    const allowanceTrue = parseInt(tauAllowance) >= parseInt(tauAmount*1e9);

    return ( 
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
        <div className="p-24">
             <Row className="justify-content-center">
                <Col md={12} style={{maxWidth: '800px'}}>
                    <div className="box p-3 p-md-4">
                        <div className="swap-card d-flex align-items-center">
                            <div>
                                <img src={tauIcon} height="42px" alt="Icon2" />
                            </div>
                            <div>
                                <h5>TAU</h5>
                                <h6>Bal: 
                                {parseFloat(TauBalance) === 'NaN' ? '0.00' :  formatValues((TauBalance/1e18),2)}
                                    </h6>
                            </div>
                            <div className="flex-grow-1 py-2">
                                <NumericFormat value={tauAmount} placeholder="0.00" className="input-reset" allowNegative={false} onChange={(e) => changeinput(e.target.value)}/>
                            </div>

                            <button className="btn btn-grad py-2" onClick={()=>maxCall()}>Max</button>
                        </div>
                        <div className="py-2 text-center">
                            <button type="button" className="btn-reset"><img src={SwapBtn} alt="SwapBtn" /></button>
                        </div>
                        <div className="swap-card mb-4 d-flex align-items-center">
                            <div>
                                <img src={Icon2} alt="Icon1" height={'42px'}/>
                            </div>
                            <div>
                                <h5>DIME</h5>
                                <h6>Bal:
                                {parseFloat(DimeBalance) === 'NaN' ? '0.00' :  formatValues((DimeBalance/1e9),2)}
                                     </h6>
                            </div>
                            <div className="flex-grow-1 py-2">
                                <input readonly disabled  placeholder="0.00" className="input-reset" value={parseFloat(OutputValue) ? (parseFloat(OutputValue)/1e18) : '0.00'}/>
                            </div>
                        </div>
                        
                         <div className="box swap-value border-top">
                        <div className="p-4 border-bottom">
                            <h6>Exchange Rate</h6>
                            <h4>1 &nbsp; TAU   &nbsp;= &nbsp; {exchangeRate?parseFloat(exchangeRate).toFixed(8):'0'} &nbsp; DIME </h4>
                        </div>
                        {/* <div className="p-4 border-bottom">
                            <h6>Exchange Fees</h6>
                            <h4> 50% &nbsp; ETH </h4>
                        </div> */}
                        <div className="p-4 border-bottom">
                            <h6>You Will Receive</h6>
                            <h4>{OutputValue?parseFloat(OutputValue / 1e18):'0.00'}</h4>
                        </div>
                        <div className="px-4 pb-4 pt-4">
                        {/* {allowanceTrue?(<>
                            <button type="submit" className="btn btn-grad w-100 outline py-2 "><span>Approve TAU</span></button>
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approve()}>Approve TAU</button>
                        </>)} */}
                        {allowanceTrue ?(<>
                        {isReedemption?(<>
                            <button  type="submit"  className=" btn mb-20 btn-grad  w-100 " onClick={()=>redeem()}>
                        <span>Redemption</span></button>
                        </>):(<>
                            <button  type="submit"  className=" btn mb-20 btn-grad  w-100 " disabled>
                        <span>Rollover is not  available</span></button>
                        </>)}
                       
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approve()}>Approve TAU</button>
                        </>)}
                      
                       
                        
                      
                       
                        {!isReedemption && 
                        <div className="mt-4 text-center">
                            <p className="text-gray mb-0">Disclaimer: Rollover will not be available until the DIME price is above $1.
                            {/* <Tooltip text="Lorem Ipsum Content." /> */}
                            </p>
                        </div>
                        }
                           
                        </div>
                    </div>
                    </div>
                </Col>
                {/* <Col md={6}>
                   
                </Col> */}
            </Row>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </div>
        </LayoutAfterLogin>
     );
}

export default Rollover;