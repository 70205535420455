import { createContext, useContext, useEffect, useState } from "react";
import { fetchDimeMarketcap, fetchPrice, fetchjokerMarketcap } from "./CommonSolFunctions";

export const PriceContext = createContext();

// Create a context provider component
export const PriceProvider = ({ children }) => {
  const [tokenPrice, settokenPrice] = useState([]);

  useEffect(() => {
    const getPrice = async () => {
      let [jokerPrice, dimePrice, creditsPrice] = await fetchPrice();
      let alltokenPrices = {
        jokerPrice: jokerPrice,
        dimePrice: dimePrice,
        creditsPrice: creditsPrice
      };
      settokenPrice(alltokenPrices);
    };
    getPrice();
  }, []);

  return (
    <PriceContext.Provider value={{ tokenPrice, settokenPrice }}>
      {children}
    </PriceContext.Provider>
  );
};

// Custom hook to use the PriceContext
export const usePriceContext = () => useContext(PriceContext);

//----------------------------------DIME MarketCap -----------------------------------//
export const DimeMarketCapContext = createContext();

// Create a context provider component
export const DimeMarketCapProvider = ({ children }) => {
  const [DimeMarketCap, setDimeMarketCap] = useState("");

  useEffect(() => {
    const getPrice = async () => {
      let dimemarketcap = await fetchDimeMarketcap();
      setDimeMarketCap(dimemarketcap)
    };
    getPrice();
  }, []);

  return (
    <DimeMarketCapContext.Provider value={{ DimeMarketCap, setDimeMarketCap }}>
      {children}
    </DimeMarketCapContext.Provider>
  );
};

// Custom hook to use the PriceContext
export const useDimeMarketCapContext = () => useContext(DimeMarketCapContext);

//----------------------------------Joker MarketCap -----------------------------------//
export const JokerMarketCapContext = createContext();

// Create a context provider component
export const JokerMarketCapProvider = ({ children }) => {
  const [JokerMarketCap, setJokerMarketCap] = useState("");

  useEffect(() => {
    const getPrice = async () => {
      let jokermarketcap = await fetchjokerMarketcap();
      setJokerMarketCap(jokermarketcap)
    };
    getPrice();
  }, []);

  return (
    <JokerMarketCapContext.Provider value={{ JokerMarketCap, setJokerMarketCap }}>
      {children}
    </JokerMarketCapContext.Provider>
  );
};

// Custom hook to use the PriceContext
export const useJokerMarketCapContext = () => useContext(JokerMarketCapContext);