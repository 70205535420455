import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import Preview from "../asserts/IMAGES/loginImage.jpg";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import LoginIcon from '../asserts/IMAGES/jokerRedLogo.svg'
import coverimage from '../asserts/IMAGES/coverimage.jpg';
import jokerIcon from '../asserts/IMAGES/jokerRedLogo.svg';

function CreateAccount() {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const apppage = async() =>{
        navigate('/');
    }
    
    return ( 
        // <LayoutBeforeLogin>
            <Container fluid style={{ backgroundImage: `url(${coverimage})`, backgroundSize: 'cover',backgroundAttachment:'fixed', backgroundRepeat: 'no-repeat' ,  minHeight: '100vh' }}>
            <div className="px-md-0 px-3">
             
                <div className="align-items-center" style={{ height: "100vh",alignContent:'flex-end' }}>
      <h2 className="text-128 text-600 mb-2 text-center">
        Welcome to JOKER PROTOCOL
      </h2>
      <div className="align-items-center" style={{textAlign:'-webkit-center' }}>

      <Button variant="grad" className="d-block text-128 text-600 mb-4 text-center w-10  " onClick={()=>apppage()} >Continue to App page</Button>
      </div>
    </div>
                </div>
            </Container>
        // </LayoutBeforeLogin>
    )
}

export default CreateAccount;