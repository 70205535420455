import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        title: {
        display: false,
        text: 'Chart.js Line Chart',
        },
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
    },
    scales: {
        x: {
            display: false
        },
        y: {
            display: false
        }
    },
    backgroundColor: 'transparent'
};

    // const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY'];
    // data: [2,20,12,15,9.247737500000001]
    // const canvas = document.getElementsByTagName('canvas')[0];
    // console.log(canvas);

    // const ctx = canvas?.getContext('2d');
    // var gradient = ctx?.createLinearGradient(0, 0, 0, 400);
    //     gradient?.addColorStop(0, 'rgba(250,174,50,1)');   
    //     gradient?.addColorStop(1, 'rgba(250,174,50,0)');

    
export  function LineChart({color,labels,prices,name}) {
  // console.log('LineChart',prices,labels)
  const data = {
    labels,
    // dates,
    datasets: [
      {
        fill: true,
        label: name,
        // data: [2,20,12,15,9.247737500000001],
        data: prices,
        borderColor: color ? color : '#45B68D'
      },
    ],
  };

    return <Line options={options} data={data} />;
  
}
