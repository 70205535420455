import { Form } from "react-bootstrap";
import { AreaChart } from "./AreaChart";
import { getCirculatingSupplyOneDay, getCirculatingsupplyOneMonth, getCirculatingsupplyOneWeek, getCirculatingsupplyOneYear, getMarketcapOneDay, getMarketcapOneMonth, getMarketcapOneWeek, getMarketcapOneYear, getPriceOneDay, getPriceOneMonth, getPriceOneWeek, getPriceOneYear, updatePrice } from "../../../abi/firebasecode";
import { useEffect, useState } from "react";
import { NumberAbbreviation, getJokerPrice, getJokerSupply } from "../../../abi/CommonSolFunctions";
import { API_KEY, CHAIN_URL, JOKER_Token_ABI, JOKER_Token_Address, Network_Name } from "../../../abi/RefinedABI&ContractAddress";
import { ethers } from "ethers";

import jokerLogo from '../../../asserts/IMAGES/jokerRedLogo.svg';

const JockerCard =() =>{
   
  const provider = ethers.getDefaultProvider(Network_Name, {
    etherscan: API_KEY});
    const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);

    const [Oneyearvalue, setOneyearvalue] = useState(0);
    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);
    
    const [activeButton, setActiveButton] = useState('1Y');
    const [jokerPrice, setjokerPrice] = useState("");
    const [TotalSupply, setTotalSupply] = useState("");
    const [title, setTitle] = useState("supply");

    // console.log("getMonthValues", Oneyearvalue);

    const getallvalues = async() =>{
      try{

      let oneyearvalue = await getCirculatingsupplyOneMonth("JokerPrice")
        setOneyearvalue(oneyearvalue)
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        
        setOneyearprices(prices)

        

      }catch(e){
        console.log(e);
    }
    
    }
    useEffect(() =>{getallvalues();},[])
    

  

    
    return ( 
        <div className="box border rounded-3">
            <div className="p-3 d-flex align-items-center flex-warp border-bottom">
              
                <img src={jokerLogo} height={'35px'}  style={{minWidth: '35px'}} alt="jokerLogo" />
      &nbsp;  <h4 className="mb-0">TotalSupply</h4>
                
                {/* <div className="chart-filter d-flex align-items-center ms-auto">
                    
                    <button
                    className={activeButton === '1W' ? 'active' : ''}
                    onClick={() => callselectedFunction('1W')} >
                    1W
                    </button>
                    <button
                    className={activeButton === '1M' ? 'active' : ''}
                    onClick={() => callselectedFunction('1M')} >
                    1M
                    </button>
                    <button
                    className={activeButton === '1Y' ? 'active' : ''}
                    onClick={() => callselectedFunction('1Y')} >
                    1Y
                    </button>
                </div> */}
            </div>

        

            <div className="p-3">
                <AreaChart dates={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name={"JOKER"} checkvalue={title?title:"price"} />
            </div>
        </div>
     );
}

export default JockerCard;