import { Col, ProgressBar, Row, Table } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import DaiIcon from '../../asserts/IMAGES/dai.svg'
import AlpacaIcon from '../../asserts/IMAGES/alpacaFinance-logo.png'
import YearnIcon  from '../../asserts/IMAGES/yearnFinance-logo.png'
import { CircularProgressbar } from "react-circular-progressbar";
import ChartIcon from '../../asserts/IMAGES/chatJokericon.svg';
import dimeicon from '../../asserts/IMAGES/dimeCyanLogo.svg';
import creditsLogo from '../../asserts/IMAGES/creditsBlackLogo.svg';
import CreditsBalanceScale from '../Dashboard/Snippets/balanceScaleCredits';
import daiwithDime from '../../asserts/IMAGES/daidimelp.svg';


// import TotalTransaction from "./snippet/TotalTransaction";
import { AreaChartPlan } from "../Dashboard/Snippets/AreaChartPlan";
import { useState, useEffect } from "react";

import { CheckAllowance, NumberAbbreviation, getCreditsPrice, getDAIPrice, getDimePrice, getTokenBalance } from "../../abi/CommonSolFunctions";
import { CHAIN_URL,  Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, Carbonix_Finance_Address, Carbonix_Finance_ABI, Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI,  DAI_Chainlink_Oracle_Address,  CREDITS_Token_Address, CREDITS_Token_ABI, CREDITS_Chainlink_Oracle_Address, API_KEY, Network_Name, Credits_Pool_Address, credits_pool_ABI, DIME_Token_Address, DIME_Token_ABI } from "../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { getCOllateralOneYear, recordUserVisits, updateCollateralValue } from "../../abi/firebasecode";
import PieChart from "../Dashboard/Snippets/PieChart";
import PieChartWithLegend from "../Dashboard/Snippets/PieChart";
import PieChartWithInternalLabels from "../Dashboard/Snippets/PieChart";
import ChartComponent from "../Dashboard/Snippets/PieChart";

function Carbonix({balances}) {

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    const [DaiBalance,setDaiBalance] = useState("");
    const[dimebalan,setDimeBalance] = useState("");
    const[creditsValue,setCreditsValue] = useState("");
    const [usdccolratio,setUsdcColratio] = useState("");
    const [mintfee,setMintfee] = useState("");
    const [mintpricethreshold,setmintpricethreshold] = useState("");
    const [redeempercent,setRedeempercent] = useState("");
    const [Redeempricethreshold,setRedeempricethreshold] = useState("");
    const[buybackfee,setbuybackfee] = useState("");
    const [AvailableBuyback,setAvailableBuyback] = useState("");
    const [recolfee,setRecolFee] = useState("");
    const [Availablerecoll,setAvailablerecoll] = useState("");
    const [DaiBalanceinPool,setDaiBalanceinPool] = useState("");
    const [Dimebalanceinpool,setDimebalanceinpool] = useState("");



    const getAssetDetails = async() =>{                  
                console.log("useeffect");
                try{
                    const creditPoolContractinstance = new ethers.Contract(Credits_Pool_Address, credits_pool_ABI, provider);
                    let creditsDaiBalance = ethers.utils.formatUnits(await creditPoolContractinstance.collatDollarBalance(),0);

                    setDaiBalance(parseFloat(creditsDaiBalance/1e18)*parseFloat(await getDAIPrice()/1e8))

                    let dimebalance = await getTokenBalance(Credits_Pool_Address,DIME_Token_Address,DIME_Token_ABI);
                    setDimeBalance(parseFloat(dimebalance/1e9)*parseFloat(await getDimePrice()/1e8));
                    
                    const CreditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
                    let creditMintValue  = ethers.utils.formatUnits(await CreditsContract.hasMinted(Credits_Pool_Address),0);
                    setCreditsValue(parseFloat(creditMintValue/1e18)*parseFloat(await getCreditsPrice()/1e8))

                    let col_ratio =  ethers.utils.formatUnits((await CreditsContract.global_collateral_ratio()),0);
                    const PRICE_PRECISION = 1e6;
                    let Overallcol_ratio=parseFloat(col_ratio/PRICE_PRECISION)*100;
                    setUsdcColratio(Overallcol_ratio);

                    let mint_fee =ethers.utils.formatUnits((await CreditsContract.minting_fee()),0);
                    setMintfee(mint_fee/PRICE_PRECISION);
                    let mintpricethreshold =   ethers.utils.formatUnits((await creditPoolContractinstance.mint_price_threshold()),0);
                    setmintpricethreshold(mintpricethreshold/PRICE_PRECISION);
                    let redemption_fee = ethers.utils.formatUnits((await CreditsContract.redemption_fee()),0);
                    setRedeempercent(redemption_fee/PRICE_PRECISION);
                    let redeempricethreshold =   ethers.utils.formatUnits((await creditPoolContractinstance.redeem_price_threshold()),0);
                    setRedeempricethreshold(redeempricethreshold/PRICE_PRECISION);
                    let buyback_fee = 6000;
                    setbuybackfee(parseFloat(buyback_fee/PRICE_PRECISION));
                    let availablebuyback = ethers.utils.formatUnits((await creditPoolContractinstance.buybackAvailableCollat()),0);
                    setAvailableBuyback(availablebuyback/1e18);
                    const recollat_fee = 6000;
                    setRecolFee(parseFloat(recollat_fee/PRICE_PRECISION));
                    
                    let availablerecolla = ethers.utils.formatUnits((await creditPoolContractinstance.recollatAvailableDime()),0);
                    setAvailablerecoll(availablerecolla/1e18);

                    let daibalance = await getTokenBalance(Credits_Pool_Address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
                    let DimeBalance = await getTokenBalance(Credits_Pool_Address,DIME_Token_Address,DIME_Token_ABI);

                    setDaiBalanceinPool(daibalance/1e18);
                    setDimebalanceinpool(dimebalance/1e9);
            }catch(err){
                console.log(err);
            }
             
        
        
    }

    useEffect(()=>{getAssetDetails()},[address, isConnected]);


    

    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const chartData = {
        labels: ['DIME', 'DAI'],
        values: [Dimebalanceinpool?Dimebalanceinpool:10, DaiBalanceinPool?DaiBalanceinPool:20],
        colors: ['cyan', '#F6C324'],
      };
    return (  
        <LayoutAfterLogin menuActive={"tau"} balances={balances}>
            <div className="p-24 app-contain">
                <Row className="g-3">
                <Col md={12}>
                        <div className="box p-3" style={{ width: '100%' ,height:'100%'}}>
                            <Row className="mb-0 g-0">
                                <Col lg={12}>
                                    <Row>
                                        <Col sm={3}>
                                            <h5 className="text-muted mb-1"><center><img src={DaiIcon} height={'30px'}  style={{minWidth: '42px'}} alt="DaiIcon" /> Total Value Locked </center></h5>
                                            <h4 className="mt-2 mb-0" ><center>$<NumberAbbreviation number={DaiBalance ? parseFloat(DaiBalance) : "0"} /></center></h4>
                                        </Col>
                                        <Col sm={3} className="border-start">
                                        <h5 className="text-muted mb-1"><center><img src={dimeicon} height={'32px'}  style={{minWidth: '42px'}} alt="DaiIcon" /> Total Value Locked </center></h5>
                                            <h4 className="mt-2 mb-0" ><center>$<NumberAbbreviation number={dimebalan ? parseFloat(dimebalan) : "0"} /></center></h4>
                                        </Col>
                                        <Col sm={3} className="border-start">
                                        <h5 className="text-muted mb-1"><center><img src={creditsLogo} height={'32px'}  style={{minWidth: '42px'}} alt="DaiIcon" /> Total Minted </center></h5>
                                            <h4 className="mt-2 mb-0" ><center>$<NumberAbbreviation number={creditsValue ? parseFloat(creditsValue) : "0"} /></center></h4>
                                        </Col>
                                        <Col sm={3} className="border-start">
                                        <h5 className="text-muted mb-1"><center> Collateral Ratio </center></h5>
                                            <h4 className="mt-3 mb-0" ><center><NumberAbbreviation number={usdccolratio ? parseFloat(usdccolratio) : "0"} />%</center></h4>
                                        </Col>
                                    </Row>
                                </Col>
                               
                            </Row>

                        </div>
                    </Col>
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="align-items-center mb-1 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                               
                                <img src={creditsLogo} height={'42px'}  style={{minWidth: '42px'}} alt="DaiIcon" />
                                <h6><center>MINT</center></h6>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <div>
                                        <h5>Fees
                                          
                                        </h5>
                                        <h6>
                                        {mintfee ? parseFloat(mintfee*100).toFixed(2) : "0.00"}%
                                        </h6>
                                    </div>
                                    {/* <div className="ms-auto">
                                        <div className="sc-chart">
                                        <LineChart color={joker1Month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name="Joker"/>

                                        </div>
                                    </div> */}
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Threshold  </h5>
                                    <h6>
                                        $<NumberAbbreviation number={formatValues(mintpricethreshold)}/> 
                                        </h6>
                                </div>
                            </Col>
                        </Row>
{/*                      
                        <hr className="my-md-3 my-2" /> */}
                       
                 
                    </div>
                </Col>
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="align-items-center mb-1 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                             
                                <img src={daiwithDime} height={'42px'}  style={{minWidth: '42px'}} alt="DaiIcon" />
                                <h6><center>REDEEM</center></h6>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <div>
                                        <h5>Fees
                                          
                                        </h5>
                                        <h6>
                                        {redeempercent ? parseFloat(redeempercent*100).toFixed(2) : "0.00"}%
                                        </h6>
                                    </div>
                                   
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Threshold  </h5>
                                    <h6>
                                        $<NumberAbbreviation number={formatValues(Redeempricethreshold)}/> 
                                        </h6>
                                </div>
                            </Col>
                        </Row>
{/*                      
                        <hr className="my-md-3 my-2" /> */}
                       
                 
                    </div>
                </Col>
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="align-items-center mb-1 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                              
                                <img src={DaiIcon} height={'42px'}  style={{minWidth: '42px'}} alt="DaiIcon" />
                                <h6><center>BUYBACK</center></h6>
                           
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <div>
                                        <h5>Fees
                                          
                                        </h5>
                                        <h6>
                                        {buybackfee ? parseFloat(buybackfee*100).toFixed(2) : "0.00"}%
                                        </h6>
                                    </div>
                                   
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Available  </h5>
                                    <h6>
                                        <NumberAbbreviation number={formatValues(AvailableBuyback)}/> 
                                        </h6>
                                </div>
                            </Col>
                        </Row>
{/*                      
                        <hr className="my-md-3 my-2" /> */}
                       
                 
                    </div>
                </Col>
                <Col md={6}>
                    <div className="box p-3 p-md-4">
                        <Row className="align-items-center mb-1 g-3">
                            <Col className="my-auto pt-3" xs={'auto'}>
                          
                               <center><img src={dimeicon} height={'42px'}  style={{minWidth: '42px'}} alt="DaiIcon" /></center> 
                                <h6>RECOLLATERALIZE</h6>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <div>
                                        <h5>Fees
                                          
                                        </h5>
                                        <h6>
                                        {recolfee ? parseFloat(recolfee*100).toFixed(2) : "0.00"}%
                                        </h6>
                                    </div>
                                   
                                </div>
                            </Col>
                            <Col>
                                <div className="status-card h-100 text-center">
                                    <h5>Available  </h5>
                                    <h6>
                                        <NumberAbbreviation number={formatValues(Availablerecoll)}/> 
                                        </h6>
                                </div>
                            </Col>
                        </Row>
{/*                      
                        <hr className="my-md-3 my-2" /> */}
                       
                 
                    </div>
                </Col>
                   
                <Col md={6}>   
                    <div style={{height:'90%'}}>           
                    <CreditsBalanceScale />
                    </div>
                </Col>
                    <Col md={6}>
                    {/* <div style={{ width: '80%', maxWidth: '600px', margin: 'auto', padding: '20px' }}>
      <h2>Doughnut Chart Integrated with Pie Chart</h2>
    
    </div> */}
  <div style={{ width: '100%' }}>
        <ChartComponent data={chartData} />
      </div>
                    </Col>
                    
                    {/* <Col md={6}>
                        <TotalTransaction />
                    </Col> */}
                    
                </Row>
            </div>
        </LayoutAfterLogin>
    );
}

export default Carbonix;
//<Tooltip text="Lorem Ipsum" pos="right" />