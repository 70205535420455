import './app.scss';
import LogIn from './pages/Login';
import { Route, Routes, useNavigate  } from 'react-router-dom';
import CreateAccount from './pages/Create-account';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import ADSB from './pages/Dashboard/index_ADSB'
import Wallet from './pages/Wallet';
import TauBond from './pages/TAUBond';
import Bond from './pages/Bond';
import MintCreditDime from './pages/MintCreditDime';
import Staking from './pages/Staking';
import LaunchPad from './pages/LaunchPad';
import Welcome from './pages/Welcome';
import Withdrawlocked from './pages/Welcome/withdrawlocked';
import WithdrawUnlocked from './pages/Welcome/withdrawUnlocked';
import Stake from './pages/Staking/stake';
import StakeBorrow from './pages/Staking/stakeBorrow';
import StakeWithdraw from './pages/Staking/stakeWithdraw';
import StakeWithdrawBorrow from './pages/Staking/stakeWithdrawBorrow';
import StakeReward from './pages/Staking/stakeReward';
import MintCREDITS from './pages/TAU/MintCREDITS';
import Redeem from './pages/TAU/redeem';
import Buyback from './pages/TAU/Buyback';
import Recollateralize from './pages/TAU/Recollateralize';
import Analytics from './pages/Analytics';
import Carbonix from './pages/Carbonix';
import CREDITS from './pages/TAU/index';
import Vault from './pages/Carbonix/Vault';
import Stabilizer from './pages/Carbonix/Stabilizer';
import Faucet from './pages/Faucet/faucet';
import NotFound from './pages/locationRestriction/pagenotfound';
import Redemption from "./pages/TAUBond/Redeemption";
import NotFoundpage from './Notfound'; 
import AddUser from './pages/AddUser';
import Signupcheck from './pages/Signupcheck';


import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';

import { API_KEY, CREDITS_Token_ABI, CREDITS_Token_Address, Communitywallet_Address, DIME_Token_ABI, DIME_Token_Address, JOKER_Token_ABI, JOKER_Token_Address, Network_Name } from './abi/RefinedABI&ContractAddress';
import { ConnectWallet, getTokenBalance } from './abi/CommonSolFunctions';
import BurnVault from './pages/Wallet/BurnVault';
import { checkWalletEligible, recordUserVisits1 } from './abi/firebasecode';

const projectId = 'a0566b417a74c151a64e8e2f9c911652'

const metadata = {
  name: 'Joker Protocol',
  description: 'Introducing the first three-dimensional orthogonal asset class',
  url: 'https://www.jokerprotocol.com/',
  icons: ['https://frontend-ui-psi.vercel.app/static/media/logo-icon.c46ebc27e963e0e2213b1a9a5e6b7d1f.svg']
}
const testnet = {
  chainId: 11155111,
  name: 'Sepolia',
  currency: 'ETH',
  explorerUrl: 'https://sepolia.etherscan.io/',
  rpcUrl: 'https://eth-sepolia-public.unifra.io'
}
createWeb3Modal({
  ethersConfig: defaultConfig({ 
    metadata,
    defaultChainId: 11155111,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: 'https://eth-sepolia-public.unifra.io' // used for the Coinbase SDK
  }),
  chains: [testnet],
  projectId
})

function App() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const[Allowed, setAllowed] = useState(true)
  const navigate = useNavigate();

  const[Eligibile,setEligibile] = useState(false);


    const checkeligibility = async() =>{
      if(isConnected){
        let eligible =  await checkWalletEligible(address);
        console.log("eligible",eligible)
        setEligibile(eligible)
      //   if (eligible) {
      //    // Redirect the user to the homepage '/'
      //    navigate('/');
      //  } else {
      //    // Handle the case where the wallet is not eligible
      //    navigate('/login');
      //    // Optionally, display an error message to the user
      //  }
       return eligible;
      }
      else{
        return false;
      }
     
    }
    useEffect(()=>{checkeligibility()},[isConnected])

    const connectWallet = async () => {
      // e.preventDefault();
      await ConnectWallet();
     let checkvalue =  await checkeligibility();
      return checkvalue;
      // await AddWallet("0xd72558AB56489747360657ab4802176Ce18B49E5",true);
  }
  const [balances, setBalances] = useState({
    joker: 0.00,
    dime: 0.00,
    credits: 0.00,
    eth: 0.00
  });

  const balanceOfTokens = async () => {
    if (isConnected) {
      try {
        const provider = ethers.getDefaultProvider(Network_Name, {
          etherscan: API_KEY});
        const joker = await getTokenBalance(address, JOKER_Token_Address, JOKER_Token_ABI);
        const dime = await getTokenBalance(address, DIME_Token_Address, DIME_Token_ABI);
        const credits = await getTokenBalance(address, CREDITS_Token_Address, CREDITS_Token_ABI);
        const eth = await provider.getBalance(address);
        
        let jokerBalance = ethers.utils.formatUnits(joker, 9);
        let dimeBalance = ethers.utils.formatUnits(dime, 9);
        let creditsBalance = ethers.utils.formatUnits(credits, 18);
        let etherBalance = ethers.utils.formatUnits(eth, 18);

        setBalances({
          joker: jokerBalance,
          dime: dimeBalance,
          credits: creditsBalance,
          eth: etherBalance
        });

        console.log("Balances updated:", balances);
      } catch (error) {
        console.error("Error fetching token balances:", error);
      }
    }
    else{
      setBalances({
        joker: 0.00,
        dime: 0.00,
        credits: 0.00,
        eth: 0.00
      });
    }
    setAllowed(await recordUserVisits1());
  };

  useEffect(() => {
      balanceOfTokens();
  }, [isConnected, address]);
if(Eligibile){
  return (
    <div className="App-main">
      <Routes>
        {/* {Allowed ? (<> */}
       
       
         {/* <Route path="/login" element={ <LogIn /> } /> */}
         <Route path="/welcome" element={ <CreateAccount /> } />
        <Route path="/login" element={ <Signup  connectWallet={connectWallet} setEligibile={setEligibile} /> } />
        <Route path="/" element={ <Dashboard balances={balances} setEligibile={setEligibile} Eligibile={Eligibile} checkeligibility={checkeligibility}/> } />
        <Route path="/swap" element={ <Wallet balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path='/burnVault' element={ <BurnVault balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/tau-bond" element={ <TauBond balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path='/redemption' element={ <Redemption balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/bond" element={ <Bond balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/mint-credit-dime" element={ <MintCreditDime balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/staking" element={ <Staking balances={balances}/> } />
        <Route path="/staking/stake" element={ <Stake balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/staking/welcome" element={ <Welcome balances={balances}/> } />
        <Route path="/staking/stake-borrow" element={ <StakeBorrow balances={balances}/> } />
        <Route path="/staking/stake-withdraw" element={ <StakeWithdraw balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/staking/stake-withdraw-borrow" element={ <StakeWithdrawBorrow balances={balances}/> } />
        <Route path="/staking/stake-reward" element={ <StakeReward balances={balances}/> } />
        <Route path="/launch-pad" element={ <LaunchPad balances={balances}/> } />
        
        <Route path="/withdrawlocked" element={ <Withdrawlocked balances={balances}/> } />
        <Route path="/withdrawUnlocked" element={ <WithdrawUnlocked balances={balances}/> } />
        <Route path="/mint-credits" element={ <MintCREDITS balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/redeem" element={ <Redeem balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/buyback" element={ <Buyback balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/recollateralize" element={ <Recollateralize balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/analytics" element={ <Analytics balances={balances}/> } />
        <Route path="/carbonix" element={ <Carbonix balances={balances}/> } />
        <Route path="/credits" element={ <CREDITS balances={balances}/> } />
        <Route path="/vault" element={ <Vault balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/stabilizer" element={ <Stabilizer balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        <Route path="/faucet" element={ <Faucet balances={balances} balanceOfTokens={balanceOfTokens}/> } />
        
        <Route path="/ADSB1.0" element={ <ADSB balances={balances} setEligibile={setEligibile} Eligibile={Eligibile} checkeligibility={checkeligibility}/> } />

        {address === Communitywallet_Address && 
          <Route path="/adduser" element={ <AddUser  connectWallet={connectWallet}  /> } />
        }
      <Route path="/logins" element={ <Signupcheck  connectWallet={connectWallet} setEligibile={setEligibile} /> } />

      <Route path="*" element={<NotFoundpage />} />
        
      
        {/* </>):(<>
          <Route path="/" element={ <NotFound balances={balances} balanceOfTokens={balanceOfTokens}/> } />

        </>)} */}

      </Routes>
    </div>
  );
}
  else{
    
    return(
      <div className="App-main">
            <Routes>
            <Route path="*" element={<NotFoundpage />} />
            <Route path="/" element={ <Signup  connectWallet={connectWallet} setEligibile={setEligibile} /> } />

          <Route path="/login" element={ <Signup  connectWallet={connectWallet} setEligibile={setEligibile} /> } />
          <Route path="/welcome" element={ <CreateAccount /> } />
          </Routes>
      </div>
    )
  }
 
}

export default App;
