import { useEffect, useState } from "react";
import BarLinedata from "./BarLineChart";
import { getADSBOneDay, getADSBOneMonth, getADSBOneWeek, getADSBOneYear, getTreasuryOneMonth, getTreasuryOneWeek, getTreasuryOneYear } from "../../../abi/firebasecode";
import { getJokerPrice, getDimePrice, getCreditsPrice, getJokerSupply, getDimeSupply, getCreditsSupply, NumberAbbreviation } from "../../../abi/CommonSolFunctions";
import jokerLogo from '../../../asserts/IMAGES/jokerRedLogo.svg';
import dimeLogo from '../../../asserts/IMAGES/dimeCyanLogo.svg';
import creditsLogo from '../../../asserts/IMAGES/creditsBlackLogo.svg';

function DimeCreditJokerCard() {


    const [Oneyearvalue, setOneyearvalue] = useState(0);
    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);
    const [Oneyeardates1, setOneyeardates1] = useState(0);
    const [Oneyearprices1, setOneyearprices1] = useState(0);
    const [Oneyearvalue1, setOneyearvalue1] = useState(0);

    
    const [activeButton, setActiveButton] = useState('1Y');
    const [jokerPrice, setjokerPrice] = useState("");
    const[dimePrice,setdimePrice] = useState("");
    const[creditsPrice,setcreditsPrice] = useState("");
    const[jokerSupply,setjokerSupply] = useState("");
    const[dimeSupply,setdimeSupply] = useState("");
    const[creditsSupply,setcreditsSupply] = useState("");
    const [title, setTitle] = useState("price");

    // console.log("getMonthValues", Oneyearvalue);

    const getallvalues = async() =>{
        let oneyearvalue = await getADSBOneYear("dime")
        // setOneyearvalue(oneyearvalue)
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);        
        setOneyearprices(prices)

        let oneyearvalue1 = await getADSBOneYear("jokerValue")
        // setOneyearvalue1(oneyearvalue1)
        // const dates1 = Object.keys(oneyearvalue1);
        // setOneyeardates1(dates1)
        const prices1 = Object.values(oneyearvalue1);        
        setOneyearprices1(prices1)

        // setjokerPrice(await getJokerPrice())
        // setTotalSupply(await JOKERContract.totalSupply())


    }
    useEffect(() =>{getallvalues()},[])

    const getAssetValues = async() => {
      try{
      setjokerPrice(await getJokerPrice())
      setdimePrice(await getDimePrice());
      setcreditsPrice(await getCreditsPrice());
      setjokerSupply(await getJokerSupply());
      setdimeSupply(await getDimeSupply());
      setcreditsSupply(await getCreditsSupply());
      }catch(e){
        console.log(e);
    }
    }
    useEffect(() =>{getAssetValues();},[])

     // Function to handle button click
     const handleButtonClick = async(value) => {
        setActiveButton(value);
        if(value == '1W'){
          let oneyearvalue = await getADSBOneWeek("dime")
          setfunction(oneyearvalue)
        }else if(value == '1M'){
          let oneyearvalue = await getADSBOneMonth("dime")
          setfunction(oneyearvalue)
  
        }else if(value == '1Y'){
          let oneyearvalue = await getADSBOneYear("dime")
          setfunction(oneyearvalue)
        }else{
  
        }
  
      };
      const handleButtonClicksupply = async(value) => {
          setActiveButton(value);
          if(value == '1W'){
            let oneyearvalue = await getADSBOneWeek("jokerValue")
            setfunction1(oneyearvalue)
          }else if(value == '1M'){
            let oneyearvalue = await getADSBOneMonth("jokerValue")
            setfunction1(oneyearvalue)
    
          }else if(value == '1Y'){
            let oneyearvalue = await getADSBOneYear("jokerValue")
            setfunction1(oneyearvalue)
          }else{
    
          }
    
        };
  
       
  
      const callselectedFunction = async(value) =>{
          console.log("selected function",title)
            await handleButtonClick(value)
            await handleButtonClicksupply(value)
          
  
      }
  
      const setfunction = async(oneyearvalue) => {
          const dates = Object.keys(oneyearvalue);
          setOneyeardates(dates)
          const prices = Object.values(oneyearvalue);
          setOneyearprices(prices)
      }
      const setfunction1 = async(oneyearvalue) => {
        const dates = Object.keys(oneyearvalue);
        setOneyeardates1(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices1(prices)
    }
  
      const OptionCall = async(titlevalue) =>{
          console.log("titlevalue", titlevalue)
          setTitle(titlevalue)
          setActiveButton('1Y')
        let oneyearvalue = await getADSBOneDay(titlevalue)
        setOneyearvalue(oneyearvalue)
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue); 
        setOneyearprices(prices)
  
      }
  

    return ( 
        <div className="box">
            <div className="p-3 d-flex align-items-center flex-warp">
               <center><h4 className="mb-0">
                <img src={jokerLogo} height={'30px'}  style={{minWidth: '42px'}} alt="jokerLogo"  />
                &nbsp; Vs &nbsp;
                <img src={dimeLogo} height={'30px'}  style={{minWidth: '42px'}} alt="dimeLogo"  />
                +
                <img src={creditsLogo} height={'30px'}  style={{minWidth: '42px'}} alt="creditLogo"  />
                </h4></center> 
                
                <div className="chart-filter d-flex align-items-center ms-auto">
                {/* <button
                    className={activeButton === '1D' ? 'active' : ''}
                    onClick={() => callselectedFunction('1D')} >
                    1D
                    </button> */}
                    <button
                    className={activeButton === '1W' ? 'active' : ''}
                    onClick={() => callselectedFunction('1W')} >
                    1W
                    </button>
                    <button
                    className={activeButton === '1M' ? 'active' : ''}
                    onClick={() => callselectedFunction('1M')} >
                    1M
                    </button>
                    <button
                    className={activeButton === '1Y' ? 'active' : ''}
                    onClick={() => callselectedFunction('1Y')} >
                    1Y
                    </button>
                </div>
            </div>

            <div className="chart-header d-flex align-items-center">
                <div>
                    <h6>JOKER Market Cap</h6>
                    <h5>$<NumberAbbreviation number={(jokerPrice && jokerSupply) ? ((jokerPrice/1e8) *(jokerSupply / 1e9)).toFixed(2) : "0.00"}/></h5>
                </div>
                <div>
                    <h6>DIME Market Cap</h6>
                    <h5>$<NumberAbbreviation number={(dimePrice && dimeSupply) ? ((dimePrice/1e8) *(dimeSupply / 1e9)).toFixed(2) : "0.00"}/></h5>
                </div>
                <div>
                    <h6>CREDITS Market Cap</h6>
                    <h5>$<NumberAbbreviation number={(creditsPrice && creditsSupply) ? ((creditsPrice/1e8) *(creditsSupply / 1e18)).toFixed(2) : "0.00"}/></h5>
                </div>
            </div>

            <div className="p-3">
                <BarLinedata labels={Oneyeardates?Oneyeardates:'0'} data1={Oneyearprices?Oneyearprices:'0'} data2={Oneyearprices1?Oneyearprices1:'0'} />
            </div>
        </div>
     );
}

export default DimeCreditJokerCard;