import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
      legend: {
          display: false,
          position: 'top',
      },
      title: {
          display: false,
          text: 'Chart.js Line Chart',
      },
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                      label += ': ';
                  }
                  if (context.parsed.y !== null) {
                      label += '$' + context.parsed.y;
                  }
                  return label;
              }
          }
      }
  },
  radius: window.innerWidth > 767 ? 8 : 3,
  interaction: {
    intersect: false,
  },
  aspectRatio: 4,
  scales: {
      x: {
          ticks: {
              color: "#979797",
              stepSize: 1,
              beginAtZero: true
          },
          display: true,
          grid: {
              color: '#1C1D1F'
          }
      },
      y: {
          ticks: {
              color: "#979797",
              stepSize: 1,
              beginAtZero: true
          },
          display: false,
          grid: {
              color: '#1C1D1F'
          }
      }
  },
};


// const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// const canvas = document.getElementsByTagName('canvas')[0];
// console.log(canvas);

// const ctx = canvas?.getContext('2d');
// var gradient = ctx?.createLinearGradient(0, 0, 0, 400);
//     gradient?.addColorStop(0, 'rgba(250,174,50,1)');   
//     gradient?.addColorStop(1, 'rgba(250,174,50,0)');

// export const data = {
//   labels,
//   datasets: [
//     {
//       fill: true,
//       label: 'Joker',
//       data: [60,30,40,35,65,43,70,35,45,65,58,45],
//       borderColor: 'rgba(0, 194, 255, 0.5)',
//       backgroundColor: 'rgba(0, 194, 255, 0.5)',
//     },
//   ],
// };

export function AreaChartPlan({labels,data1}) {

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'DAI',
        data: data1,
        borderColor: 'rgba(0, 194, 255, 0.5)',
        backgroundColor: 'rgba(0, 194, 255, 0.5)',
      },
    ],
  };
  return <Line options={options} data={data} />;
}
