import Poll from '../../../asserts/IMAGES/pole.svg'
import Angle from '../../../asserts/IMAGES/pole-angle.svg'
import BalanceWeight from '../../../asserts/IMAGES/balanceWeight.svg'
import JokerIcon from '../../../asserts/IMAGES/jokerRedLogo.svg'
import CreditsIcon from '../../../asserts/IMAGES/creditsBlackLogo.svg'
import DimeIcon from '../../../asserts/IMAGES/dimeCyanLogo.svg'
import DaiIcon from '../../../asserts/IMAGES/dai.svg';
import { useEffect, useState } from 'react'
import {NumericFormat}  from 'react-number-format';
import { NumberAbbreviation, getCreditsPrice, getCreditsSupply, getDAIPrice, getDimePrice, getDimeSupply, getJokerPrice, getJokerSupply, getTokenBalance } from '../../../abi/CommonSolFunctions'
import { API_KEY, CREDITS_Token_ABI, CREDITS_Token_Address, Carbonix_Finance_ABI, Carbonix_Finance_Address, Communitywallet_Address, Credits_Pool_Address, DAI_TOKEN_ABI, DAI_TOKEN_Address, DIME_LP_Token_Address, DIME_TREASURY_Address, DIME_Token_ABI, DIME_Token_Address, ECO_Reserve_Treasury_Address, Faucet_Address, JOKER_Token_ABI, JOKER_Token_Address, LP_Token_ABI, Network_Name, credits_pool_ABI } from '../../../abi/RefinedABI&ContractAddress'
import { ethers } from 'ethers'

function DimeBalanceScale() {
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    
   const[creditsValue,setCReditsValue] = useState("");
   const[DaiValue,setDaiValue] = useState("");
   const[dimebalan,setDimeBalance] = useState("");

    const fetchinitailValues = async () => {

        const CreditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
        let borrowedCredits = ethers.utils.formatUnits(await CreditsContract.hasMinted(Carbonix_Finance_Address),0);
        let creditMintValue  = ethers.utils.formatUnits(await CreditsContract.hasMinted(Credits_Pool_Address),0);
        setCReditsValue((parseFloat(borrowedCredits/1e18)+parseFloat(creditMintValue/1e18))*parseFloat(await getCreditsPrice())/1e8);
        
        const CarbonixContract = new ethers.Contract(Carbonix_Finance_Address, Carbonix_Finance_ABI, provider);
        let depositedDai = ethers.utils.formatUnits(await CarbonixContract.totalDeposited(),0);
        
        const creditPoolContractinstance = new ethers.Contract(Credits_Pool_Address, credits_pool_ABI, provider);
        let creditsDaiBalance = ethers.utils.formatUnits(await creditPoolContractinstance.collatDollarBalance(),0);
        setDaiValue((parseFloat(depositedDai/1e18) + parseFloat(creditsDaiBalance/1e18))*parseFloat(await getDAIPrice())/1e8);
        let dimebalance = await getTokenBalance(Credits_Pool_Address,DIME_Token_Address,DIME_Token_ABI);
        setDimeBalance(parseFloat(dimebalance/1e9)*parseFloat(await getDimePrice()/1e8));

    }
    useEffect(() =>{fetchinitailValues()},[])

    // console.log("dimebalancemarketcap",dimeMarketcap,lpTokenBal);
    const lpcoin = parseFloat(creditsValue).toFixed(4);
    const daibalance = parseFloat(DaiValue).toFixed(0);
    const Dime = parseFloat(dimebalan).toFixed(0);
    const creditsDim = parseFloat(daibalance) + parseFloat(Dime);
    // const rotate = (lpcoin - Dime)/20; // divided by 50
    const rotate = lpcoin > creditsDim ? 10 : creditsDim > lpcoin ? -10 : 0
    // const rotate = 0

    console.log("rotatdimee",lpcoin,rotate);
    const getSuffix = (num) => {
        if (num >= 1000000000) {
          return 'B';
        } else if (num >= 1000000) {
          return 'M';
        } else if (num >= 1000) {
          return 'K';
        } else {
          return '';
        }
      };
    return ( 
        
        <div className="box d-flex flex-column balance-diagram-outer align-items-center justify-content-sm-center  p-1" >
           
                <center><h2>CREDITS </h2></center>
            <div className="balance-diagram">
                <img src={Poll} alt="Poll" className='balance-diagram-poll' />
                <img src={Angle} alt="Angle" className='balance-diagram-angle' style={{transform : `rotate(${rotate}deg)`}} />
                <div className='balance-weight balance-weight-left' style={{transform : `translateY(${-rotate*3}px)`}}>
                    <div className='balance-weight-group'>
                        <img src={BalanceWeight} alt='BalanceWeight' className='balance-weight-image' />

                        <div className='balance-weight-icons'>
                            
                        <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                            <img src={DaiIcon} alt='CreditsIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                            <img src={DimeIcon} alt='DimeIcon' />
                        </div>
                    </div>
                    <div className='balance-weight-info'>
                        DAI + DIME  <span className='text-muted'> 
                        {/* <NumberAbbreviation number={creditsDim}/> */}
    <NumericFormat value={creditsDim} displayType={'text'} thousandSeparator={true} suffix={getSuffix((creditsDim))}/>
    </span>
                    </div>
                </div>
                <div className='balance-weight balance-weight-right' style={{transform : `translateY(${rotate*3}px)`}}>
                    <div className='balance-weight-group'>
                        <img src={BalanceWeight} alt='BalanceWeight' className='balance-weight-image' />
                        <div className='balance-weight-icons'>
                     
                        <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                            <img src={CreditsIcon} alt='JokerIcon' />
                        </div>
                    </div>
                    <div className='balance-weight-info'>
                        CREDITS<span className='text-muted'> 
                        <NumericFormat value={parseFloat(lpcoin).toFixed(2)} displayType={'text'} thousandSeparator={true} suffix={getSuffix((lpcoin))}/>
                        </span>
                    </div>
                </div>
            </div>
            {/* <div className='p-2 w-100 text-end'>
                <button class="btn btn-grad outline btn-sm"><span>Claimable</span></button>
            </div> */}
            {creditsDim && (<>
                <div className='p-2 w-100 text-end'>
                <button class="btn pb-1  outline btn-sm"  style={{backgroundColor: creditsDim > lpcoin ?'green' :'red'}}>
                    <h5 style={{color:'white'}}>Equilibrium</h5>
                    </button>
            </div>
            </>)}
            
        </div>
     );
}

export default DimeBalanceScale;