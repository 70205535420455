import { Col, Modal, Row , Spinner, Table} from "react-bootstrap";
import Icon1 from '../../asserts/IMAGES/jokerRedLogo.svg';
import SwapBtn from '../../asserts/IMAGES/swap-btn.svg';
import EthereumIcon from '../../asserts/IMAGES/Ethereum-icon.svg';
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import ArrowLink from '../../asserts/IMAGES/arrowLink.svg';
import ProgressBar from "../Dashboard/Snippets/CircleTimer";
import Timer from "../Dashboard/Snippets/Timer";
import { useEffect,useState } from "react";
import daiLogo from '../../asserts/IMAGES/dai.svg';

import { CheckAllowance, PendingModal,   convertEpochToDateTime, convertFullEpochToDateTime, getGasBalance, getTokenBalance } from "../../abi/CommonSolFunctions";
import { API_KEY, CHAIN_URL, DAI_TOKEN_ABI, DAI_TOKEN_Address, JOKER_Token_ABI, JOKER_Token_Address, Network_Name, TXN_ID, WETH_TOKEN_Address } from "../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
import { NumericFormat } from "react-number-format";
/* global BigInt */

function Swap({selectedTab, balanceOfTokens}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    //usestate values
    const [jokerBalance, setJokerBalance] = useState("");
    const [etherBalance, setEtherBalance] = useState("");
    const [jokerAmount,setJokeramount] = useState("");
    const [OutputValue,setOutputValue] = useState("");
    const [jokerAllowance,setJokerAllowance] = useState("");
    const [secondsLeft,setSecondsLeft] = useState("");
    const [lockedTrue,setLockedTrue] = useState("");
    const [swapLockedTime,setswapLockedTime] = useState("");
    const [timePercentage, setTimePercentage] = useState(0);
    const [exchangeRate,setExchangeRate] = useState("");

    const [daiBalance, setDaiBalance] = useState("");

    const [swapLimit, setSwaplimit] = useState("")
    const [isNew, setisNew] = useState(null)
    const [mebalance, setmebalance] = useState("")
    const[swapLocked,setswapLocked] = useState(false);
    const[timePercent2,settimepercent2] = useState("");


    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);
   
    
    const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
    const getAllBalanceFunction = async() =>{
        if(selectedTab === 'Swap')
            await recordUserVisits(address, "Controlled Swap - Swap");
        
            if(isConnected){
                try{
                console.log("useeffect swap");
                
                let jokerBalance = await getTokenBalance(address,JOKER_Token_Address,JOKER_Token_ABI);
                console.log("jokerBalance",jokerBalance)
                let etherBalance = await getGasBalance(address);
                setJokerBalance(jokerBalance);
                setEtherBalance(etherBalance);

                
                setmebalance((jokerBalance * 20)/100);

                let daibalance = await getTokenBalance(address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
                setDaiBalance(daibalance)
    
                let jokerallowance = await CheckAllowance(JOKER_Token_Address,JOKER_Token_ABI,address,address);
                setJokerAllowance(jokerallowance);
    
                let secondsleft = ethers.utils.formatUnits(await JOKERContract._secondsLeft(address),0);
                setSecondsLeft(await convertEpochToDateTime(secondsleft))
                setLockedTrue(secondsleft)
                setTimePercentage(parseInt((secondsleft / 86400) * 100));

                let twelvedays = 1036800;
                let swaplockedtime = ethers.utils.formatUnits(await JOKERContract.LastSwapTime(address),0);
                let crttimeformat = await convertFullEpochToDateTime(parseFloat(swaplockedtime)+parseFloat(twelvedays));
                setswapLockedTime(crttimeformat);
                console.log("crttimeformat",crttimeformat,swaplockedtime)
    
                let value = await JOKERContract.checkSwapValue(1*1e9,[JOKER_Token_Address,WETH_TOKEN_Address]);
                let ethAmount = ethers.utils.formatUnits(value,0);
                setExchangeRate(ethAmount);

                let SwapLimit = ethers.utils.formatUnits(await JOKERContract.SwapLimit(address),0);
                let SwapTrack = ethers.utils.formatUnits(await JOKERContract.SwapTrack(address),0);
                let isNew1 = await JOKERContract.isnew(address);
                setisNew(isNew1);
                console.log("swapLimit:", SwapLimit, SwapTrack, SwapLimit - SwapTrack,);
                setSwaplimit(SwapLimit - SwapTrack);


                const now = new Date(); // Current date and time
                const targetEpoch = now.getTime() 
                console.log("targetEpoch",targetEpoch)
              
                if(isNew1){
                    if(((targetEpoch/1000) - swaplockedtime) < twelvedays){
                        setswapLocked(true);
                        console.log("targetEpoch",true)
                    }else{
                        
                        setswapLocked(false);
                        console.log("targetEpoch",false)
                    }
                }else{
                    setswapLocked(false);
                    console.log("targetEpoch",false)
                }
                

                settimepercent2((((targetEpoch/1000) - swaplockedtime)/twelvedays)*100)
            }catch(err){
                    console.log(err);
                }
               
           
        }
        
    }

    useEffect(() =>{
        getAllBalanceFunction();
    },[address, isConnected, timePercentage, selectedTab]);

    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const changeinput = async(e) => {
        setJokeramount(e);
        
        try{
            let value = await JOKERContract.checkSwapValue(e*1e9,[JOKER_Token_Address,DAI_TOKEN_Address]);
            let ethAmountvalue = ethers.utils.formatUnits(value,0);
            // console.log("ethAmount",ethAmountvalue)
            // let ethAmount = exchangeRate * e ;
            let feesdeductedvalue = (parseFloat(ethAmountvalue)*50/100)/1e18;
            setOutputValue(feesdeductedvalue)
        }
        catch(err){
            console.log(err)
            setOutputValue(0);
        }
         
    }

    // const maxCall = async() =>{
    //     if(jokerBalance > 0){
    //         changeinput(jokerBalance/1e9)
    //     }
    // }

    const maxCall = async() =>{
        const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
        let bala;
        if(isNew ==false){
            bala = ethers.utils.formatUnits(await JOKERContract.balanceOf(address),0);
            setJokeramount(((bala * 20)/100)/1e9);
            let avaxSpent = ethers.utils.formatUnits(await JOKERContract.checkSwapValue(BigInt(parseFloat((bala * 20)/100).toFixed(0)),[JOKER_Token_Address,DAI_TOKEN_Address]),0);
            console.log("avaxSpent",parseFloat(avaxSpent/1e18))
            let feesdeductedvalue = (parseFloat(avaxSpent)*50/100)/1e18;
            setOutputValue(feesdeductedvalue)
        }else if(isNew == true){
            bala = swapLimit;
            setJokeramount((bala)/1e9);
            let avaxSpent = ethers.utils.formatUnits(await JOKERContract.checkSwapValue(BigInt(bala),[JOKER_Token_Address,DAI_TOKEN_Address]),0);
            console.log("avaxSpent",parseFloat(avaxSpent/1e6))
            let feesdeductedvalue = (parseFloat(avaxSpent)*50/100)/1e18;
            setOutputValue(feesdeductedvalue)
        }
        
      }

    const approve = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const approveTxn = await JOKERContract.approve(address,BigInt(1000000000 * 1e9));
                
                await approveTxn.wait();
                await TxnId_PopUp(approveTxn.hash);
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const swap = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const swaptxn = await JOKERContract.swapTokensForBNBUSer(BigInt(parseFloat(jokerAmount * 1e9).toFixed(0)));
                
                await swaptxn.wait();
                await TxnId_PopUp(swaptxn.hash);
                await balanceOfTokens();
                await createTxn("Joker",swaptxn.hash,"Controlled swap",address,JOKER_Token_Address);
                await resetState();
            }catch(err){
                let errorData ;
                if(err.reason){
                    errorData = err.reason;
                }else 
                {
                    errorData = err;
                }
                showPopUp("not",TransactionPending,"",(errorData).toString());
                console.log("error",err);
                
            }
        }
    }
    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAllBalanceFunction();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const resetState = async() =>{
        setJokeramount("");
        setOutputValue("");
        await balanceOfTokens();
    }

    const allowanceTrue = parseInt(jokerAllowance) >= parseInt(jokerAmount*1e9);

    return ( 
        <div className="p-24 table-box app-contain">
            <Row className="justify-content-center">
            
          
                <Col md={12} style={{maxWidth: '800px'}}>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>Disclaimer: Get exclusive access to unlock swap by contributing 50% to the BurnVault.</th>
                            {/* <th>Allocated to Mint</th> */}
                            {/* <th> <img src={Icon3} width={35} alt="Transfer" /></th>
                            <th><img src={Icon4} width={35} alt="Transfer" /></th> */}
                            {/* <th>Treasury Revenue </th> */}
                            {/* <th>End Date </th> */}
                        </tr>
                    </thead>
                    </Table>
                    <div className="box p-3 p-md-4">
                        <div className="swap-card d-flex align-items-center">
                            <div>
                                <img src={Icon1} alt="Icon1" height={'42px'}/>
                            </div>
                            <div>
                                <h5>JOKER</h5>
                                <h6>Bal: 
                                {parseFloat(jokerBalance) === 'NaN' ? '0.00' :  formatValues((jokerBalance/1e9),2)}
                                    </h6>
                            </div>
                            <div className="flex-grow-1 py-2">
                                <NumericFormat value={jokerAmount} placeholder="0.00" className="input-reset" allowNegative={false} onChange={(e) => changeinput(e.target.value)}   required />
                            </div>

                            <button className="btn btn-grad py-2" onClick={()=>maxCall()}>Max</button>
                        </div>
                        <div className="py-2 text-center">
                            <button type="button" className="btn-reset"><img src={SwapBtn} alt="SwapBtn" /></button>
                        </div>
                        <div className="swap-card mb-4 d-flex align-items-center">
                            <div>
                                <img src={daiLogo} height={'42px'} alt="Icon1" />
                            </div>
                            <div>
                                <h5>DAI</h5>
                                <h6>Bal:
                                {parseFloat(daiBalance) === 'NaN' ? '0.00' :  formatValues((daiBalance/1e18),2)}
                                     </h6>
                            </div>
                            <div className="flex-grow-1 py-2">
                              {OutputValue > 0 ? (<><input readonly disabled  placeholder="0.00" className="input-reset" value={parseFloat(OutputValue) ? formatValues(OutputValue,9) : '0.00'}/></>):
                              (<><input readonly disabled  placeholder="0.00" className="input-reset" value={parseFloat(OutputValue) ? formatValues(OutputValue,9) : '0.00'}/></>)}  
                            </div>
                        </div>
                       
                      
                       
                        
                      
                       

                        {/* <div className="mt-4 text-center">
                            <p className="text-gray mb-0">Disclaimer: Get exclusive access to unlock Swap by Contributing 50% to the BurnVault.
                            <Tooltip text="Lorem Ipsum Content." />
                            </p>
                        </div> */}
                         <div className="box swap-value border-top">
                        <div className="p-4 border-bottom">
                            <h6>Exchange Rate</h6>
                            <h4>1 &nbsp; JOKER   &nbsp;= &nbsp; {exchangeRate?parseFloat(exchangeRate/1e18).toFixed(8):'0'} &nbsp; DAI </h4>
                        </div>
                        <div className="p-4 border-bottom">
                            <h6>Exchange Fees</h6>
                            <h4> 50% &nbsp; DAI </h4>
                        </div>
                        <div className="p-4 border-bottom">
                            <h6>You Will Receive</h6>
                            <h4>{OutputValue?parseFloat(OutputValue).toFixed(9):'0.00'}</h4>
                        </div>

                        <div className="p-4 border-bottom">
                        {isNew == true && <> <h6> Your SwapLimit</h6> <h4> {swapLimit ? parseFloat(swapLimit/1e9).toFixed(9):"0.00"} JOKER</h4></> }
                        {isNew == false && <> <h6> Your SwapLimit</h6> <h4>{mebalance ? parseFloat(mebalance/1e9).toFixed(9):"0.00"} JOKER</h4></>}
                        </div>

                        
                        <div className="px-4 pb-4 pt-4">
                        {/* {allowanceTrue?(<>
                            <button type="submit" className="btn btn-grad w-100 outline py-2 "><span>Approve JOKER</span></button>
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approve()}>Approve JOKER</button>
                        </>)} */}

                        {lockedTrue <= 0 ? (<>
                            {swapLocked ? (<>
                                <div className="box swap-card mb-20 d-flex align-items-center p-3 p-md-4">
                            <div>
                                <h5 className="text-gray">swap to be unlocked in</h5>
                                <Timer count={swapLockedTime ? swapLockedTime : '0'} />
                            </div>
                            <div className="ms-auto">
                                <ProgressBar
                                    percentage={100 - timePercent2}
                                    startColor="#20E2D0"
                                    endColor="#00C2FF"
                                    gradientId="progress9"
                                />
                            </div>
                        </div>
                        </>):(<>
                            {allowanceTrue?(<>
                        <button  type="submit"  className=" btn mb-20 btn-grad  w-100 " onClick={()=>swap()} >
                        <span>Swap</span></button>
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approve()}>Approve JOKER</button>
                        </>)}
                        </>)}
                        
                        </>):(<>
                            <div className="box swap-card mb-20 d-flex align-items-center p-3 p-md-4">
                            <div>
                                <h5 className="text-gray">Wallet to be unlocked in</h5>
                                <Timer count={secondsLeft ? secondsLeft : '0'} />
                            </div>
                            <div className="ms-auto">
                                <ProgressBar
                                    percentage={timePercentage}
                                    startColor="#20E2D0"
                                    endColor="#00C2FF"
                                    gradientId="progress1"
                                />
                            </div>
                        </div>
                        </>)}
                           
                        </div>
                    </div>
                    </div>
                
                {/* <Col md={6}> */}
                {/* <div className="burn-card p-3 px-md-4"> */}
                   
                    {/* </div> */}
                {/* </Col> */}
                </Col>
           
            </Row>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </div>
     );
}

export default Swap;