import { Badge, Col, ProgressBar, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import BackIcon from '../../asserts/IMAGES/back-white-icon.svg'
import ProgressBarCircle from '../Dashboard/Snippets/CircleTimer'
import HedgeDebtI from '../../asserts/IMAGES/hedgeDebt-1.svg'
import HedgeDebt2 from '../../asserts/IMAGES/hedgeDebt-2.svg'
import HedgeDebt3 from '../../asserts/IMAGES/snx-icon.svg'
import HedgeDebt4 from '../../asserts/IMAGES/skelton-icon.svg'
import JokerLogo from '../../asserts/IMAGES/joker-icon.svg'
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';

import { Link, useLocation } from "react-router-dom";
import Timer from "../Dashboard/Snippets/Timer";
import { useEffect, useState } from "react";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { API_KEY, CHAIN_URL, DAI_TOKEN_ABI, Network_Name, STAKING_ABI, TXN_ID } from "../../abi/RefinedABI&ContractAddress";
import { ethers } from "ethers";
import { PendingModal, convertFullEpochToDateTime, getTokenBalance } from "../../abi/CommonSolFunctions";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
import { NumericFormat } from "react-number-format";

/* global BigInt */
function StakeWithdraw({balances,balanceOfTokens}) {
    const [withdrawnotlock,setwithdrawnotlock ] = useState(false)

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const location =  useLocation();

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);


    const[stakedAmount,setStakedAmount] = useState("");
    const[unstakeLock,setunstakeLock] = useState(false);
    const[UnstakeTime,setUnstakeTime ] = useState("");
    const[unloacktimeinDateformat,setunloacktimeinDateformat] = useState("");
    const [timePercentage, setTimePercentage] = useState(0);
    const[enteredValue,setenteredValue] = useState("");
    const [activePercentage, setActivePercentage] = useState("");
    const[TotalStakedValue,setTotalStakedValue] = useState("");


    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    const getAllBalanceFunction = async() =>{
        try{
            if (location.state)
                await recordUserVisits(address, `${location.state.TokenName} Withdraw`);
        if(isConnected){
            
            if (location.state){
                // setstatevalues(location.state);
             
                const StakingContract = new ethers.Contract(location.state.StakingContract, STAKING_ABI, provider);

                let userDeposited = await StakingContract.userInfo(address);
                setStakedAmount(ethers.utils.formatUnits((userDeposited.amount),0));

                const now = new Date(); // Current date and time
                const targetEpoch = now.getTime() /1000;
                let holderunstaketime= await StakingContract.holderUnstakeRemainingTime(address);
                console.log("maxstakeamount",targetEpoch, ethers.utils.formatUnits((holderunstaketime),0));
                setUnstakeTime(ethers.utils.formatUnits((holderunstaketime),0));
                setunloacktimeinDateformat(await convertFullEpochToDateTime(ethers.utils.formatUnits((holderunstaketime),0)))
                console.log("maxstakeamount",await convertFullEpochToDateTime(ethers.utils.formatUnits((holderunstaketime),0)));
                if( parseFloat(ethers.utils.formatUnits((holderunstaketime),0)) - parseFloat(targetEpoch) <= 0){
                    setunstakeLock(false);
                    console.log("maxstakeamount","false");
                }else{
                    setunstakeLock(true);
                    console.log("maxstakeamount","true");
                }
                let secondsleft = ethers.utils.formatUnits((holderunstaketime),0) - parseFloat(targetEpoch)
                setTimePercentage(parseInt((secondsleft / 86400) * 100));

                // const tokenContract = new ethers.Contract(location.state.depositTokenAddress, DAI_TOKEN_ABI, provider);

                // let userbalance = await tokenContract.balanceOf(address);
                // setwalletBalance(ethers.utils.formatUnits((userbalance),0));

                // let allowance = await CheckAllowance(location.state.depositTokenAddress,DAI_TOKEN_ABI,address,location.state.StakingContract)
                // setAllowance(allowance)

                let totalStakedvalue = await getTokenBalance(location.state.StakingContract,location.state.depositTokenAddress,DAI_TOKEN_ABI)
                setTotalStakedValue(totalStakedvalue)
            }
        }
        }catch(error){
            console.error(error);
    }
    }
    useEffect(() =>{getAllBalanceFunction()},[isConnected,address]);
    const maxCall = async(percent) =>{
        setActivePercentage(percent);
        setenteredValue((stakedAmount*percent)/100/10**location.state.decimalAmount)
    }

    const withdraw = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const stakingContract = new ethers.Contract(location.state.StakingContract, STAKING_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                let inputvalue = (enteredValue * (10**(location.state.decimalAmount))).toFixed(0);
                const swaptxn = await stakingContract.withdraw(BigInt(inputvalue));
                
                await swaptxn.wait();
                await TxnId_PopUp(swaptxn.hash);
                await createTxn(location.state.TokenName,swaptxn.hash,"Withdraw",address,location.state.StakingContract);
                await resetState();
            }catch(err){
                console.log("error",err);
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAllBalanceFunction();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const resetState = async() =>{
        setenteredValue("");
        await balanceOfTokens();
    }


    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <div className="p-24 px-xl-5 app-contain">
                <div className="mb-4 mb-lg-5">
                    <Link className="flex g-2 mb-3 text-white" to='/staking/welcome' state={location.state}><img src={BackIcon} alt="icon" /> Back</Link>
                    <Row>
                        <Col md={6} xl={8}>
                            <h2 className="mb-2">Unstake by withdrawing the {location.state? location.state.TokenName :"Token"} </h2>
                           
                            <h6 className="text-gray mb-0">
                                {unstakeLock?'Wait for the unlock period to Unstake':
                                'By unstaking you can withdraw your deposited token and claiming the rewards'}</h6>
                        </Col>
                    </Row>
                </div>

                <Row className="g-lg-4 g-3 justify-content-center">
                    <Col md={8} sm={6}>
                        {/* <div className="box mb-20 d-flex flex-column p-3">
                            <div className="d-flex align-items-center text-gray justify-content-between">
                                <span>Liquidate &lt; 180%</span>
                                <span>Target &lt; 540% <Tooltip pos="right" text="Texting" /></span>
                            </div>
                            {withdrawnotlock?(<>
                                <ProgressBar className="progress-shadow my-4" variant="green-light" now={90} />
                            </>):(<>
                                <ProgressBar className="progress-shadow my-4" variant="danger" now={90} />
                               
                            </>)}
                            
                        </div> */}
                        <div className="box p-3 p-md-4">
                            {unstakeLock?(<>
                                <div className="swap-card flex mb-3">
                                <div>
                                    <h4 className="mb-3">Next withdraw unlock time</h4>
                                    <h5 className="text-gray">Next Seigniorage</h5>
                                    <Timer count={unloacktimeinDateformat? unloacktimeinDateformat: '0'} />
                                </div>
                                <div className="ms-auto">
                                    <ProgressBarCircle
                                        percentage={timePercentage}
                                        startColor="#20E2D0"
                                        endColor="#00C2FF"
                                        gradientId="withdraw"
                                    />
                                </div>
                                
                            </div>
                            <button type="button" className="btn btn-grad w-100" disabled><span>Withdraw</span></button>
                            </>):(<>
                                

                            <h5 className="flex g-1">Withdraw  </h5>
                            <div className="swap-card mb-20">
                                <div className="mb-20 d-flex flex-wrap justify-content-between align-items-center">
                                    <h3 className="flex mb-0 g-2">
                                        <img src={location.state? location.state.tokenLogo :""} height="42px" alt="SNXLogo" />
                                        {location.state? location.state.TokenName :"Token"}
                                    </h3>
                                    <div className="text-end ms-auto">
                                        <h3 className="mb-1">Enter Amount</h3>
                                        <NumericFormat type="text" style={{maxWidth: '130'}} className="text-gray h3 btn-reset text-end border p-1 rounded-2" value={enteredValue} placeholder="Amount" allowNegative={false} onChange={(e) => setenteredValue(e.target.value)}/>
                                    </div>
                                </div>

                                <Row className="g-2">
                                <Col>
                                    <button className={`btn btn-toggle ${activePercentage === 25 ? 'active' : ''} w-100`} onClick={() => maxCall(25)}>
                                        <span>25%</span>
                                    </button>
                                    </Col>
                                    <Col>
                                    <button className={`btn btn-toggle ${activePercentage === 50 ? 'active' : ''} w-100`} onClick={() => maxCall(50)}>
                                        <span>50%</span>
                                    </button>
                                    </Col>
                                    <Col>
                                    <button className={`btn btn-toggle ${activePercentage === 75 ? 'active' : ''} w-100`} onClick={() => maxCall(75)}>
                                        <span>75%</span>
                                    </button>
                                    </Col>
                                    <Col>
                                    <button className={`btn btn-toggle ${activePercentage === 100 ? 'active' : ''} w-100`} onClick={() => maxCall(100)}>
                                        <span>100%</span>
                                    </button>
                                    </Col>
                                </Row>
                            </div>
                            <button type="button" className="btn btn-grad w-100" onClick={()=>withdraw()}><span>Withdraw</span></button>
                            </>)}
                          

                            {/* <button type="button" className="btn btn-grad w-100" disabled><span>Withdraw</span></button> */}
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <div className="box mb-2 swap-value">
                            <div className="p-4 d-flex justify-content-between border-bottom">
                                <h5 className="flex g-1">Total Deposited  </h5>
                                <div className="d-flex g-2 text-end flex-column justify-content-end">
                                
                                        <h3 className="text-green-light mb-1">{TotalStakedValue?parseFloat(TotalStakedValue/(10**location.state.decimalAmount)).toFixed(2):'0.0'}</h3>
                                    <Badge bg="green-light" className="me-auto">{location.state? location.state.TokenName :""}</Badge>
                                    
                                </div>
                            </div>
                            <div className="p-4 d-flex justify-content-between">
                                <h5 className="flex g-1">Your deposits  </h5>
                                <div className="d-flex g-2 text-end flex-column justify-content-end">
                                    <h3 className="text-green-light mb-0">{stakedAmount?parseFloat(stakedAmount/(10**location.state.decimalAmount)).toFixed(2):'0.0'}</h3>
                                </div>
                            </div>
                        </div>
                        {/* <div className="swap-card flex mb-2 p-3 p-md-4">
                            <h4 className="mb-0 font-600">Staking Guide</h4>
                            <img src={HedgeDebtI} className="ms-auto" alt="HedgeDebtI" />
                        </div>
                        <div className="swap-card flex mb-2 p-3 p-md-4">
                            <h4 className="mb-0 font-600">Sell dSNX for sUSD</h4>
                            <img src={HedgeDebt2} className="ms-auto" alt="HedgeDebtI" />
                        </div>
                        <div className="swap-card flex mb-2 p-3 p-md-4">
                            <h4 className="mb-0 font-600">Bridge</h4>
                            <img src={HedgeDebt3} className="ms-auto" alt="HedgeDebtI" />
                        </div>
                        <div className="swap-card flex mb-2 p-3 p-md-4">
                            <h4 className="mb-0 font-600">Self Liquidate</h4>
                            <img src={HedgeDebt4} className="ms-auto" alt="HedgeDebtI" />
                        </div> */}
                    </Col>
                </Row>
            </div>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </LayoutAfterLogin>
    );
}

export default StakeWithdraw;