import fireDb from "./firebase";

export const updatePrice = async(Price,timing,cicultingsupply) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/JokerPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,circulatingSupply:cicultingsupply
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updatedimePrice = async(Price,timing,cicultingsupply) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/DimePrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,circulatingSupply:cicultingsupply
        })
        .then(()=>{
          console.log("updatedimePrice")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updatecreditPrice = async(Price,timing,cicultingsupply) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/CreditPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,circulatingSupply:cicultingsupply
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updatetauPrice = async(Price,timing,cicultingsupply) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/TauPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,circulatingSupply:cicultingsupply
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updatetreasuryPrice = async(Price,timing,jokervalue,dimevalue,creditsvalue,usdcvalue) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/TreasuryPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,jokerValue:jokervalue,dimeValue:dimevalue,creditsValue:creditsvalue,USDCValue:usdcvalue
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updateComparisonPrice = async(jokerprice,timing,dimeCreditsprice) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,jokerValue:jokerprice,entryTime:timing,dimeCreditValue:dimeCreditsprice
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updateCollateralValue = async(collateral) =>{
    let currentTime = (new Date().getTime())/1000;
    let ref2= await fireDb.database().ref(`PriceHistory/collateralValue`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,Collateral:collateral,entryTime:currentTime
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}


export const updateFloorPrice = async(Price,timing) =>{
    let ref2= await fireDb.database().ref(`FloorPriceHistory/JokerFloorPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const getupdatePrice = async (Price, timing, circulatingSupply) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerPrice`);

        // Retrieve data
        ref2.once("value", (snapshot) => {
            const data = snapshot.val();
            // console.log("Retrieved data:", data);
            // Use the retrieved data as needed
        });
    } catch (error) {
        console.error("Error:", error);
    }
}

// export const getPriceOneDay = async (tokenname) => {
//     try {
//         let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

//         // Retrieve data
//         const snapshot = await ref2.once("value");
//         const data = snapshot.val();

//         // Get the current time in milliseconds
//         const currentTime = Date.now();

//         // Calculate the timestamp for 24 hours ago
//         const twentyFourHoursAgo =( currentTime - (24 * 60 * 60 *1000 ))/1000;

//         console.log("twentyFourHoursAgo",twentyFourHoursAgo)

//         // Extract price and epoch from the retrieved data and combine them for the last 24 hours
//         const priceEpochDataLast24Hours = [];
//         for (const key in data) {
//             if (data.hasOwnProperty(key)) {
//                 const entry = data[key];
//                 // Check if the epoch is within the last 24 hours
//                 if (entry.entryTime >= twentyFourHoursAgo && entry.entryTime <= currentTime) {
//                     priceEpochDataLast24Hours.push({ price: entry.price, epoch: entry.entryTime });
//                 }
//             }
//         }

//         console.log("Price and Epoch Data for the Last 24 Hours:", priceEpochDataLast24Hours);
//         // Use the retrieved data as needed
//     } catch (error) {
//         console.error("Error:", error);
//     }
// }
export const getPriceOneDay = async (tokenname) => {
    try {
      const currentDate = new Date(); // Current date and time
  
      // Calculate the start time (24 hours ago from the current time)
      const startOfLast24Hours = new Date(currentDate.getTime() - (24 * 60 * 60 * 1000));
  
      // Firebase reference to the PriceHistory node for the specified token
      const ref = fireDb.database().ref(`PriceHistory/${tokenname}`);
  
      // Retrieve data for the last 24 hours
      const snapshot = await ref.orderByChild('entryTime').startAt(startOfLast24Hours.getTime() / 1000).endAt(currentDate.getTime() / 1000).once('value');
      const data = snapshot.val();
  
      // Initialize pricesByHour object to store average prices for each hour
      const pricesByHour = {};
  
      // Loop through each hour in the last 24 hours
      for (let hour = 0; hour < 24; hour++) {
        // Calculate the start and end time of the current hour
        const hourStart = new Date(startOfLast24Hours);
        hourStart.setHours(hourStart.getHours() + hour);
  
        const hourEnd = new Date(hourStart);
        hourEnd.setHours(hourEnd.getHours(), 59, 59, 999);
  
        // Filter data for entries within the current hour's time range
        const pricesInHour = Object.values(data || {}).filter(entry => {
          const entryTime = new Date(entry.entryTime * 1000);
          return entryTime >= hourStart && entryTime <= hourEnd;
        });
  
        // Calculate the average price for entries within the current hour
        if (pricesInHour.length > 0) {
          const totalPrices = pricesInHour.reduce((sum, entry) => sum + parseFloat(entry.price), 0);
          const averagePrice = totalPrices / pricesInHour.length;
          const formattedHour = formatHour(hourStart.getHours());
  
          pricesByHour[formattedHour] = averagePrice;
        }
      }
  
      // Return pricesByHour object containing average prices for each hour
      return pricesByHour;
    } catch (error) {
      console.error('Error fetching prices for the last 24 hours:', error);
      return {}; // Return empty object or handle error appropriately
    }
  };
  
  // Helper function to format hour (e.g., 0 => '12 AM', 13 => '1 PM', etc.)
  const formatHour = (hour) => {
    const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
    const period = hour < 12 ? 'AM' : 'PM';
    return `${formattedHour} ${period}`;
  };


export const getPriceOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            pricesByDay[formattedDate].push(entry.price);
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                // const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                // const month = entryDate.toLocaleString('default', { month: 'short' });
                // const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getPriceOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneMonthAgo && entryDate <= currentDate; // Filter data from one month ago to current date
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            pricesByDay[formattedDate].push( parseFloat(entry.price));
        });
//  console.log("fileterdaata",pricesByDay)
        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getFloorPriceOneMonth = async () => {
    try {
        let ref2 = await fireDb.database().ref(`FloorPriceHistory/JokerFloorPrice`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneMonthAgo && entryDate <= currentDate; // Filter data from one month ago to current date
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            pricesByDay[formattedDate].push( parseFloat(entry.price));
        });
//  console.log("fileterdaata",pricesByDay)
console.log("Average Prices By Day:", pricesByDay);
        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                averagePricesByDay[date] = averagePrice;
            }
        }

        
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const deleteEntriesForSpecificDate = async () => {
    try {
        const ref = fireDb.database().ref(`FloorPriceHistory/JokerFloorPrice`);
        
        // Retrieve all entries
        const snapshot = await ref.once("value");
        const data = snapshot.val();

        if (!data) {
            console.log("No data found.");
            return;
        }

        // Filter entries for 22nd April (date needs to be adjusted to match the exact date format in your data)
        const entriesToDelete = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            return entryDate.getDate() === 22 && entryDate.getMonth() === 3; // Month is zero-indexed (0 = January, 3 = April)
        });

        // Delete filtered entries
        const deletionPromises = entriesToDelete.map(([key, entry]) => {
            return ref.child(key).remove(); // Remove the entry by its key
        });
        console.log("deleted entries",entriesToDelete)
        // Wait for all deletions to complete
        await Promise.all(deletionPromises);

        console.log("Entries deleted successfully.");
    } catch (error) {
        console.error("Error deleting entries:", error);
    }
};

export const getPriceOneYear =async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: entry.price, epoch: entry.entryTime });
            }
        }

           // Filter data for the last 12 months
      const currentDate = new Date();
      const twelveMonthsAgo = new Date();
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
  
      const filteredData = priceEpochData.filter(({ epoch }) => {
        const entryDate = new Date(epoch * 1000);
        return entryDate >= twelveMonthsAgo && entryDate <= currentDate;
      });
        // Process data by month
        const pricesByMonth = {};
        filteredData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        // console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getCirculatingSupplyOneDay = async (tokenname) => {
    try {
        const currentDate = new Date(); // Current date and time
    
        // Calculate the start time (24 hours ago from the current time)
        const startOfLast24Hours = new Date(currentDate.getTime() - (24 * 60 * 60 * 1000));
    
        // Firebase reference to the PriceHistory node for the specified token
        const ref = fireDb.database().ref(`PriceHistory/${tokenname}`);
    
        // Retrieve data for the last 24 hours
        const snapshot = await ref.orderByChild('entryTime').startAt(startOfLast24Hours.getTime() / 1000).endAt(currentDate.getTime() / 1000).once('value');
        const data = snapshot.val();
    
        // Initialize pricesByHour object to store average prices for each hour
        const pricesByHour = {};
    
        // Loop through each hour in the last 24 hours
        for (let hour = 0; hour < 24; hour++) {
          // Calculate the start and end time of the current hour
          const hourStart = new Date(startOfLast24Hours);
          hourStart.setHours(hourStart.getHours() + hour);
    
          const hourEnd = new Date(hourStart);
          hourEnd.setHours(hourEnd.getHours(), 59, 59, 999);
    
          // Filter data for entries within the current hour's time range
          const pricesInHour = Object.values(data || {}).filter(entry => {
            const entryTime = new Date(entry.entryTime * 1000);
            return entryTime >= hourStart && entryTime <= hourEnd;
          });
    
          // Calculate the average price for entries within the current hour
          if (pricesInHour.length > 0) {
            const totalPrices = pricesInHour.reduce((sum, entry) => sum + parseFloat(entry.circulatingSupply), 0);
            const averagePrice = totalPrices / pricesInHour.length;
            const formattedHour = formatHour(hourStart.getHours());
    
            pricesByHour[formattedHour] = averagePrice;
          }
        }
    
        // Return pricesByHour object containing average prices for each hour
        return pricesByHour;
      } catch (error) {
        console.error('Error fetching prices for the last 24 hours:', error);
        return {}; // Return empty object or handle error appropriately
      }
  };


export const getCirculatingsupplyOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            pricesByDay[formattedDate].push(entry.circulatingSupply);
        });
      
        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                // const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                // const month = entryDate.toLocaleString('default', { month: 'short' });
                // const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getCirculatingsupplyOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneMonthAgo && entryDate <= currentDate; // Filter data from one month ago to current date
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            pricesByDay[formattedDate].push(parseFloat(entry.circulatingSupply) );
        });
//  console.log("fileterdaata",pricesByDay)
        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                // const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                 // Calculate the smallest price for the day
                 const nonZeroPrices = prices.filter(price => parseFloat(price) !== 0);

                 // Check if there are non-zero prices to calculate minimum
                 if (nonZeroPrices.length > 0) {
                     // Calculate the smallest price for the day
                     const minPrice = Math.min(...nonZeroPrices.map(price => parseFloat(price)));
         
                     // Store the minimum price for the day
                     averagePricesByDay[date] = parseFloat(minPrice.toFixed(1)); 
                } else {
                     // If all prices are zero, handle accordingly (e.g., set to null or skip this date)
                     // Here we can choose to skip adding to averagePricesByDay or set a default value
                     // averagePricesByDay[date] = null; // Or any other default value
                 }
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const gettotalSupplyOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneMonthAgo && entryDate <= currentDate; // Filter data from one month ago to current date
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            pricesByDay[formattedDate].push(parseFloat(entry.circulatingSupply) );
        });
//  console.log("fileterdaata",pricesByDay)
        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getCirculatingsupplyOneYear = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: parseFloat(entry.circulatingSupply), epoch: entry.entryTime });
            }
        }

           // Filter data for the last 12 months
      const currentDate = new Date();
      const twelveMonthsAgo = new Date();
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
  
      const filteredData = priceEpochData.filter(({ epoch }) => {
        const entryDate = new Date(epoch * 1000);
        return entryDate >= twelveMonthsAgo && entryDate <= currentDate;
      });
        // console.log("priceEpochData Prices By Day:", priceEpochData);
        // Process data by month
        const pricesByMonth = {};
        filteredData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

    

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        // console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getMarketcapOneDay = async (tokenname) => {
    try {
        // Set the start and end time for the last day
        const currentDate = new Date();
        const startOfLastDay = new Date(currentDate);
        startOfLastDay.setDate(currentDate.getDate() - 1);
        startOfLastDay.setHours(0, 0, 0, 0); // Set to the beginning of the last day

        const endOfLastDay = new Date(currentDate);
        endOfLastDay.setDate(currentDate.getDate() - 1);
        endOfLastDay.setHours(23, 59, 59, 999); // Set to the end of the last day

        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data for the last day
        const snapshot = await ref2.orderByChild('entryTime').startAt(startOfLastDay.getTime() / 1000).endAt(endOfLastDay.getTime() / 1000).once('value');
        const data = snapshot.val();

        // Process data by hour within the last day
        const pricesByHour = {};
        for (let hour = 0; hour < 24; hour++) {
            const hourStart = new Date(startOfLastDay);
            hourStart.setHours(hour, 0, 0, 0);

            const hourEnd = new Date(startOfLastDay);
            hourEnd.setHours(hour, 59, 59, 999);

            const pricesInHour = Object.values(data).filter(entry => {
                const entryTime = new Date(entry.entryTime * 1000);
                return entryTime >= hourStart && entryTime <= hourEnd;
            });

            if (pricesInHour.length > 0) {
                const averagePriceInHour = pricesInHour.reduce((total, entry) => total + parseFloat(parseFloat(entry.circulatingSupply)*parseFloat(entry.price)), 0) / pricesInHour.length;
                const formattedHour = formatHour(hour);
                pricesByHour[formattedHour] = averagePriceInHour;
            }
        }

        // console.log("Average Prices By Hour:", pricesByHour);
        return pricesByHour;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}



export const getMarketcapOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            pricesByDay[formattedDate].push(((parseFloat(entry.circulatingSupply)*parseFloat(entry.price))));
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                // const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                // const month = entryDate.toLocaleString('default', { month: 'short' });
                // const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getMarketcapOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneMonthAgo && entryDate <= currentDate; // Filter data from one month ago to current date
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            pricesByDay[formattedDate].push(parseFloat(entry.circulatingSupply) * parseFloat(entry.price));
        });
//  console.log("fileterdaata",pricesByDay)
        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}





export const getMarketcapOneYear = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: ((parseFloat(entry.circulatingSupply)*parseFloat(entry.price))), epoch: entry.entryTime });
            }
        }
           // Filter data for the last 12 months
      const currentDate = new Date();
      const twelveMonthsAgo = new Date();
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
  
      const filteredData = priceEpochData.filter(({ epoch }) => {
        const entryDate = new Date(epoch * 1000);
        return entryDate >= twelveMonthsAgo && entryDate <= currentDate;
      });

        // Process data by month
        const pricesByMonth = {};
        filteredData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        // console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getTreasuryOneDay =async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/TreasuryPrice`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Get the current time in milliseconds
        const currentTime = Date.now();

        // Calculate the timestamp for 24 hours ago
        const twentyFourHoursAgo =( currentTime - (24 * 60 * 60 *1000 ))/1000;

        // console.log("twentyFourHoursAgo",twentyFourHoursAgo)

        // Extract price and epoch from the retrieved data and combine them for the last 24 hours
        const priceEpochDataLast24Hours = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                // Check if the epoch is within the last 24 hours
                if (entry.entryTime >= twentyFourHoursAgo && entry.entryTime <= currentTime) {

                    if(tokenname === "price") {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.price))), epoch: entry.entryTime });
                    }
                    else if(tokenname === 'jokerValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.jokerValue))), epoch: entry.entryTime });
                    }else if(tokenname === 'dimeValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.dimeValue))), epoch: entry.entryTime });
                    }else if(tokenname === 'creditsValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.creditsValue))), epoch: entry.entryTime });
                    }else if(tokenname === 'USDCValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.USDCValue))), epoch: entry.entryTime });
                    }
                    // priceEpochDataLast24Hours.push({ price: (parseFloat(entry.circulatingSupply)*parseFloat(entry.price)), epoch: entry.entryTime });
                }
            }
        }

        // console.log("priceEpochDataLast24Hours and Epoch Data for the Last 24 Hours:", priceEpochDataLast24Hours);
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getTreasuryOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/TreasuryPrice`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }

            if(tokenname === "price") {
                pricesByDay[formattedDate].push((parseFloat(entry.price)))
            }
            else if(tokenname === 'jokerValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.jokerValue)))
            }else if(tokenname === 'dimeValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.dimeValue)))
            }else if(tokenname === 'creditsValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.creditsValue)))
            }else if(tokenname === 'USDCValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.USDCValue)))
               
            }
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                // const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                // const month = entryDate.toLocaleString('default', { month: 'short' });
                // const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getTreasuryOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/TreasuryPrice`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneMonthAgo && entryDate <= currentDate; // Filter data from one month ago to current date

        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`; // Get day of the month (1-31)
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            if(tokenname === "price") {
                pricesByDay[formattedDate].push((parseFloat(entry.price)))
            }
            else if(tokenname === 'jokerValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.jokerValue)))
            }else if(tokenname === 'dimeValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.dimeValue)))
            }else if(tokenname === 'creditsValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.creditsValue)))
            }else if(tokenname === 'USDCValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.USDCValue)))
               
            }
        
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                // const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                // const month = entryDate.toLocaleString('default', { month: 'short' });
                // const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getTreasuryOneYear = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/TreasuryPrice`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                let pricevar = 'entry.price';
                // console.log("pricedata forma",pricevar)
                if(tokenname === "price") {
                    priceEpochData.push({ price: ((parseFloat(entry.price))), epoch: entry.entryTime });
                }
                else if(tokenname === 'jokerValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.jokerValue))), epoch: entry.entryTime });
                }else if(tokenname === 'dimeValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.dimeValue))), epoch: entry.entryTime });
                }else if(tokenname === 'creditsValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.creditsValue))), epoch: entry.entryTime });
                }else if(tokenname === 'USDCValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.USDCValue))), epoch: entry.entryTime });
                }
                
            }
        }
        // console.log("pricedata",priceEpochData)
   // Filter data for the last 12 months
   const currentDate = new Date();
   const twelveMonthsAgo = new Date();
   twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

   const filteredData = priceEpochData.filter(({ epoch }) => {
     const entryDate = new Date(epoch * 1000);
     return entryDate >= twelveMonthsAgo && entryDate <= currentDate;
   });
        // Process data by month
        const pricesByMonth = {};
        filteredData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        // console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getADSBOneDay =async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Get the current time in milliseconds
        const currentTime = Date.now();

        // Calculate the timestamp for 24 hours ago
        const twentyFourHoursAgo =( currentTime - (24 * 60 * 60 *1000 ))/1000;

        // console.log("twentyFourHoursAgo",twentyFourHoursAgo)

        // Extract price and epoch from the retrieved data and combine them for the last 24 hours
        const priceEpochDataLast24Hours = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                // Check if the epoch is within the last 24 hours
                if (entry.entryTime >= twentyFourHoursAgo && entry.entryTime <= currentTime) {

                    if(tokenname === "dime") {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.dimeCreditValue))), epoch: entry.entryTime });
                    }
                    else if(tokenname === 'jokerValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.jokerValue))), epoch: entry.entryTime });
                    }
                    // priceEpochDataLast24Hours.push({ price: (parseFloat(entry.circulatingSupply)*parseFloat(entry.price)), epoch: entry.entryTime });
                }
            }
        }

        // console.log("priceEpochDataLast24Hours and Epoch Data for the Last 24 Hours:", priceEpochDataLast24Hours);
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getADSBOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }

            if(tokenname === "dime") {
                pricesByDay[formattedDate].push((parseFloat((entry.dimeCreditValue))))
            }
            else if(tokenname === 'jokerValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.jokerValue)))
            }
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                // const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                // const month = entryDate.toLocaleString('default', { month: 'short' });
                // const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getADSBOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneMonthAgo && entryDate <= currentDate; 
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000);
            const monthName = entryDate.toLocaleString('default', { month: 'short' });
            const day = entryDate.getDate(); // Get day of the month (1-31)
            const formattedDate = `${monthName} ${day}`;
            if (!pricesByDay[formattedDate]) {
                pricesByDay[formattedDate] = [];
            }
            if(tokenname === "dime") {
                pricesByDay[formattedDate].push((parseFloat(entry.dimeCreditValue)))
            }
            else if(tokenname === 'jokerValue') {
                pricesByDay[formattedDate].push((parseFloat(entry.jokerValue)))
            }
        
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                // const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                // const month = entryDate.toLocaleString('default', { month: 'short' });
                // const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[date] = averagePrice;
            }
        }

        // console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getADSBOneYear = async (tokenname) => {
    try {
      const refPath = `PriceHistory/JokerDimeCredits`; // Adjust the path based on your database structure
      const ref = fireDb.database().ref(refPath);
  
      // Retrieve data
      const snapshot = await ref.once('value');
      const data = snapshot.val();
  
      // Extract and filter price and epoch data for the specified token
      const priceEpochData = [];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const entry = data[key];
          let price;
          if (tokenname === 'dime') {
            price = parseFloat(entry.dimeCreditValue);
          } else if (tokenname === 'jokerValue') {
            price = parseFloat(entry.jokerValue);
          }
  
          if (!isNaN(price)) {
            priceEpochData.push({ price, epoch: entry.entryTime });
          }
        }
      }
  
      // Filter data for the last 12 months
      const currentDate = new Date();
      const twelveMonthsAgo = new Date();
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
  
      const filteredData = priceEpochData.filter(({ epoch }) => {
        const entryDate = new Date(epoch * 1000);
        return entryDate >= twelveMonthsAgo && entryDate <= currentDate;
      });
  
      // Process data by month
      const pricesByMonth = {};
      filteredData.forEach(({ price, epoch }) => {
        const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
        if (!pricesByMonth[monthYear]) {
          pricesByMonth[monthYear] = [];
        }
        pricesByMonth[monthYear].push(price);
      });
  
      // Calculate average price for each month
      const averagePricesByMonth = {};
      for (const monthYear in pricesByMonth) {
        if (pricesByMonth.hasOwnProperty(monthYear)) {
          const prices = pricesByMonth[monthYear];
          const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;
  
          const [year, month] = monthYear.split('-');
          const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
          const formattedMonthYear = `${monthName} ${year}`;
  
          averagePricesByMonth[formattedMonthYear] = averagePrice;
        }
      }
  
      // Return average prices for the last 12 months
      return averagePricesByMonth;
    } catch (error) {
      console.error('Error:', error);
      return {}; // Return an empty object or handle the error appropriately
    }
  };


export const getCOllateralOneYear = async () => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/collateralValue`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                let pricevar = 'entry.price';
                // console.log("pricedata forma",pricevar)
                
                    priceEpochData.push({ price: ((parseFloat(entry.Collateral))), epoch: entry.entryTime });
               
                
            }
        }
        // console.log("pricedata",priceEpochData)

         // Filter data for the last 12 months
      const currentDate = new Date();
      const twelveMonthsAgo = new Date();
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
  
      const filteredData = priceEpochData.filter(({ epoch }) => {
        const entryDate = new Date(epoch * 1000);
        return entryDate >= twelveMonthsAgo && entryDate <= currentDate;
      });

        // Process data by month
        const pricesByMonth = {};
        filteredData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        // console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getCollateralIncrease = async (interval) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/collateralValue`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: parseFloat(entry.Collateral), epoch: entry.entryTime });
            }
        }

        // Sort the data by epoch in ascending order
        priceEpochData.sort((a, b) => a.epoch - b.epoch);

        // Get the epoch of the current date
        const currentDateEpoch = Date.now() / 1000;

        // Calculate the epoch for the start of the specified interval
        let intervalStartEpoch;
        if (interval === '1hour') {
            intervalStartEpoch = currentDateEpoch - (1 * 60 * 60);
        } else if (interval === '24hours') {
            intervalStartEpoch = currentDateEpoch - (24 * 60 * 60);
        } else if (interval === '1month') {
            intervalStartEpoch = currentDateEpoch - (30 * 24 * 60 * 60); // Assuming 30 days in a month
        } else {
            throw new Error("Invalid interval specified.");
        }

        // Filter the data for the specified interval
        const dataInterval = priceEpochData.filter(entry => entry.epoch >= intervalStartEpoch);

        // Calculate the collateral values at the beginning and end of the interval
        const collateralValueAtStart = dataInterval[0].price;
        const collateralValueAtEnd = dataInterval[dataInterval.length - 1].price;

        // Calculate the percentage increase
        const percentageIncrease = ((collateralValueAtEnd - collateralValueAtStart) / collateralValueAtStart) * 100;

        // console.log(`Collateral value at start of ${interval}:`, collateralValueAtStart);
        // console.log(`Collateral value at end of ${interval}:`, collateralValueAtEnd);
        // console.log(`Percentage increase in collateral value over ${interval}:`, percentageIncrease);

        return percentageIncrease;
    } catch (error) {
        console.error("Error:", error);
    }
}

export const gepriceIncrease = async (tokenanem,interval) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenanem}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();
       
        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: parseFloat(entry.price), epoch: entry.entryTime });
            }
        }
        

        // Sort the data by epoch in ascending order
        priceEpochData.sort((a, b) => a.epoch - b.epoch);

        // Get the epoch of the current date
        const currentDateEpoch = Date.now() / 1000;

        // Calculate the epoch for the start of the specified interval
        let intervalStartEpoch;
        if (interval === '1hour') {
            intervalStartEpoch = currentDateEpoch - (1 * 60 * 60 );
        } else if (interval === '24hours') {
            intervalStartEpoch = currentDateEpoch - (24 * 60 * 60 );
        } else if (interval === '1month') {
            intervalStartEpoch = currentDateEpoch - (30 * 24 * 60 * 60 ); // Assuming 30 days in a month
        } else {
            throw new Error("Invalid interval specified.");
        }
  
        // Filter the data for the specified interval
        const dataInterval = priceEpochData.filter(entry => parseFloat(entry.epoch) >= parseFloat(intervalStartEpoch));
        // console.log("dataprice",intervalStartEpoch,priceEpochData,dataInterval);
        // Calculate the collateral values at the beginning and end of the interval
        const collateralValueAtStart = dataInterval[0].price;
        const collateralValueAtEnd = dataInterval[dataInterval.length - 1].price;

        // Calculate the percentage increase
        const percentageIncrease = ((collateralValueAtEnd - collateralValueAtStart) / collateralValueAtStart) * 100;

        // console.log(`Collateral value at start of ${interval}:`, collateralValueAtStart);
        // console.log(`Collateral value at end of ${interval}:`, collateralValueAtEnd);
        // console.log(`Percentage increase in collateral value over ${interval}:`, percentageIncrease);

        return percentageIncrease;
    } catch (error) {
        console.error("Error:", error);
    }
}
export const getPriceIncreaselast7days = async (tokenName, interval) => {
    try {
        const ref = fireDb.database().ref(`PriceHistory/${tokenName}`);

        // Retrieve data
        const snapshot = await ref.once("value");
        const data = snapshot.val() || {}; // Use empty object if data is null

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: parseFloat(entry.price), epoch: entry.entryTime });
            }
        }

        // Sort the data by epoch in ascending order
        priceEpochData.sort((a, b) => a.epoch - b.epoch);

        // Get the epoch of the current date
        const currentDateEpoch = Date.now() / 1000;

        // Calculate the epoch for the start of the past 7 days (7 days * 24 hours * 60 minutes * 60 seconds)
        const intervalStartEpoch = currentDateEpoch - (7 * 24 * 60 * 60);

        // Filter the data for the past 7 days
        const dataInterval = priceEpochData.filter(entry => parseFloat(entry.epoch) >= intervalStartEpoch);

        // Calculate the collateral values at the beginning and end of the interval
        const collateralValueAtStart = dataInterval[0].price;
        const collateralValueAtEnd = dataInterval[dataInterval.length - 1].price;

        // Calculate the percentage increase
        const percentageIncrease = ((collateralValueAtEnd - collateralValueAtStart) / collateralValueAtStart) * 100;

        // Return the percentage increase
        return percentageIncrease;
    } catch (error) {
        console.error("Error:", error);
        throw error; // Re-throw the error to be handled by the caller
    }
};



export const createTxn = async(tokenname,txnhash,type,to,from) =>{
    let ref2 = await fireDb.database().ref(`PriceHistory/TxnHistory`);
        const db = ref2.push().key;   
        let currenttime = (new Date().getTime())/1000;                                                                   
        ref2.child(db).set({
          key:db,tokenNmae:tokenname,entryTime:currenttime,txnHash:txnhash,From:from,To:to,Type:type
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}
// export const retrieveTxnByPage = async (pageNumber) => {
//     const itemsPerPage = 2;
//     const offset = (pageNumber - 1) * itemsPerPage;

//     let ref = fireDb.database().ref(`PriceHistory/TxnHistory`);
//     ref = ref.orderByChild('entryTime').limitToFirst(itemsPerPage).startAt(offset);

//     const snapshot = await ref.once('value');
//     const data = snapshot.val();
//     console.log("retrieved",data);

//     let ref1 = fireDb.database().ref(`PriceHistory/TxnHistory`);
//     ref1 = ref1.orderByChild('entryTime'); // Optionally, you can order by a specific child node

//     const snapshot1 = await ref1.once('value');
//     const data1 = snapshot1.val();
//     console.log("snapshot1",data1);
    
//     return data;
// };
export const retrieveTxnByPage = async (pageNumber,itemsPerPage) => {
    
    const totalItems = pageNumber * itemsPerPage;

    let ref = fireDb.database().ref(`PriceHistory/TxnHistory`);
    ref = ref.orderByChild('entryTime').limitToLast(totalItems);

    const snapshot = await ref.once('value');
    const data = snapshot.val();
    
    // Convert the object to an array and reverse it to get the most recent entries first
    const dataArray = Object.values(data).reverse();
    
    // Get the two entries corresponding to the current page
    const startIndex = Math.max(dataArray.length - itemsPerPage, 0);
    const pageData = dataArray.slice(startIndex);
    // console.log("snapshot1",pageData);

    return pageData;
}

export const getTotalPages = async (itemsPerPage) => {
    let ref = fireDb.database().ref(`PriceHistory/TxnHistory`);
    const snapshot = await ref.once('value');
    const totalEntries = snapshot.numChildren();

    const totalPages = Math.ceil(totalEntries / itemsPerPage);
    
    return totalPages;
};

export const recordUserVisits = async (address, pageName) => {
    try{
        const locationDetails = await fetch('https://geolocation-db.com/json/')
        let location = await locationDetails.json();
        let ipAddress = location.IPv4;
        let region = location.country_name;
    
        let addressSet = address ? address : "Not Connected";
    
        let ref2 = fireDb.database().ref(`UserVisits`);
            const db = ref2.push().key;   
            let currentTime = (new Date().getTime())/1000;                                                                   
            ref2.child(db).set({
              address: addressSet,
              ipAddress: ipAddress,
              pageName: pageName,
              region: region,
              createdAt: currentTime,
            })
            .then(()=>{
              console.log(" updatePrice done")
            }).catch((error) => {
                console.error("Error:", error);
              });
    }catch(e){
        console.log("errro",e)
    }
    
}

export const recordUserVisits1 = async () => {
    try {
        // Fetch location details based on IP address
        const locationDetails = await fetch('https://geolocation-db.com/json/');
        const location = await locationDetails.json();
        const countryName = location.country_name;

        // Check if the user is located in the US (United States)
        const isUSVisitor = countryName === 'United States';
        if(isUSVisitor)
            return false;
        else
            return true;

        // Return whether access is allowed (true if not in the US, false if in the US)
        // return !isUSVisitor;
    } catch (error) {
        console.error('Error fetching location:', error);
        // Handle errors by assuming access is not allowed (safe fallback)
        return false;
    }
}

export const AddWallet = async(address,bool) =>{
    let ref2= await fireDb.database().ref(`Eligibility/Wallet`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,Wallet:address,Eligible:bool
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}
export const checkWalletEligible = async (address) => {
    try {
        // Reference to the 'Wallet' node under 'Eligibility' in Firebase database
        const ref = fireDb.database().ref('Eligibility/Wallet');

        // Retrieve the data (all wallets and their eligibility statuses)
        const snapshot = await ref.once('value');

        // Get the value (wallets data) from the snapshot
        const walletsData = snapshot.val();

        if (walletsData) {
            // Loop through each wallet entry
            for (const key in walletsData) {
                if (walletsData.hasOwnProperty(key)) {
                    const walletEntry = walletsData[key];

                    // Check if the wallet address matches and return its eligibility status
                    if (walletEntry.Wallet === address) {
                        return walletEntry.Eligible; // Returns true or false
                    }
                }
            }
        }
        
        // If no match found, return null or false (depending on your requirement)
        return false; // or null or any other default value
    } catch (error) {
        console.error("Error fetching wallet data:", error);
        throw error; // Propagate the error back to the caller
        return false;
    }
};

export const createTxnDimePrice = async(tokenname,txnhash,to,from) =>{
    let ref2 = await fireDb.database().ref(`PriceHistory/ADSB1`);
        const db = ref2.push().key;   
        let currenttime = (new Date().getTime())/1000;                                                                   
        ref2.child(db).set({
          key:db,tokenNmae:tokenname,entryTime:currenttime,txnHash:txnhash,lastPrice:from,UpdatedPrice:to
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const createTxnPriceADSB2 = async(tokenname,txnhash,to,from) =>{
    let ref2 = await fireDb.database().ref(`PriceHistory/ADSB2`);
        const db = ref2.push().key;   
        let currenttime = (new Date().getTime())/1000;                                                                   
        ref2.child(db).set({
          key:db,tokenNmae:tokenname,entryTime:currenttime,txnHash:txnhash,lastPrice:from,UpdatedPrice:to
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const createTxnADSB2 = async(principle,txnhash) =>{
    let ref2 = await fireDb.database().ref(`PriceHistory/ADSB2txn`);
        const db = ref2.push().key;   
        let currenttime = (new Date().getTime())/1000;                                                                   
        ref2.child(db).set({
          key:db,Principle:principle,entryTime:currenttime,txnHash:txnhash
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}
export const getTxnADSB2 = async () => {
    try {
      let ref2 = await fireDb.database().ref(`PriceHistory/ADSB2txn`);
      let snapshot = await ref2.once('value');
      let data = snapshot.val();
      if (data) {
        const datas = [];
        snapshot.forEach((childSnapshot) => {
        datas.push(childSnapshot.val());
        });
        console.log("Fetched data:", data);
        return datas;
      } else {
        console.log("No data available");
        return null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

export const fetchLast10Data = async (tokenname) => {
    const ref =await fireDb.database().ref('PriceHistory/ADSB1');
    const snapshot = await ref
      .orderByChild('tokenNmae')
      .equalTo(tokenname)
      .limitToLast(3)
      .once('value');
  
    const data = [];
    snapshot.forEach((childSnapshot) => {
      data.push(childSnapshot.val());
    });
  
    return data.reverse(); // Reverse to get the latest first
  };


export const fetchLast10DataADSB2 = async (tokenname) => {
const ref =await fireDb.database().ref('PriceHistory/ADSB2');
const snapshot = await ref
    .orderByChild('tokenNmae')
    .equalTo(tokenname)
    .limitToLast(3)
    .once('value');

const data = [];
snapshot.forEach((childSnapshot) => {
    data.push(childSnapshot.val());
});

return data.reverse(); // Reverse to get the latest first
};

export const getTxnADSB2bypage = async (pageNumber, limit = 7) => {
    try {
        let ref2 = fireDb.database().ref('PriceHistory/ADSB2txn');

        // Fetch all data in order
        let snapshot = await ref2.orderByKey().once('value');
        let data = snapshot.val();

        if (data) {
        const datas = [];
        snapshot.forEach((childSnapshot) => {
            datas.push({ key: childSnapshot.key, ...childSnapshot.val() });
        });

        // Reverse the data
        datas.reverse();

        // Apply pagination
        const startAt = (pageNumber - 1) * limit;
        const paginatedData = datas.slice(startAt, startAt + limit);

        // Calculate total entries and total pages
        const totalEntries = datas.length;
        const totalPages = Math.ceil(totalEntries / limit);

        console.log("Fetched data:", paginatedData);
        return { data: paginatedData, totalPages: totalPages };
        } else {
        console.log("No data available");
        return { data: [], totalPages: 0 };
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return { data: [], totalPages: 0 };
    }
};
      
  
