import { Form } from "react-bootstrap";
import Bardata from "./BarChart";
import { getTreasuryOneMonth, getTreasuryOneWeek, getTreasuryOneYear, updateComparisonPrice, updatetreasuryPrice } from "../../../abi/firebasecode";
import { getJokerPrice, getDimePrice, getCreditsPrice, getDAIPrice, getTokenBalance, NumberAbbreviation } from "../../../abi/CommonSolFunctions";
import { useEffect, useState } from "react";
import { CHAIN_URL, ECO_Reserve_Treasury_Address, Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, DAI_Chainlink_Oracle_Address, DIME_Token_Address, DIME_Token_ABI, DIME_Chainlink_Oracle_Address, JOKER_Token_ABI, JOKER_Token_Address, JOKER_Chainlink_Oracle_Address,  CREDITS_Token_Address, CREDITS_Token_ABI, CREDITS_Chainlink_Oracle_Address } from "../../../abi/RefinedABI&ContractAddress";
import { AreaChart } from "./AreaChart1";
import dailogo from '../../../asserts/IMAGES/dai.svg';

function CarbonixCard({Oneyeardates,Oneyearprices}) {
    
  
    // console.log("getMonthValues", Oneyearvalue);

    return ( 
        <div className="box">
            <div className="p-3 d-flex align-items-center flex-warp">
                <h4 className="mb-0 text-center"><img src={dailogo} style={{ width: "25px", height: "25px", marginRight: '5px' }}/>Collateral Value</h4>
                
                {/* <div className="chart-filter d-flex align-items-center ms-auto">
              
                    <button
                    className={activeButton === '1W' ? 'active' : ''}
                    onClick={() => callselectedFunction('1W')} >
                    1W
                    </button>
                    <button
                    className={activeButton === '1M' ? 'active' : ''}
                    onClick={() => callselectedFunction('1M')} >
                    1M
                    </button>
                    <button
                    className={activeButton === '1Y' ? 'active' : ''}
                    onClick={() => callselectedFunction('1Y')} >
                    1Y
                    </button>
                </div> */}
            </div>

           

            {/* <div className="p-3">
                <Bardata labels={Oneyeardates?Oneyeardates:'0'} datas={Oneyearprices?Oneyearprices:'0'} />
            </div> */}
            <div className="p-3">
            <AreaChart dates={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name={"DAI"} checkvalue={"price"} />
            </div>
        </div>
     );
}

export default CarbonixCard;