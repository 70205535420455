import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ChartComponent = ({ data }) => {
  const chartRef = useRef();

  useEffect(() => {
    if (chartRef && chartRef.current && data) {
      const myChart = new Chart(chartRef.current, {
        type: 'doughnut',
        data: {
          labels: data.labels,
          datasets: [
            {
              label: 'Balance',
              data: data.values,
              backgroundColor: data.colors,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'right',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [data]);

  return (
    <div style={{ backgroundColor: 'var(--bg-black)', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
      <h2 style={{ color: 'white', textAlign: 'center', marginBottom: '75px' }}>DIME vs DAI in CREDITS Pool</h2>
      <div style={{ width: '100%', height: 'auto' }}>
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default ChartComponent;
