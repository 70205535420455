import LayoutAfterLogin from "../../component/LayoutAL";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Stasis from "./Stasis";
import Joker from "./Joker";
import { useEffect, useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

function Dashboard({balances,setEligibile,Eligibile,checkeligibility}) {
    const [selectedTab, setSelectedTab] = useState('Stasis'); // State to track the selected tab
    const { address, chainId, isConnected } = useWeb3ModalAccount();


    const handleTabChange = (tabKey) => {
        setSelectedTab(tabKey);
    };

    useEffect(()=>{
         checkeligibility();
        if(!Eligibile){
            setEligibile(false)

        }
    },[isConnected])

    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances} checkeligibility={checkeligibility}>
            <Tabs
                defaultActiveKey="Stasis"
                id="uncontrolled-tab-example"
                className="justify-content-between px-4 px-lg-5"
                onSelect={(eventKey) => handleTabChange(eventKey)}
                >
                <Tab eventKey="Stasis" title="Stasis">
                    <Stasis selectedTab={selectedTab}/>
                </Tab>
                <Tab eventKey="Wallet" title="Wallet">
                    <Joker selectedTab={selectedTab} balances={balances} />
                </Tab>
            </Tabs>
        </LayoutAfterLogin>
    );
}

export default Dashboard;