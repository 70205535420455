import { Form } from "react-bootstrap";
import { AreaChart } from "./AreaChart";
import { getCirculatingSupplyOneDay, getCirculatingsupplyOneMonth, getCirculatingsupplyOneWeek, getCirculatingsupplyOneYear, getMarketcapOneDay, getMarketcapOneMonth, getMarketcapOneWeek, getMarketcapOneYear, getPriceOneDay, getPriceOneMonth, getPriceOneWeek, getPriceOneYear, updatePrice } from "../../../abi/firebasecode";
import { useEffect, useState } from "react";
import { NumberAbbreviation, getJokerPrice, getJokerSupply } from "../../../abi/CommonSolFunctions";
import { API_KEY, CHAIN_URL, JOKER_Token_ABI, JOKER_Token_Address, Network_Name } from "../../../abi/RefinedABI&ContractAddress";
import { ethers } from "ethers";

import jokerLogo from '../../../asserts/IMAGES/jokerRedLogo.svg';

const JockerCard =() =>{
   
  const provider = ethers.getDefaultProvider(Network_Name, {
    etherscan: API_KEY});
    const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);

    const [Oneyearvalue, setOneyearvalue] = useState(0);
    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);
    
    const [activeButton, setActiveButton] = useState('1Y');
    const [jokerPrice, setjokerPrice] = useState("");
    const [TotalSupply, setTotalSupply] = useState("");
    const [title, setTitle] = useState("price");

    // console.log("getMonthValues", Oneyearvalue);

    const getallvalues = async() =>{
      try{

      let oneyearvalue = await getPriceOneYear("JokerPrice")
        setOneyearvalue(oneyearvalue)
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        
        setOneyearprices(prices)

        setjokerPrice(await getJokerPrice())
        setTotalSupply(await JOKERContract.totalSupply())

      }catch(e){
        console.log(e);
    }
    
    }
    useEffect(() =>{getallvalues();},[])
    

    // Function to handle button click
    const handleButtonClick = async(value) => {
      setActiveButton(value);
      if(value == '1W'){
        let oneyearvalue = await getPriceOneWeek("JokerPrice")
        setfunction(oneyearvalue)
      }else if(value == '1M'){
        let oneyearvalue = await getPriceOneMonth("JokerPrice")
        setfunction(oneyearvalue)

      }else if(value == '1Y'){
        let oneyearvalue = await getPriceOneYear("JokerPrice")
        setfunction(oneyearvalue)
      }else{
        let onedayvalue = await getPriceOneDay("JokerPrice")
        setfunction(onedayvalue)
      }

    };
    const handleButtonClicksupply = async(value) => {
        setActiveButton(value);
        if(value == '1W'){
          let oneyearvalue = await getCirculatingsupplyOneWeek("JokerPrice")
          setfunction(oneyearvalue)
        }else if(value == '1M'){
          let oneyearvalue = await getCirculatingsupplyOneMonth("JokerPrice")
          setfunction(oneyearvalue)
  
        }else if(value == '1Y'){
          let oneyearvalue = await getCirculatingsupplyOneYear("JokerPrice")
          setfunction(oneyearvalue)
        }else{
          let oneyearvalue = await getCirculatingSupplyOneDay("JokerPrice")
          setfunction(oneyearvalue)
        }
  
      };

      const handleButtonClickMarketcap = async(value) => {
        setActiveButton(value);
        if(value == '1W'){
          let oneyearvalue = await getMarketcapOneWeek("JokerPrice")
          setfunction(oneyearvalue)
        }else if(value == '1M'){
          let oneyearvalue = await getMarketcapOneMonth("JokerPrice")
          setfunction(oneyearvalue)
  
        }else if(value == '1Y'){
          let oneyearvalue = await getMarketcapOneYear("JokerPrice")
          setfunction(oneyearvalue)
        }else{
          let oneyearvalue = await getMarketcapOneDay("JokerPrice")
          setfunction(oneyearvalue)
        }
  
      };

    const callselectedFunction = async(value) =>{
        console.log("selected function",title)
        if(title === "price"){
            handleButtonClick(value)
        }else if(title === "supply"){
            handleButtonClicksupply(value)

        }else if(title === "market"){
            handleButtonClickMarketcap(value)
        }

    }

    const setfunction = async(oneyearvalue) => {
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices(prices)
    }

    const OptionCall = async(titlevalue) =>{
        console.log("titlevalue", titlevalue)
        setTitle(titlevalue)
        setActiveButton('1Y')
        if(titlevalue === "price"){
            let oneyearvalue = await getPriceOneYear("JokerPrice")
            setOneyearvalue(oneyearvalue)
            const dates = Object.keys(oneyearvalue);
            setOneyeardates(dates)
            const prices = Object.values(oneyearvalue); 
            setOneyearprices(prices)

        }else if(titlevalue === "supply"){
            console.log("titlevalue",titlevalue)
            let oneyearvalue = await getCirculatingsupplyOneYear("JokerPrice")
            setOneyearvalue(oneyearvalue)
            const dates = Object.keys(oneyearvalue);
            setOneyeardates(dates)
            const prices = Object.values(oneyearvalue); 
            setOneyearprices(prices)
        }
        else if(titlevalue === "market"){
            let oneyearvalue = await getMarketcapOneYear("JokerPrice")
            setOneyearvalue(oneyearvalue)
            const dates = Object.keys(oneyearvalue);
            setOneyeardates(dates)
            const prices = Object.values(oneyearvalue); 
            setOneyearprices(prices)
        }
    }

    const pushPrice = async() =>{
      try{
      let jokerprice = await getJokerPrice();
      let circulatingSupply = await getJokerSupply();
      let currentTime = (new Date().getTime())/1000;
      await updatePrice((jokerprice/1e8),currentTime,(circulatingSupply/1e9));
    }catch(e){
      console.log(e);
  }
  
      // setOneyearvalue(oneyearvalue)
    }
    useEffect(() =>{pushPrice();},[])

    
    return ( 
        <div className="box">
            <div className="p-3 d-flex align-items-center flex-warp">
                {/* <h4 className="mb-0">Joker</h4> */}
                <img src={jokerLogo} height={'35px'}  style={{minWidth: '35px'}} alt="jokerLogo" />

                
                <div className="chart-filter d-flex align-items-center ms-auto">
                    {/* <button>15M</button>
                    <button>1H</button>
                    <button>8H</button> */}
                    {/* <button>1D</button>
                    <button>1W</button>
                    <button>1M</button> */}
                    {/* <button className="active">6M</button> */}
                    {/* <button>1Y</button> */}
                    {/* <button>ALL</button> */}

                    {/* <button
                    className={activeButton === '1D' ? 'active' : ''}
                    onClick={() => callselectedFunction('1D')} >
                    1D
                    </button> */}
                    <button
                    className={activeButton === '1W' ? 'active' : ''}
                    onClick={() => callselectedFunction('1W')} >
                    1W
                    </button>
                    <button
                    className={activeButton === '1M' ? 'active' : ''}
                    onClick={() => callselectedFunction('1M')} >
                    1M
                    </button>
                    <button
                    className={activeButton === '1Y' ? 'active' : ''}
                    onClick={() => callselectedFunction('1Y')} >
                    1Y
                    </button>
                </div>
            </div>

            <div className="chart-header d-flex align-items-center flex-wrap">
                <div>
                    <h6>Price</h6>
                    <h5>${jokerPrice?parseFloat(jokerPrice/1e8).toFixed(2):'0.0'}</h5>
                </div>
                <div>
                    <h6>Supply</h6>
                    <h5><NumberAbbreviation number={TotalSupply?parseFloat((TotalSupply)/1e9).toFixed(2):'0.0'}/></h5>
                </div>
                <div>
                    <h6>Market Cap</h6>
                    <h5>$<NumberAbbreviation number={jokerPrice?parseFloat((jokerPrice*TotalSupply)/1e17).toFixed(2):'0.0'}/></h5>
                </div>

                <div className="border-0">
                <Form.Select size="lg" onChange={(e) => OptionCall(e.target.value)}>
                    <option value="price">Price</option>
                    <option value="supply">CirculatingSupply</option>
                    <option value="market">Marketcap</option>
                </Form.Select>
                </div>
            </div>

            <div className="p-3">
                <AreaChart dates={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name={"JOKER"} checkvalue={title?title:"price"} />
            </div>
        </div>
     );
}

export default JockerCard;