import { Col, Row, Table } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Icon1 from "../../asserts/IMAGES/doller-icon.svg";
import daiLogo from '../../asserts/IMAGES/dai.svg';
import Icon2 from "../../asserts/IMAGES/jokerRedLogo.svg";
import Icon3 from "../../asserts/IMAGES/creditsBlackLogo.svg";
import Icon4 from "../../asserts/IMAGES/dimeCyanLogo.svg";
import AddIcon from "../../asserts/IMAGES/add-icon.svg";
import SwapIcon from "../../asserts/IMAGES/swap-btn.svg";
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import Tooltip from "../../Snippets/Tooltip";
import ChartIcon from "../../asserts/IMAGES/chatJokericon.svg";

import { useEffect,useState } from "react";

import { ConnectWallet, NumberAbbreviation, PendingModal, convertEpochToDateTime, convertFullEpochToDateTime, getTokenBalance } from '../../abi/CommonSolFunctions';
import { API_KEY, CREDITS_Chainlink_Oracle_Address, CREDITS_Token_ABI, CREDITS_Token_Address, Chainlink_Oracle_ABI, DAI_Chainlink_Oracle_Address, DAI_TOKEN_ABI, DAI_TOKEN_Address, DIME_Bond_Address, DIME_Chainlink_Oracle_Address, DIME_Token_ABI, DIME_Token_Address, JOKER_Chainlink_Oracle_Address, JOKER_Token_ABI, JOKER_Token_Address, Network_Name, Presale_DIME_CREDITS_ABI, Presale_DIME_CREDITS_Address, TXN_ID } from "../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
import Timer from "../Dashboard/Snippets/Timer";
import ProgressBar from "../Dashboard/Snippets/CircleTimer";
import { CircularProgressbar } from "react-circular-progressbar";
import { NumericFormat } from "react-number-format";

function MintCreditDime({balances, balanceOfTokens}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loadMint, setLoadMint] = useState(false);

    const handleShowMint = () => setLoadMint(true);
    const handleHideMint = () => setLoadMint(false);   

    const [daiAmount, setDaiAmount ] = useState("");
    const [DimeAmount, setDimeAmount] = useState("");
    const [CreditAmount, setCreditAmount] = useState("");
    const [JokerAmount, setJokerAmount] = useState("");

    const [secondsLeft,setSecondsLeft] = useState("");
    const [lockedTrue,setLockedTrue] = useState("");
    const [timePercentage, setTimePercentage] = useState(0);
    
    const[allowan,setAllowance] = useState("")
    const[allowan2,setAllowance2] = useState("")

    const[jokerPrice,setjokerPrice] = useState("")
    const[daiPrice,setdaiPrice] = useState("")
    const[creditsPrice,setcreditsPrice] = useState("")
    const[dimePrice,setdimePrice] = useState("")

    const [daiBalance, setDaiBalance] = useState("");
    const[JokerBalance, setJokerBalance] = useState("");
    const[dimeBalance, setDimeBalance] = useState("");
    const[creditsBalance, setCreditsBalance] = useState("");

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    const[RebaseEpoch, setRebaseEpoch] = useState("");
    const[TreasuryBalance,setTreasuryBalance] = useState(0);
    const[ExchangeJoker,setExchangeJoker] = useState("");
    const[ExchangecreditTokenMint,setExchangecreditTokenMint] = useState("");
    const[ExchangedimeTokenMint,setExchangedimeTokenMint] = useState("");
  
    

    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    useEffect(()=>{
        presaleDC_Calculation();
    },[allowan, allowan2, isConnected, address])

      const presaleDC_Calculation = async() =>{
        await recordUserVisits(address, "Genesis Minting");
       
            if(!isConnected){     
                // setDaiBalance("")  
                // setJokerBalance("")
                // setDimeBalance("")  
                // setCreditsBalance("") 
                // setSecondsLeft("");
                // setLockedTrue("");
                // setTimePercentage(0);
            }
            else{
                try{
                // const provider = new ethers.providers.Web3Provider(window.ethereum)
                
                // console.log("Connected Successfully", account);
    
                //new code
                const DimePriceContract = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
                const DaiPriceContract = new ethers.Contract(DAI_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
                const JokerPriceContract = new ethers.Contract(JOKER_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
                const CreditPriceContract = new ethers.Contract(CREDITS_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
                    
                const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
                const daiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
                const dimeContract = new ethers.Contract(DIME_Token_Address, DIME_Token_ABI, provider);
                const creditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
    
                let secondsleft = ethers.utils.formatUnits(await JOKERContract._secondsLeft(address),0);
                setSecondsLeft(await convertEpochToDateTime(secondsleft))
                setLockedTrue(secondsleft)
                setTimePercentage(parseInt((secondsleft / 86400) * 100));
                // const MintContractDC = new ethers.Contract(presaleDCMintProxy, presaleDCABI, provider);
                // const ECOReserveContract = new ethers.Contract(ECOReserveAddress, ECOReserveABI, provider);
    
                let daiprice = ethers.utils.formatUnits(await DaiPriceContract.getChainlinkDataFeedLatestAnswer(),0);
                let dimeprice = ethers.utils.formatUnits(await DimePriceContract.getChainlinkDataFeedLatestAnswer(),0);
                let Creditprice = ethers.utils.formatUnits(await CreditPriceContract.getChainlinkDataFeedLatestAnswer(),0);
                let Jokerprice = ethers.utils.formatUnits(await JokerPriceContract.getChainlinkDataFeedLatestAnswer(),0);
    
                setjokerPrice(Jokerprice);
                setdaiPrice(daiprice) 
                setdimePrice(dimeprice);
                setcreditsPrice(Creditprice);
    
                let daibalance = ethers.utils.formatUnits(await daiContract.balanceOf(address),0);
                setDaiBalance(daibalance)  
                let Jokerbalance = ethers.utils.formatUnits(await JOKERContract.balanceOf(address),0);
                setJokerBalance(Jokerbalance)
                let dimebalance = ethers.utils.formatUnits(await dimeContract.balanceOf(address),0);
                setDimeBalance(dimebalance)  
                let creditsbalance = ethers.utils.formatUnits(await creditsContract.balanceOf(address),0);
                setCreditsBalance(creditsbalance)  
    
                let allowance =  ethers.utils.formatUnits(await daiContract.allowance(address,Presale_DIME_CREDITS_Address),0);
                console.log("allowance1", allowance)
                setAllowance(allowance);
                let allowance2 =  ethers.utils.formatUnits(await JOKERContract.allowance(address,Presale_DIME_CREDITS_Address),0);
                console.log("allowance2", allowance2)
                setAllowance2(allowance2);
                const PresaleContract = new ethers.Contract(Presale_DIME_CREDITS_Address, Presale_DIME_CREDITS_ABI, provider);
                let RebaseTime  = ethers.utils.formatUnits(await PresaleContract.endTimestamp(),0);
                setRebaseEpoch(await convertFullEpochToDateTime(RebaseTime));

                let dai25 = (1 * 1e18) * 25 / 100;
                let calculatedValue = (dai25 * (daiprice) * (10 ** 9)) / (Jokerprice * (10 ** 18));
                setExchangeJoker((Math.abs(calculatedValue)));

                let Totaldollarvalue = ((1 * 1e18) * daiprice)  / 1e18;
                setExchangecreditTokenMint((Totaldollarvalue * 1e18 * 50) / (100 * Creditprice)); //24 - 6 = 18
                setExchangedimeTokenMint((Totaldollarvalue * 1e9 * 50) / (100 * dimeprice)); //24 - 15 = 9
    
                setTreasuryBalance(await getTokenBalance(Presale_DIME_CREDITS_Address,JOKER_Token_Address,JOKER_Token_ABI))
            }catch(e){
                console.log(e);
            }
            }
        
      }

      const calculateDimeCreditmint = async(value)=>{
        setDaiAmount(value)
        let dai25 = (value * 1e18) * 25 / 100;
        let calculatedValue = (dai25 * (daiPrice) * (10 ** 9)) / (jokerPrice * (10 ** 18));
        console.log("calculated",calculatedValue,Math.abs(calculatedValue));
        setJokerAmount((Math.abs(calculatedValue)));

        let Totaldollarvalue = ((value * 1e18) * daiPrice)  / 1e18;
        let creditTokenMint = ((Totaldollarvalue * 1e18 * 50) / (100 * creditsPrice)); //24 - 6 = 18
        let dimeTokenMint = ((Totaldollarvalue * 1e9 * 50) / (100 * dimePrice)); //24 - 15 = 9
        setCreditAmount(creditTokenMint);
        setDimeAmount(dimeTokenMint);
        await presaleDC_Calculation();
      }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
     }

    const mintDimeCredit = async() =>{
        // e.preventDefault();
        handleShowMint();
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner() 
            await showPopUp("yes",TransactionPending,"","");

            // Create contract instance with the correct order of arguments
            const MintContract = new ethers.Contract(Presale_DIME_CREDITS_Address, Presale_DIME_CREDITS_ABI, signer);
            const daiAmountBN = ethers.utils.parseUnits(daiAmount.toString(), 18);

            // Send the transaction and wait for it to be mined
            const mintTx = await MintContract.depositAndMint(daiAmountBN);
            await mintTx.wait();
            // await TransactionPost(address, Presale_DIME_CREDITS_Address, "PresaleDime&Credit", mintTx.hash);
            await TxnId_PopUp(mintTx.hash);
            await balanceOfTokens();
            await createTxn("Dime",mintTx.hash,"Genesis Minting",address,Presale_DIME_CREDITS_Address);
            await resetState();
        }catch(error){
            showPopUp("not",TransactionPending,"",(error.reason).toString());
        }

    }

    const approveDai = async () => {
        // e.preventDefault();
        handleShowMint();
        try {  
          const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
          const signer =  ethersProvider.getSigner() 
          await showPopUp("yes",TransactionPending,"","");

          // Create contract instance with the correct order of arguments
          const daiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, signer);
        
          // Convert daiAmount to BigNumber and multiply by 1e18
          const daiAmountBN = ethers.utils.parseUnits((548949849846516854849848651651468848451n).toString(), 0);
        
          const approveTx = await daiContract.approve(Presale_DIME_CREDITS_Address, daiAmountBN);
          await approveTx.wait();
          await TxnId_PopUp(approveTx.hash);
        
        } catch (error) {
            showPopUp("not",TransactionPending,"",(error.reason).toString());
        }
    };

    const approveJOKER = async() =>{
        // e.preventDefault();
        handleShowMint();
        try {    
          const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
          const signer =  ethersProvider.getSigner() 
          // Create contract instance with the correct order of arguments
          const jokerContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, signer);

          let jokerAmount = parseInt(JokerAmount);

          // Convert daiAmount to BigNumber and multiply by 1e9
          const jokerAmountBN = ethers.utils.parseUnits((548949849846516854849848651651468848451n).toString(), 0);
          await showPopUp("yes",TransactionPending,"","");
          const approveTx = await jokerContract.approve(Presale_DIME_CREDITS_Address, jokerAmountBN);
          await approveTx.wait();

          await TxnId_PopUp(approveTx.hash);
        } catch (error) {
            showPopUp("not",TransactionPending,"",(error.reason).toString());
        }
    }

    const daiMaxTau = () =>
    {
        setDaiAmount(daiBalance/1e18);
        calculateDimeCreditmint(daiBalance/1e18)
    }

    const canMintDimeAndCredit = parseFloat(allowan) >= parseFloat(daiAmount) * 1e18;
    const canApproveJoker = parseInt(allowan2) >= parseInt(JokerAmount);

    function formatValues (values, digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await presaleDC_Calculation();
    }

    const showPopUp = async(pendingvalue, imgSrc, txid, errormsg)=>{
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();   
    }

    const resetState = async() =>{
        setDaiAmount("");
        setDimeAmount("");
        setCreditAmount("");
        setJokerAmount("");
        await balanceOfTokens();
    }

    const connectWallet = async (e) => {
        e.preventDefault();
        await ConnectWallet();
    }

    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
                <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>
 <div className="p-24 table-box app-contain">
    {/* <center style={{ paddingBottom: '2rem' }}> <button className="btn btn-grad outline btn-sm "><span>Genesis Minting </span></button>
                    </center> */}
 <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>Collateral</th>
                            <th>Allocated to Mint</th>
                            <th> <img src={Icon3} width={35} alt="Transfer" /></th>
                            <th><img src={Icon4} width={35} alt="Transfer" /></th>
                            <th>Treasury Revenue </th>
                            <th>End Date </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={daiLogo} width={28} alt="Transfer" /> + <img src={Icon2} width={28} alt="Transfer" /> 
                                    
                                </div>
                            </td>
                            <td>$<NumberAbbreviation number={(1e6 ).toFixed(4)}/></td>
                            <td>${creditsPrice?(creditsPrice / 1e8).toFixed(2):'0.0'}</td>
                            <td> ${dimePrice?(dimePrice / 1e8).toFixed(2):'0.0'}</td>
                            <td> <center><NumberAbbreviation number={TreasuryBalance?(TreasuryBalance/1e9).toFixed(3):'0'} />  <img src={Icon2} width={28} alt="Transfer" /> </center> </td>
                            <td> {RebaseEpoch && <Timer count={ RebaseEpoch ? (RebaseEpoch) :'0'} />}
                            
                            </td>
                        </tr>
                    </tbody>
                </Table>
            {/* <div className="p-md-4 p-3">
                    <h2 className="mb-2">Genesis Minting</h2>
                    <h6 className="text-gray mb-0">Mint Credits & Dime</h6>
                </div> */}
                <div className="box p-3 px-md-4">
                <Row className="g-3 g-lg-4 mb-24">
                    <Col md={6}>
                        <div className="p-0">
                            <div className="swap-card py-2 d-flex align-items-center">
                                <div>
                                    <img src={daiLogo} alt="daiLogo" height={'42px'}/>
                                </div>
                                <div>
                                    <h5>DAI</h5>
                                    <h6>Bal: {parseFloat(daiBalance) ? formatValues(daiBalance/1e18, 2) : '0.00'}</h6>
                                </div>
                                <div className="flex-grow-1 py-2">
                                    <NumericFormat 
                                        value={daiAmount}
                                        disabled={!(lockedTrue <= 0)}
                                        placeholder="0.00" 
                                        className="input-reset" 
                                        type="number" 
                                        allowNegative={false}
                                        onChange={(e) => calculateDimeCreditmint(e.target.value)}
                                    />
                                </div>

                                {lockedTrue <= 0 ? <button className="btn btn-grad py-2" onClick={daiMaxTau}>Max</button> : <></>}
                            </div>
                            <div className="py-2 text-center">
                                <button type="button" className="btn-reset"><img src={AddIcon} alt="SwapBtn" /></button>
                            </div>
                            <div className="swap-card py-2 d-flex align-items-center">
                                <div>
                                    <img src={Icon2} alt="Icon1" height={'42px'}/>
                                </div>
                                <div>
                                    <h5>JOKER</h5>
                                    <h6>Bal: {parseFloat(JokerBalance) ? formatValues(JokerBalance/1e9, 2) : '0.00'}</h6>
                                </div>
                                <div className="flex-grow-1 py-2">
                                    <input readonly disabled placeholder="0.00" className="input-reset" value={parseFloat(JokerAmount) ? formatValues(JokerAmount/1e9, 9) : '0.00'}/>
                                </div>
                            </div>

                            <div className="py-2 text-center">
                                <button type="button" className="btn-reset"><img src={SwapIcon} alt="SwapBtn" /></button>
                            </div>
                            <div className="swap-card py-2 d-flex align-items-center">
                                <div>
                                    <img src={Icon3} alt="Icon1" height={'42px'}/>
                                </div>
                                <div>
                                    <h5>CREDITS</h5>
                                    <h6>Bal: {parseFloat(creditsBalance) ? formatValues(creditsBalance/1e18, 2) : '0.00'}</h6>
                                </div>
                                <div className="flex-grow-1 py-2">
                                    <input readonly disabled placeholder="0.00" className="input-reset" value={parseFloat(CreditAmount) ? formatValues(CreditAmount/1e18, 9) : '0.00'}/>
                                </div>
                            </div>
                            <div className="py-2 text-center">
                                <button type="button" className="btn-reset"><img src={AddIcon} alt="SwapBtn" /></button>
                            </div>
                            <div className="swap-card py-2 mb-3 d-flex align-items-center">
                                <div>
                                    <img src={Icon4} alt="Icon1" height={'42px'}/>
                                </div>
                                <div>
                                    <h5>DIME</h5>
                                    <h6>Bal: {parseFloat(dimeBalance) ? formatValues(dimeBalance/1e9, 2) : '0.00'}</h6>
                                </div>
                                <div className="flex-grow-1 py-2">
                                    <input readonly disabled placeholder="0.00" className="input-reset" value={parseFloat(DimeAmount) ? formatValues(DimeAmount/1e9, 9) : '0.00'}/>
                                </div>
                            </div>
                            {!address ? <>
                                <button className="btn  py-2 btn-grad w-100" onClick={connectWallet}>Connect Wallet</button>
                            </> : <>
                            {!daiAmount ? <>
                                <button
                                    disabled
                                    className='btn py-2 btn-grad w-100'
                                >
                                    {lockedTrue <= 0 ? 'Enter DAI Amount' : 'Joker Token Locked'}
                                </button>
                            </> : <>
                                <button
                                    className='btn py-2 btn-grad w-100'
                                    onClick={canMintDimeAndCredit ? canApproveJoker ? mintDimeCredit : approveJOKER : approveDai}
                                >
                                    {canMintDimeAndCredit
                                        ? canApproveJoker
                                          ? 'Mint DIME & CREDITS'
                                          : 'Approve JOKER'
                                        : 'Approve DAI'
                                    }
                                </button>
                            </>}
                            </>}
                        </div>
                    </Col>
                    <Col md={6}>
                   
                    
                        <div className="burn-card px-sm-3 py-3 box swap-value">
                        <div className=" d-flex align-items-center px-sm-4 px-2 py-3 border-bottom">
                        <div>
                            <h4 className="mb-3">Available CREDITS & DIME</h4>
                            <h5 className="text-gray"><span className="dot" style={{backgroundColor: '#20E2D0'}}></span> Total Minted</h5>
                            <h5 className="text-gray"><span className="dot" style={{backgroundColor: '#EE6A5F'}}></span> Available to Mint</h5>
                        </div>
                        <div className="ms-auto">
                            <div className="circle-timer circle-timer-pie">
                                <CircularProgressbar value='20' />
                                    <img src={ChartIcon} alt='icon' />    
                            </div>
                        </div>
                    {/* </div> */}
          
                            </div>
                            <div className="px-sm-4 px-2 py-3  border-bottom">
                                <h6>Exchange Rate</h6>
                                <h4>1 DAI + 
                                     <NumberAbbreviation number={ExchangeJoker/1e9}/> JOKER = 
                                     <NumberAbbreviation number={ExchangecreditTokenMint/1e18}/> CREDITS + 
                                     <NumberAbbreviation number={ExchangedimeTokenMint/1e9}/> DIME</h4>
                            </div>
                            <div className="px-sm-4 px-2 py-3  border-bottom">
                                <h6>Minting Fee</h6>
                                <h4>5% JOKER</h4>
                            </div>
                            <div className="px-sm-4 px-2 py-3 border-bottom">
                                <h6>You receive</h6>
                                <h4>
                                    {parseFloat(DimeAmount) ? formatValues(DimeAmount/1e9, 2) : '0.00'} DIME + 
                                    &nbsp;{parseFloat(CreditAmount) ? formatValues(CreditAmount/1e18, 2) :'0.00'} CREDITS
                                </h4>
                            </div>
                            {/* <div className="px-4"> */}
                            
                  
                            {/* <div className="py-3 text-center"> */}
                                {/* <h5 className="text-gray mb-0">How it works <Tooltip text="Lorem Ipsum Content." /></h5> */}
                            {/* </div> */}
                            {lockedTrue <= 0 ? (<>
                           
                        </>) : (<>
                            <div className="box swap-card mb-20 d-flex align-items-center p-2 p-md-4">
                            <div>
                                <h5 className="text-gray">Wallet to be unlocked in</h5>
                                <Timer count={secondsLeft ? secondsLeft : '0'} />
                            </div>
                            <div className="ms-auto">
                                <ProgressBar
                                    percentage={timePercentage}
                                    startColor="#20E2D0"
                                    endColor="#00C2FF"
                                    gradientId="genesisMint"
                                />
                            </div>

                            
                        </div>
                      </>)}
                        </div>
                   
                  
                   
                    </Col>
                </Row>
            </div>
  </div>
        </LayoutAfterLogin>

    );
}

export default MintCreditDime;