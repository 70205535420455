import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

const ChartComponent = ({ data }) => {
  const chartRef = useRef(null);
  let myChart = null;

  // Function to create the chart when data changes or component mounts
  const createChart = () => {
    if (chartRef.current && data) {
      myChart = new Chart(chartRef.current, {
        type: 'doughnut',
        data: {
          labels: data.labels,
          datasets: [
            {
              label: 'Balance',
              data: data.values,
              backgroundColor: data.colors,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'right',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  };

  // Function to destroy the chart when component unmounts
  const destroyChart = () => {
    if (myChart) {
      myChart.destroy();
      myChart = null;
    }
  };

  // Create chart on initial render and whenever data changes
  useEffect(() => {
    createChart();

    // Clean up chart on component unmount
    return () => {
      destroyChart();
    };
  }, [data]); // Re-run effect when data changes

  return (
    <div style={{ backgroundColor: 'var(--bg-black)', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
      <h2 style={{ color: 'white', textAlign: 'center', marginBottom: '20px' }}>JOKER Supply</h2>
      <div style={{ width: '100%', height: '35vh' }}>
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default ChartComponent;
