import firebase from "firebase";
const firebaseConfig = {
    apiKey: "AIzaSyA5KnZNH3y6157Srxwyn23Z_SehZYvRLt8",
    authDomain: "joker-8d83f.firebaseapp.com",
    databaseURL: "https://joker-8d83f-default-rtdb.firebaseio.com",
    projectId: "joker-8d83f",
    storageBucket: "joker-8d83f.appspot.com",
    messagingSenderId: "433170915380",
    appId: "1:433170915380:web:33836464d1f4f2434b2a61",
    measurementId: "G-LLV4FR8T0Y"
  };
const fireDb = firebase.initializeApp(firebaseConfig);
export default fireDb;  