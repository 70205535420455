import { Col, Row, Table } from "react-bootstrap";
import ChartIcon from '../../asserts/IMAGES/chatJokericon.svg';
import ProgressBar from "./Snippets/CircleTimer";
import TimerJoker from "./Snippets/TimerJoker";
import { CircularProgressbar } from "react-circular-progressbar";
import TransferIcon from '../../asserts/IMAGES/transfer-icon.svg';
import JokerIcon from '../../asserts/IMAGES/jokerRedLogo.svg'
import { retrieveTxnByPage, convertEpochToTimeAgo, getJokerContractDetails, getTimeLeftForJoker, getTokenBalance, getTokenTransactions, getTokenTransactionsLength, getDimePrice, getJokerPrice, getCreditsPrice } from "../../abi/CommonSolFunctions";
import { useEffect, useState } from "react";
import { Burn_Vault_Address, ECO_Reserve_Treasury_Address, JOKER_Token_ABI, JOKER_Token_Address, Zero_Address } from "../../abi/RefinedABI&ContractAddress";
import { recordUserVisits } from "../../abi/firebasecode";
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';

function Joker({ selectedTab ,balances}) {
    const { address, isConnected } = useWeb3ModalAccount();

    const [burnFees, setBurnFees] = useState(0);
    const [taxRevenue, setTaxRevenue] = useState(0);
    const [liquidityFees, setLiquidityFees] = useState(0);
    const [treasuryFees, setTreasuryFees] = useState(0);
    const [burnVaultBalance, setBurnVaultBalance] = useState(0);
    const [secondsLeftState, setSecondsLeftState] = useState("");
    const [secondsLeftInTextState, setSecondsLeftInTextState] = useState("");
    const [timePercentageState, setTimePercentageState] = useState("");
    const [totalJokerBurned, setTotalJokerBurned] = useState(0);
    const [jokerTransactions, setJokerTransactions] = useState([]);
    const [jokerTxLength, setJokerTxLength] = useState(0);

    const[jokerPrice,setjokerPrice] = useState(0);
    const[daiPrice,setdaiPrice] = useState("");
    const[dimePrice,setdimePrice] = useState("");
    const[tauPrice,settauPrice] = useState("");
    const[creditsPrice,setcreditsPrice] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const transactionsPerPage = 5;

    const feesFetch = async() => { 
       

        
        if(selectedTab === "TBS Collateral")
            await recordUserVisits(address, "Your Wallet Dashboard");
         try{
        let burnFeesFetch = await getJokerContractDetails('totalBurn');
        let taxRevenueFetch = await getJokerContractDetails('totalTax');
        let liquidityFeesFetch = await getJokerContractDetails('_getnumTokensSellToAddToLiquidity');
        let treasuryFeesFetch = await getTokenBalance(ECO_Reserve_Treasury_Address, JOKER_Token_Address, JOKER_Token_ABI);
        let burnVaultBalanceFetch = await getTokenBalance(Burn_Vault_Address, JOKER_Token_Address, JOKER_Token_ABI);
        let zeroAddressJokerBalanceFetch = await getTokenBalance(Zero_Address, JOKER_Token_Address, JOKER_Token_ABI);
        let totalJokerBurned = parseFloat(burnFeesFetch) + parseFloat(zeroAddressJokerBalanceFetch);
        if(isConnected)
        {
            let { secondsleft, secondsLeftInText, timePercentage } = await getTimeLeftForJoker(address);
            setSecondsLeftState(secondsleft);
            setSecondsLeftInTextState(secondsLeftInText);
            setTimePercentageState(timePercentage);
        }
        else{
            setSecondsLeftState("");
            setSecondsLeftInTextState("");
            setTimePercentageState("");    
        }

        setBurnFees(burnFeesFetch);
        setTaxRevenue(taxRevenueFetch);
        setLiquidityFees(liquidityFeesFetch);
        setTreasuryFees(treasuryFeesFetch);
        setBurnVaultBalance(burnVaultBalanceFetch);
        setTotalJokerBurned(totalJokerBurned);
        let jokerprice =await  getJokerPrice();
          setjokerPrice(jokerprice);
          let dimeprice = await getDimePrice();
          setdimePrice(dimeprice);
          let creditsprice = await getCreditsPrice();
          setcreditsPrice(creditsprice);
        }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        feesFetch();
    }, [isConnected, address, selectedTab])

    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const pagination = async (index) => {
        try{
            if(isConnected)
            {
                setCurrentPage(index);
                let txnData = await retrieveTxnByPage(address, transactionsPerPage, index);
                let jokerLength = await getTokenTransactionsLength(JOKER_Token_Address, address);
                setJokerTransactions(() => txnData);
                setJokerTxLength(jokerLength);
            }
            else
            {
                setCurrentPage(1);
                setJokerTransactions([]);
                setJokerTxLength(0); 
            }
        }catch(e){
            console.error(e);
        }
       
    }

    useEffect(() => {
        pagination(currentPage);
        setCurrentPage(1);
    }, [isConnected, address]);

    return ( 
        <div className="p-24 app-contain">
            <Row className="g-3 mb-24">
                <Col md={3} xs={6}>
                    {/* <div className="status-card">
                        <h5>Your Balance</h5>  
                        
             
                        <h6> {parseFloat(balances.joker).toFixed(2)} <img src={JokerIcon} height={'20px'} style={{minWidth: '20px'}} alt="Icon1" /> </h6>
                    </div> */}
                     <div className="status-card d-flex align-items-center">
                        <div>
                            <img src={JokerIcon} height={'42px'} style={{minWidth: '42px'}} alt="Icon1" />
                        </div>
  
                        
                        <div>
                            <h5>Balance</h5>
                            <h6>{parseFloat(balances.joker).toFixed(4)}</h6>
                        </div>
                        </div>
                </Col>
                <Col md={3} xs={6}>
                <div className="status-card d-flex align-items-center">
                        
  
                        
                        <div>
                            <h5>24Hours Lock</h5>
                            <h6>Transact {`>=`} {parseFloat((balances.joker)*0.0075).toFixed(4)}</h6>
                        </div>
                        </div>
                </Col>
                <Col md={3} xs={6}>
                <div className="status-card d-flex align-items-center">

                        <div>
                            <h5>12Hours Lock</h5>
                            <h6>Transact {`>=`} {parseFloat((balances.joker)*0.005).toFixed(4)}</h6>
                        </div>
                        </div>
                </Col>
                <Col md={3} xs={6}>
                    <div className="status-card">
                        <h5>No lock</h5>
                        <h6>Transact {`<=`} {parseFloat((balances.joker)*0.0049).toFixed(4)}</h6>
                    </div>
                </Col>
                {/* <Col md={3} xs={6}>
                    <div className="status-card">
                        <h5>Treasury Reserve</h5>
                        <h6>{formatValues(treasuryFees/1e9)} JOKER</h6>
                    </div>
                </Col> */}
            </Row>

            <Row className="g-3 mb-24">
                <Col md={6}>
                    <div className="box d-flex align-items-center p-3 p-md-4">
                        <div>                            
                            <h4 className="mb-3">{isConnected ? secondsLeftState > 0 ? 'Time until wallet unlocks' : 'Wallet access granted' : "Connect your wallet"}</h4>
                            {secondsLeftState > 0 ? <h5 className="text-gray">Next Seigniorage</h5> : <></>}
                          {secondsLeftInTextState && <TimerJoker count={secondsLeftInTextState? secondsLeftInTextState:'0'} phrase={'good'}/>}              
                        </div>
                        {isConnected ?
                        <div className="ms-auto">
                            <ProgressBar
                                percentage={parseInt(timePercentageState)}
                                startColor="#20E2D0"
                                endColor="#00C2FF"
                                gradientId="progress11"
                            />
                        </div> :
                        <div className="ms-auto" style={{margin: "51px"}}></div>
                    }
                        
                    </div>
                </Col>
                <Col md={6}>
                    <div className="box d-flex align-items-center p-3 p-md-4">
                        <div>
                            <h4 className="mb-3">Overall Token</h4>
                            <h6 className="text-gray"><span className="dot" style={{backgroundColor: '#20E2D0'}}></span> JOKER Balance: ${formatValues(parseFloat(balances.joker)*(jokerPrice/1e8),4)} </h6>
                            <h6 className="text-gray"><span className="dot" style={{backgroundColor: '#EE6A5F'}}></span> DIME + CREDITS Balance: ${formatValues(parseFloat(balances.dime)*(dimePrice/1e8)+parseFloat(balances.credits)*(creditsPrice/1e8),4)} </h6>
                        </div>
                        <div className="ms-auto">
                            <div className="circle-timer circle-timer-pie">
                            {/* {(((balances.joker*jokerPrice/1e8))/((balances.joker*jokerPrice/1e8) +((parseFloat(balances.dime)*dimePrice/1e8)+(parseFloat(balances.credits)*creditsPrice/1e8)) ))*100} */}
                                <CircularProgressbar value={(((balances.joker*jokerPrice/1e8))/((balances.joker*jokerPrice/1e8) +((parseFloat(balances.dime)*dimePrice/1e8)+(parseFloat(balances.credits)*creditsPrice/1e8)) ))*100}

 />
                                    <img src={JokerIcon} height={'42px'} style={{minWidth: '42px'}} alt='icon' />    
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <hr />

            <h3>Transaction History</h3>
            <div className="mb-4">
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th><div className="sort">Type</div></th>
                            <th><div className="sort">Txn Hash</div></th>
                            <th><div className="sort">Block</div></th>
                            <th><div className="sort">From</div></th>
                            <th><div className="sort">To</div></th>
                            <th><div className="sort float-end">Amount</div></th>
                            <th><div className="sort float-end">Created</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(jokerTransactions) && jokerTransactions.length ? (
                            jokerTransactions.map((txDetails, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {/* <img src={TransferIcon} alt="Transfer" /> */}
                                            <a href={`https://sepolia.etherscan.io/address/${txDetails.contractAddress}`} target="_blank" rel="noreferrer" type="button" className="btn-link">  <img src={JokerIcon}  alt="Icon1" /> Transfer</a>
                                        </div>
                                    </td>
                                    <td><a href={`https://sepolia.etherscan.io/tx/${txDetails.hash}`} target="_blank" rel="noreferrer" type="button" className="btn-link">{txDetails.hash ? `${txDetails.hash.substring(0, 4)}...${txDetails.hash.substring(txDetails.hash.length - 4)}` : ""}</a></td>
                                    <td><a href={`https://sepolia.etherscan.io/block/${txDetails.blockNumber}`} target="_blank" rel="noreferrer" type="button" className="btn-link">{txDetails.blockNumber}</a></td>
                                    <td><a href={`https://sepolia.etherscan.io/address/${txDetails.from}`} target="_blank" rel="noreferrer" type="button" className="btn-link">{txDetails.from ? `${txDetails.from.substring(0, 4)}...${txDetails.from.substring(txDetails.from.length - 4)}` : ""}</a></td>
                                    <td><a href={`https://sepolia.etherscan.io/address/${txDetails.to}`} target="_blank" rel="noreferrer" type="button" className="btn-link">{txDetails.to ? `${txDetails.to.substring(0, 4)}...${txDetails.to.substring(txDetails.to.length - 4)}` : ""}</a></td>
                                    <td className="text-end">{parseFloat(txDetails.value / 1e9).toFixed(4)}&nbsp;&nbsp;
                                    {/* <img src={JokerIcon} alt="Transfer"/> */}
                                    </td>
                                    <td className="text-end">{convertEpochToTimeAgo(txDetails.timeStamp)}</td>
                                </tr>
                            ))
                        ) :         
                            <tr>
                                <td colSpan="7" className="text-center"><h3 className="text-gray">Data not found</h3></td>
                            </tr>
                        }


                    </tbody>
                </Table>
            </div>
            
            <div className="d-flex justify-content-md-end justify-content-center">
                <ul className="paginate list-unstyled">
                    <li>
                        <button disabled={currentPage === 1} onClick={()=>pagination(currentPage - 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                            </svg>
                        </button>
                    </li>
                        <li>
                        <a className='active cursor-pointer' style={{ cursor: 'pointer' }}>
                            {currentPage}
                        </a>
                        </li>
                    <li>
                        <button disabled={currentPage >= ((jokerTxLength / 5) % 1 === 0 ? jokerTxLength / 5 : Math.ceil(jokerTxLength / 5))} onClick={() => pagination(currentPage + 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>

        </div>
     );
}

export default Joker;