import { Badge, Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import Preview from "../asserts/IMAGES/loginImage.jpg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg'
import bgBackgroundImage from '../asserts/IMAGES/top-bg.svg';
import { ConnectWallet } from "../abi/CommonSolFunctions";
import { AddWallet, checkWalletEligible } from "../abi/firebasecode";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";




function LogIn({connectWallet,setEligibile}) {
    const [eligible, seteligibile] = useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    
    const checkeliglibile = async() =>{
        let eligible1 = await connectWallet();
        setEligibile(eligible1)
        seteligibile(eligible1);
    }

   



    
    return ( 
        // <LayoutBeforeLogin>
        <Container fluid className="p-0" style={{ backgroundImage: `url(${bgBackgroundImage})`, backgroundSize: 'cover', minHeight: '100vh' }}>
            <div className="px-md-0 px-3">
        <Row className="app-blogin-row align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Col md={12}>
                <div className="app-blogin-inner">
                    <div className="d-md-none text-center mb-4">
                        <img src={LoginIcon} alt="logo icon" />
                    </div>
                    <div className="mb-4 text-center text-md-start">
                        <h1 className="text-28 text-600 mb-1">Check your Eligibility!</h1>
                        {/* <p className="text-500">Enter your registered email & password to log in.</p> */}
                    </div>

                    <Form>
                        {/* <FloatingLabel
                            controlId="floatingInput.Email"
                            label="Email"
                            className="mb-3"
                        >
                            <Form.Control type="email" placeholder=" " />
                        </FloatingLabel>
                        <InputGroup className="mb-3">
                            <FloatingLabel
                                controlId="floatingInput.Password"
                                label="Password"
                            >
                                <Form.Control
                                    type={show ? 'text' : "password"}
                                    placeholder=" "
                                />
                            </FloatingLabel>
                            <Button variant="reset" onClick={() => setShow(!show)} className="border-0" id="button-addon2">
                                {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputGroup>
                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            <Form.Check
                                type='checkbox'
                                id={`default-checkbox`}
                                label={`Keep me logged in`}
                            />
                            <Link className="btn-link" to="forgot-password">Forgot password?</Link>
                        </div> */}
                    {/* <button className="btn  py-2 btn-grad w-100" onClick={connectWallet}>Connect Wallet</button> */}
                        <Button  variant="grad" className="d-block w-100 mb-2" onClick={()=>checkeliglibile()} >{isConnected ? 'Connected':'Connect Wallet'}</Button>
                       <center> {!eligible &&  <Badge bg="danger" className="mb-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                    Your Wallet is not allowed to access this Website.
                </Badge>
                }
                </center>
                        
                        {/* <p className="px-md-5 px-4 text-center mb-3">By clicking on log in you agree with the divinedim’s terms of service & privacy guidelines</p> */}
                    </Form>
                </div>
            </Col>
        </Row>
        </div>
    </Container>
        // </LayoutBeforeLogin>
    )
}

export default LogIn;