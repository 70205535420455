import { Col, Row } from "react-bootstrap";
import Icon3 from '../../../asserts/IMAGES/creditsBlackLogo.svg';
import Tooltip from "../../../Snippets/Tooltip";
import TransactionPending from '../../../asserts/IMAGES/transsationPending.svg';
import { useState, useEffect } from "react";

import { CheckAllowance, PendingModal, SuccessModal, getGasBalance, getTokenBalance, convertEpochToDateTime, NumberAbbreviation } from "../../../abi/CommonSolFunctions";
import { CHAIN_URL, JOKER_Token_ABI, JOKER_Token_Address, TXN_ID,  Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, Carbonix_Finance_Address, Carbonix_Finance_ABI, DIME_Chainlink_Oracle_Address, DAI_Chainlink_Oracle_Address, DIME_Token_ABI, DIME_Token_Address, JOKER_Chainlink_Oracle_Address, CREDITS_Token_Address, CREDITS_Token_ABI, API_KEY, Network_Name } from "../../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, recordUserVisits } from "../../../abi/firebasecode";
import { NumericFormat } from "react-number-format";
import creditslogo from '../../../asserts/IMAGES/creditsBlackLogo.svg';
import DaiIcon from '../../../asserts/IMAGES/dai.svg';


function Borrow({ selectedTab, balanceOfTokens }) {

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
        
    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    const[allowan,setAllowance] = useState("")
    // const[allowan2,setAllowance2] = useState("")
    const[daiBalance,setdaiBalance] = useState("")
    const[creditsBalance,setCreditsBalance] = useState("");
    const[dimePrice,setdimePrice] = useState("")
    const[daiPrice,setdaiPrice] = useState("")
    const[bondPrice,setbondPrice] = useState("")

    const[borrowAmt, setborrowAmt] = useState(0.00);
    const[userDeposit,setUserDeposit] = useState(0);
    const[userDebt,setUserDebt] = useState(0);

    const getAssetDetails = async() =>{
        if(selectedTab === 'Borrow')
            await recordUserVisits(address, "Vault - Borrow");
    
        if(isConnected){
          console.log("useeffect borrow");
        try{
        const DimePriceContract = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
        const DaiPriceContract = new ethers.Contract(DAI_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
        const daiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
        const creditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
        const CarbonixContract = new ethers.Contract(Carbonix_Finance_Address, Carbonix_Finance_ABI, provider);

        let dimeprice = ethers.utils.formatUnits(await DimePriceContract.getChainlinkDataFeedLatestAnswer(),0);
        setdimePrice(dimeprice);
        let daiprice = ethers.utils.formatUnits(await DaiPriceContract.getChainlinkDataFeedLatestAnswer(),0);
        setdaiPrice(daiprice);

        let allowance =  ethers.utils.formatUnits(await daiContract.allowance(address,Carbonix_Finance_Address),0);
        console.log("allowance", allowance)
        setAllowance(allowance);

        let daibalance1 = ethers.utils.formatUnits(await daiContract.balanceOf(address),18);
          setdaiBalance(daibalance1);
          console.log("bal",daibalance1);
          let creditsbalance1 = ethers.utils.formatUnits(await creditsContract.balanceOf(address),0);
          setCreditsBalance(creditsbalance1)  
        const userDeposit = ethers.utils.formatUnits(await CarbonixContract.getCdpTotalDeposited(address),0);
        setUserDeposit(userDeposit);
        const userDebt = ethers.utils.formatUnits(await CarbonixContract.getCdpTotalDebt(address),0);
        setUserDebt(userDebt);
    }catch(error)
    {
        console.log(error);
    }
        }
        
      }

    const borrow = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const CarbonixContract = new ethers.Contract(Carbonix_Finance_Address, Carbonix_Finance_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            let givenAmount = ethers.utils.parseEther(borrowAmt.toString());
            console.log(ethers.utils.formatUnits(givenAmount._hex, 0));
            const borrowTx = await CarbonixContract.mint(givenAmount);
            await borrowTx.wait();
          
            console.log("minttx",borrowTx.hash);
            await getAssetDetails();
            await resetState();
            await TxnId_PopUp(borrowTx.hash);
            await balanceOfTokens();
            await createTxn("Credits",borrowTx.hash,"Borrow",address,Carbonix_Finance_Address);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        // await getAssetDetails();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const handlechange = (event) =>{ 
        setborrowAmt(event.target.value);
    }

    const handleInput = (value) => {
        setborrowAmt(((((userDeposit / 2) - userDebt) / 1e18)*value)/100);
        console.log(value);
    }

    const resetState = async() =>{
        setborrowAmt(0.00);
        await balanceOfTokens();
    }

    useEffect(()=>{getAssetDetails()},[address, isConnected, selectedTab]);
    let buttonDisableCondition = !parseFloat(userDeposit);
    return ( 
        <div className="p-24">
            <Row className="justify-content-center">
            <Col style={{maxWidth: '700px'}}>
                    <div className="box mb-20 p-3 p-md-4">
                        <div className="swap-card mb-20 d-flex align-items-center">
                            <div>
                                <img src={Icon3} height={'42px'} alt="Icon1" />
                            </div>
                            <div className="flex-grow-1 ms-2 ps-3 py-2">
                                <NumericFormat disabled={buttonDisableCondition} value={borrowAmt ? borrowAmt : ""} placeholder="0.00" className="input-reset" allowNegative={false} onChange={(e) => handlechange(e)}/>
                            </div>
                            
                            <button disabled={buttonDisableCondition} className="btn btn-grad py-2" onClick={borrow}>Borrow</button> 
                            
                        </div>

                        <Row className="mb-20 g-2">
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(25)}><span>25%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(50)}><span>50%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(75)}><span>75%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(100)}><span>100%</span></button>
                            </Col>
                        </Row>

                        <div className="mt-3 text-center  pb-2">
                            {buttonDisableCondition ? <h5 className="text-red mb-0">{isConnected?"Insufficient collateral for borrowing. Please deposit.":"Connect Your Wallet"}</h5> : <></>}
                        </div>

                        <div className="swap-card p-3">
                    <div className="box mb-2 p-3 p-md-4 border">
                    {/* <h4 className="text-center pb-1">DAI</h4> */}
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Your Wallet balance: </h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={daiBalance ? (parseFloat(daiBalance)).toFixed(4) : "0.0000"}/> &nbsp;<img src={DaiIcon} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Your collateral balance:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={userDeposit ? (userDeposit / 1e18).toFixed(4) : "0.0000"} /> &nbsp;<img src={DaiIcon} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                        <div className="flex mb-0 justify-content-between ">
                            <h5 className="mb-0 text-gray">Available to withdraw:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={(userDeposit && userDebt) ? ((userDeposit - userDebt) / 1e18).toFixed(4) : "0.0000"}/> &nbsp;<img src={DaiIcon} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                    </div>

                 
                    <div className="box mb-0 p-3 p-md-4 border" style={{width:'100%'}}>
                    {/* <h4 className="text-center pb-1">CREDITS</h4> */}
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray ">Borrowed:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={userDebt ? (userDebt / 1e18).toFixed(4) : "0.0000"}/> &nbsp;<img src={creditslogo} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Available to Borrow:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={(userDeposit && userDebt) ? (((userDeposit / 2) - userDebt) / 1e18).toFixed(4) : "0.0000"} /> &nbsp;<img src={creditslogo} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                        <div className="flex mb-0 justify-content-between">
                            <h5 className="mb-0 text-gray">Your Wallet Balance:</h5>
                            <h5 className="d-flex align-items-center"><NumberAbbreviation number={creditsBalance ? (creditsBalance / 1e18).toFixed(4) : "0.0000"} /> &nbsp;<img src={creditslogo} alt="Icon1" style={{ width: "25px", height: "25px" }}/></h5>
                        </div>
                    </div>
                    </div>
                    </div>

                   
                </Col>
            </Row>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>
        </div>
     );
}

export default Borrow;