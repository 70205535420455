import InfoIcon from '../asserts/IMAGES/infoIcon.svg'

function Tooltip({text, pos}) {
    return ( 
        <span className={`tooltip align-${pos}`}>
            <span>{text}</span>
            <img src={InfoIcon} alt='InfoIcon' />
        </span>
     );
}

export default Tooltip;