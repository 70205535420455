// import React from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Filler,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Filler,
//   Legend
// );

// export const options = {
//     responsive: true,
//     plugins: {
//         legend: {
//             display: false,
//             position: 'top',
//         },
//         title: {
//         display: false,
//         text: 'Chart.js Line Chart',
//         },
//     },
//     radius: 8,
//     interaction: {
//       intersect: false,
//     },
//     aspectRatio:3,
//     scales: {
//         x: {
//             ticks: {
//                 color: "#979797",
//                 // fontSize: 18,
//                 stepSize: 1,
//                 beginAtZero: true
//             },
//             display: true,
//             grid: {
//                 color: '#333333'
//             }
//         },
//         y: {
//             ticks: {
//                 color: "#979797",
//                 // fontSize: 18,
//                 stepSize: 1,
//                 beginAtZero: true
//             },
//             display: true,
//             grid: {
//                 color: '#333333'
//             }
//         }
//     }
// };

// // const labels = ['JAN 2023', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// // const canvas = document.getElementsByTagName('canvas')[0];
// // console.log(canvas);

// // const ctx = canvas?.getContext('2d');
// // var gradient = ctx?.createLinearGradient(0, 0, 0, 400);
// //     gradient?.addColorStop(0, 'rgba(250,174,50,1)');   
// //     gradient?.addColorStop(1, 'rgba(250,174,50,0)');

// export const data = {
//   // labels,
//   datasets: [
//     {
//       fill: true,
//       label: 'Joker',
//       data: [60,30,40,35,65,43,70,35,45,65,58,45],
//       borderColor: 'rgba(0, 194, 255, 0.5)',
//       backgroundColor: 'rgba(0, 194, 255, 0.5)',
//     },
//   ],
// };

// export function AreaChart({dates,prices,name}) {
// // console.log('AreaChart',dates,prices);
// // let labels = ['JAN 2023', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
// let labels = dates;
//   const data1 = {
//     labels,
//     datasets: [
//       {
//         fill: true,
//         label: name,
//         data: prices,
//         borderColor: 'rgba(0, 194, 255, 0.5)',
//         backgroundColor: 'rgba(0, 194, 255, 0.5)',
//       },
//     ],
//   };

//   // console.log("data",data1);
//   return <Line options={options} data={data1} />;
// }
import React from 'react';
import { Line } from 'react-chartjs-2';


function formatYAxisLabel(value,checkvalue) {
  console.log("check",checkvalue)
    const absValue = Math.abs(value);
    if (absValue >= 1e12) {
      return (value / 1e12).toFixed(1) + 'T';
    } else if (absValue >= 1e9) {
      return (value / 1e9).toFixed(1) + 'B';
    } else if (absValue >= 1e6) {
      return (value / 1e6).toFixed(1) + 'M';
    } else if (absValue >= 1e3) {
      return (value / 1e3).toFixed(1) + 'K';
    } else {
      return value.toFixed(1);
    }
  }

  function formatYAxisLabel1(value,checkvalue) {
    console.log("check",checkvalue)
      const absValue = Math.abs(value);
      if (absValue >= 1e12) {
        return (value / 1e12).toFixed(0) + 'T';
      } else if (absValue >= 1e9) {
        return (value / 1e9).toFixed(0) + 'B';
      } else if (absValue >= 1e6) {
        return (value / 1e6).toFixed(0) + 'M';
      } else if (absValue >= 1e3) {
        return (value / 1e3).toFixed(0) + 'K';
      } else {
        return value.toFixed(0);
      }
    }
export function AreaChart({ dates, prices, name ,checkvalue}) {
    const data = {
        labels: dates,
        datasets: [
            {
                fill: true,
                label: name,
                name: checkvalue,
                data: prices,
                borderColor: 'rgba(0, 194, 255, 0.5)',
                backgroundColor: 'rgba(0, 194, 255, 0.5)',
            },
        ],
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Chart.js Line Chart',
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.dataset.name === 'price') {
                label += formatPrice(context.parsed.y);
              } else if (context.dataset.name === 'supply') {
                label += formatSupply(context.parsed.y);
              } else {
                label += formatPrice(context.parsed.y);
              }
              return label;
            }
          }
        }
      },
      radius: window.innerWidth > 767 ? 8 : 3,
      interaction: {
        intersect: false,
      },
      aspectRatio: 3,
      scales: {
        x: {
          ticks: {
            color: "#979797",
            stepSize: 1,
            beginAtZero: true
          },
          display: true,
          grid: {
            color: '#333333'
          }
        },
        y: {
          ticks: {
            color: "#979797",
            stepSize: 1,
            beginAtZero: true,
            callback: function(value) {
              if (checkvalue === "supply") {
                return  formatYAxisLabel1(value);
              } else {
                return '$' +formatYAxisLabel(value);
              }
            }
          },
          display: true,
          grid: {
            color: '#333333'
          }
        }
      }
    };

    return <Line options={options} data={data} />;
}

// Function to format price data
const formatPrice = (value) => {
    return '$' + value.toFixed(2);
};

// Function to format supply data
const formatSupply = (value) => {
    if (value >= 1e6) {
        return (value / 1e6).toFixed(0) + 'M';
    } else {
        return value.toFixed(0);
    }
};

