import { Badge, Col, ProgressBar, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import BackIcon from '../../asserts/IMAGES/back-white-icon.svg'
import DollerLogo from '../../asserts/IMAGES/doller-icon.svg'
import InfoIcon from '../../asserts/IMAGES/infoIcon-blue.svg'
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import { Link, useLocation } from "react-router-dom";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { API_KEY, CHAIN_URL, DAI_TOKEN_ABI, Network_Name, STAKING_ABI, TXN_ID } from "../../abi/RefinedABI&ContractAddress";
import { PendingModal, getJokerPrice, getTokenBalance } from "../../abi/CommonSolFunctions";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
/* global BigInt */
function StakeReward({balances}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const location =  useLocation();

    const[stakedAmount,setStakedAmount] = useState("");
    const[RewardAmount,setRewardAmount] = useState("");
    const[MinRewardAmount,setMinRewardAmount] = useState("");
    const [jokerPrice,setJokerPrice] = useState("");
    const[TotalStakedValue,setTotalStakedValue] = useState("");

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    const getAllBalanceFunction = async() =>{
        try{
            if (location.state)
                await recordUserVisits(address, `${location.state.TokenName} Reward Claim`);
        if(isConnected){
            
            if (location.state){
        
                const StakingContract = new ethers.Contract(location.state.StakingContract, STAKING_ABI, provider);
                
                let userDeposited = await StakingContract.userInfo(address);
                console.log("userDeposited", ethers.utils.formatUnits((userDeposited.amount),0));
                setStakedAmount(ethers.utils.formatUnits((userDeposited.amount),0));

                let rewardvalue =ethers.utils.formatUnits( await StakingContract.pendingJoker(address),0);
                setRewardAmount(rewardvalue)

                let priceOracle = await getJokerPrice();
                setJokerPrice(priceOracle)

                let minrewardvalue =ethers.utils.formatUnits( await StakingContract.minRewardBalanceToClaim(),0);
                setMinRewardAmount(minrewardvalue)

                let totalStakedvalue = await getTokenBalance(location.state.StakingContract,location.state.depositTokenAddress,DAI_TOKEN_ABI)
                setTotalStakedValue(totalStakedvalue)
            }
        }
        }catch(error){
            console.error(error);
        }
    }
    useEffect(() =>{getAllBalanceFunction()},[isConnected,address]);

    const claim = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(location.state.StakingContract, STAKING_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const swaptxn = await JOKERContract.claimReward();
                
                await swaptxn.wait();
                await TxnId_PopUp(swaptxn.hash);
                await createTxn(location.state.TokenName,swaptxn.hash,"Claim Reward",address,location.state.StakingContract);
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAllBalanceFunction();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }


    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <div className="p-24 px-xl-5 app-contain">
                <div className="mb-3">
                    <Link className="flex g-2 mb-3 text-white" to='/staking/welcome' state={location.state}><img src={BackIcon} alt="icon" /> Back</Link>
                    <Row>
                        <Col md={6} xl={8}>
                            <h2 className="mb-2">Rewards Earned</h2>
                            {/* <h6 className="text-gray mb-0">Earn Rewards by Staking {location.state? location.state.TokenName :"Token"} tokens offers a rewards and claimable if it is greater than 10.</h6> */}
                        </Col>
                    </Row>
                </div>

                <div className="mb-3">
                    <Badge bg="blue">Earn Rewards by Staking {location.state? location.state.TokenName :"Token"} tokens offers a rewards and claimable if it is greater than 10 <img src={InfoIcon} alt="InfoIcon" /></Badge>
                </div>

                <Row className="g-3 mb-24">
                    <Col sm={4} xs={6}>
                        <div className="status-card">
                            <h6 className="flex mb-1 g-0">Claim Rewards </h6>
                            <h5 className="mb-0">${RewardAmount? parseFloat((RewardAmount*jokerPrice)/1e17).toFixed(7) : '0.0'}</h5>
                        </div>
                    </Col>
                    <Col sm={4} xs={6}>
                        <div className="status-card">
                            <h6 className="flex mb-1 g-0">Earning </h6>
                            <h5 className="mb-0">{RewardAmount ? parseFloat((RewardAmount/stakedAmount) * 100).toFixed(6) : '0.0'} &nbsp;%</h5>
                        </div>
                    </Col>
                    <Col sm={4} xs={6}>
                        <div className="status-card">
                            <h6 className="flex mb-1 g-0">Total Staked </h6>
                            <h5 className="mb-0">{stakedAmount ? parseFloat((stakedAmount/(10**location.state.decimalAmount))).toFixed(2) : '0.0'} &nbsp; {location.state? location.state.TokenName :"Token"} </h5>
                        </div>
                    </Col>
                </Row>

                <hr className="my-md-3 my-2" />
                <Row className="g-2 align-items-center">
                    <Col xs={6} md={3}>
                        <div className="flex g-2">
                            <img src={location.state? location.state.rewardToken :""} height="42px"alt="DollerLogo" />
                            <div>
                                <h5 className="mb-0">{location.state? location.state.rewardTokenName :""}</h5>
                                <h6 className="text-gray mb-0">Staking Rewards</h6>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={2}>
                        <h5 className="mb-0">{TotalStakedValue?parseFloat(((location.state.blocksperyear*365)/(TotalStakedValue/(10**location.state.decimalAmount)))*100).toFixed(3):'0.0'}%</h5>
                        <h6 className="text-gray mb-0">Est. APR</h6>
                    </Col>
                    <Col xs={12} md={3}>
                        <h6 className="text-gray text-600 mb-0"> {RewardAmount? Math.abs(parseFloat(MinRewardAmount) - parseFloat(RewardAmount))/parseFloat(location.state.rewardDecimal) : '0'}</h6>

                        <ProgressBar style={{height: '5px'}} variant="white" now={RewardAmount? (RewardAmount/MinRewardAmount)*100 : '0'} />
                        <div className="flex mt-1 justify-content-between">
                            {/* <h6 className="text-gray text-600 mb-0"> {RewardAmount? (RewardAmount/MinRewardAmount) : '0'}</h6> */}
                            {/* <h6 className="text-green-light text-600 mb-0">06D  17H  57M</h6> */}
                            <h6 className="text-gray text-600 mb-0">Remaining target amount to claim</h6>
                        <h6 className="text-green-light text-600 mb-0">{MinRewardAmount/parseFloat(location.state.rewardDecimal)}</h6>
                        </div>
                    </Col>
                    <Col xs={12} md={3} className="ms-auto">
                        <div className="flex g-2 justify-content-between">
                            <div className="text-end">
                                {/* {RewardAmount}:{MinRewardAmount} */}
                                <h5 className="mb-1 "> {RewardAmount? (RewardAmount/parseFloat(location.state.rewardDecimal)) : '0'}</h5>
                                {parseFloat(RewardAmount) >= parseFloat(MinRewardAmount) ? (<>
                                    <Badge bg="green-light">CLAIMABLE</Badge> 
                                </>):(<>
                                    <Badge bg="muted">CLAIMABLE</Badge> 
                                </>)}
                                {/* <Badge bg={(parseFloat(RewardAmount) >= parseFloat(MinRewardAmount)) ? "muted" : "green-light"}>CLAIMABLE</Badge> */}
                                {/* <Badge bg="green-light">CLAIMABLE</Badge> */}
                            </div>
                            <div>
                            {parseFloat(RewardAmount) >= parseFloat(MinRewardAmount) ? (<>
                                <button className="btn btn-grad px-md-4 px-3 py-md-2" onClick={()=>claim()}>Claim</button>
                            </>) : (<>
                                <button className="btn btn-grad px-md-4 px-3 py-md-2" disabled>Claim</button>
                            </>)}
                                
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr className="my-md-3 my-2" />

                {/* <Row className="g-3 mb-24">
                    <Col sm={4} xs={6}>
                        <div className="status-card">
                            <h6 className="flex mb-1 g-0">Claim Rewards <Tooltip pos="right" text="Texting" /></h6>
                            <h5 className="mb-0">$0.22</h5>
                        </div>
                    </Col>
                    <Col sm={4} xs={6}>
                        <div className="status-card">
                            <h6 className="flex mb-1 g-0">Earning <Tooltip pos="right" text="Texting" /></h6>
                            <h5 className="mb-0">12.51%</h5>
                        </div>
                    </Col>
                    <Col sm={4} xs={6}>
                        <div className="status-card">
                            <h6 className="flex mb-1 g-0">Lifetime Rewards <Tooltip pos="right" text="Texting" /></h6>
                            <h5 className="mb-0">$0.00</h5>
                        </div>
                    </Col>
                </Row>

                <hr className="my-md-3 my-2" />
                <Row className="g-2 align-items-center">
                    <Col xs={6} md={3}>
                        <div className="flex g-2">
                            <img src={DollerLogo} alt="DollerLogo" />
                            <div>
                                <h5 className="mb-0">Synthetix</h5>
                                <h6 className="text-gray mb-0">Staking Rewards</h6>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={2}>
                        <h5 className="mb-0">12.51%</h5>
                        <h6 className="text-gray mb-0">Est. APR</h6>
                    </Col>
                    <Col xs={12} md={3}>
                        <h6 className="text-gray text-600 mb-0">Repays debt automatically</h6>
                    </Col>
                    <Col xs={12} md={3} className="ms-auto">
                        <div className="flex g-2 justify-content-between">
                            <div className="text-end">
                                <h5 className="mb-1 ">0.05 SNX</h5>
                                <Badge bg="muted">Fees Burned</Badge>
                            </div>
                            <div>
                                <button className="btn btn-grad outline px-md-4 px-3 py-md-2"><span>Claim</span></button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr className="my-md-3 my-2" /> */}
            </div>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </LayoutAfterLogin>
    );
}

export default StakeReward;