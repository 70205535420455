import { ethers } from 'ethers';
import { CREDITS_Chainlink_Oracle_Address, CREDITS_LP_Staking_Address, CREDITS_LP_Token_Address, Chainlink_Oracle_ABI, DAI_Chainlink_Oracle_Address, DIME_Chainlink_Oracle_Address, DIME_LP_Staking_Address, DIME_LP_Token_Address, DIME_Token_Address, JOKER_Chainlink_Oracle_Address, JOKER_Staking_Address, JOKER_Token_Address, JOKER_Token_ABI, TAU_Chainlink_Oracle_Address, STAKING_ABI, DIME_Token_ABI, CREDITS_Token_Address, CREDITS_Token_ABI, TAU_Token_Address, TAU_Token_ABI, DAI_TOKEN_Address, DAI_TOKEN_ABI, Network_Name, API_KEY, pancakeOracleAddress, pancakeOracle, pancakeOracleABI, ADSB_1_Address, ADSB_1_ABI, ECO_Reserve_Treasury_Address, ADSB_2_Address, ADSB2_ABI } from './RefinedABI&ContractAddress';
import { useWeb3ModalAccount, useWeb3ModalProvider, useWeb3Modal } from '@web3modal/ethers5/react';
import { Modal, Spinner } from 'react-bootstrap';
import ArrowLink from '../asserts/IMAGES/arrowLink.svg';

import numeral from 'numeral';
// import TranssationFailed from '../asserts/IMAGES/transsationFailed.svg'
import DangerIcon from '../asserts/IMAGES/DangerIcon.svg'
import Transactionsuccess from '../asserts/IMAGES/Check-icon.svg'
export const config = {
  "chainName": "Sepolia",
  "chainId": 11155111,
  "chainIdHex": "0xaa36a7",
  "rpcUrl": "https://eth-sepolia-public.unifra.io",
  "explorerUrl": "https://sepolia.etherscan.io/"
}

export const provider = ethers.getDefaultProvider(Network_Name, {
  etherscan: API_KEY});

// export const provider = new ethers.providers.JsonRpcProvider(url);
// Function to get token balance of given user or address
export const getTokenBalance = async(userAddress, tokenAddress, tokenAbi) => {
    try {
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);
        const balance = await tokenContract.balanceOf(userAddress);
        // const balancetx = await balance.wait();

        // Convert balance to a readable format (e.g., Ether or token units)
        const readableBalance = ethers.utils.formatUnits(balance, 0); // Assuming token has 18 decimals
        console.log(readableBalance);

        return readableBalance;
    } catch (error) {
        console.error("Error fetching token balance:", error);
        return 0; // Return NaN in case of error
    }
}

//Function to get chain gas balance of given address
export const getGasBalance = async(userAddress) => {
    try {
        

        const balance = await provider.getBalance(userAddress);
        
        // Convert balance to a readable format (e.g., Ether or token units)
        const readableBalance = ethers.utils.formatUnits(balance, 0); // Assuming token has 18 decimals

        return readableBalance;
    } catch (error) {
        console.error("Error fetching balance:", error);
        return 0; // Return NaN in case of error
    }
}

export const connectToEthereum = async () => {
    try {
      if (window.ethereum) {
        let k = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log("K",k)
        
        const web3= new ethers.providers.Web3Provider(window.ethereum);
        return web3;
      } else {
        throw new Error('No Ethereum wallet found.');
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

//---------------------------Connect Wallet--------------------------------------

export const ConnectWallet = async () => {
  const { open } = useWeb3Modal();
  await open();
};

export const ChangeNetwork = async ({walletProvider}) => {
  try {
    await walletProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: config.chainIdHex }],
    });
  } catch (switchError) {
    if (switchError.code === config.chainId) {
      try {
        await walletProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: config.chainIdHex,
              chainName: config.chainName,
              rpcUrls: [config.rpcUrl],
              blockExplorerUrls: [config.explorerUrl],
            },
          ],
        });
      } catch (addError) {
        throw addError;
      }
    }
  }
};

//---------------------------Connect Wallet--------------------------------------

//----------------------------Check allowance------------------------------------

export const CheckAllowance = async (tokenAddress, tokenAbi, walletAddress, addressToAllowed) => {
  try {
    
    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);   
    const allowance = ethers.utils.formatUnits(await tokenContract.allowance(walletAddress, addressToAllowed),0);
    return allowance;
  }catch (error) {
    console.error(error);
    return 0;
  }
}

//----------------------------Check allowance--------------------------------------

//----------------------------Transaction pending Modal--------------------------------------

export const PendingModal = ({pending,showTr,handleCloseTransation,imgSrc,txId,errormsg})=>{
  const handleClick = () => {
    // Assuming txId is set dynamically before the click
    window.open(txId, '_blank'); // Open the link in a new tab
  };
  if(pending === "yes"){
    return(
      <Modal show={showTr} centered onHide={handleCloseTransation}>
        <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
          <div className='mb-3 popup-icon'>
                  <svg id="L5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
                    <circle fill="#F5C451" stroke="none" cx="30" cy="50" r="7">
                      <animateTransform 
                        attributeName="transform" 
                        dur="1s" 
                        type="translate" 
                        values="0 15 ; 0 -15; 0 15" 
                        repeatCount="indefinite" 
                        begin="0.1"/>
                    </circle>
                    <circle fill="#F5C451" stroke="none" cx="50" cy="50" r="7">
                      <animateTransform 
                        attributeName="transform" 
                        dur="1s" 
                        type="translate" 
                        values="0 10 ; 0 -10; 0 10" 
                        repeatCount="indefinite" 
                        begin="0.2"/>
                    </circle>
                    <circle fill="#F5C451" stroke="none" cx="70" cy="50" r="7">
                      <animateTransform 
                        attributeName="transform" 
                        dur="1s" 
                        type="translate" 
                        values="0 5 ; 0 -5; 0 5" 
                        repeatCount="indefinite" 
                        begin="0.3"/>
                    </circle>
                  </svg>
                </div>
            <h3 className='mb-1'>Transaction Pending</h3>
            <p className='mb-4'><small>Follow the Metamask prompts to authorize the transaction.</small></p>
            <button className='btn btn-black border-0 flex justify-content-center  w-100 g-2 mb-3'><Spinner size='sm' animation="border" variant="blue " /> <span className='text-grad'>Loading</span></button>
            {/* <button className="btn-link" onClick={handleCloseTransation}><span className='text-grad'>View Etherscan</span> <img src={ArrowLink} className='ms-2' alt="arrowLink" /></button>     */}
          </div>
    
        </Modal.Body>
      </Modal>
      )
  }else if(pending === "no"){
    return(
      <Modal show={showTr} centered onHide={handleCloseTransation}>
        <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Transactionsuccess} width={80} className="mb-3" alt="JokerIcon" />
            <h3 className='mb-1'>Transaction Success</h3>
            {/* <p className='mb-4'><small>Follow the Metamask prompts to authorize the transaction.</small></p> */}
            {/* <button className='btn btn-black border-0 flex justify-content-center  w-100 g-2 mb-3'><Spinner size='sm' animation="border" variant="blue " /> <span className='text-grad'>Loading</span></button> */}
            <button className="btn-link" onClick={()=>handleClick()} ><span className='text-grad'>View in Explorer</span> <img src={ArrowLink} href={txId} target="_blank" rel="noreferrer" className='ms-2' alt="arrowLink" /></button>    
          </div>
    
        </Modal.Body>
      </Modal>
      )
  }
  else if(pending === "noo"){
    return(
      <Modal show={showTr} centered onHide={handleCloseTransation}>
        <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={Transactionsuccess} width={80} className="mb-3" alt="JokerIcon" />
            <h3 className='mb-1'>Added Successfully</h3>
            {/* <p className='mb-4'><small>Follow the Metamask prompts to authorize the transaction.</small></p> */}
            {/* <button className='btn btn-black border-0 flex justify-content-center  w-100 g-2 mb-3'><Spinner size='sm' animation="border" variant="blue " /> <span className='text-grad'>Loading</span></button> */}
            {/* <button className="btn-link" onClick={()=>handleClick()} ><span className='text-grad'>View in Explorer</span> <img src={ArrowLink} href={txId} target="_blank" rel="noreferrer" className='ms-2' alt="arrowLink" /></button>     */}
          </div>
    
        </Modal.Body>
      </Modal>
      )
  }
  else{
    return(
      <Modal show={showTr} centered onHide={handleCloseTransation}>
    <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="text-center">
        <img src={DangerIcon} width={80} className="mb-3" alt="JokerIcon" />
        <h3 className='mb-1'>Transaction Failed</h3>
        <p className='mb-4'><small>{errormsg}</small></p>
        {/* <button className='btn btn-black border-0 flex justify-content-center  w-100 g-2 mb-3'><Spinner size='sm' animation="border" variant="blue " /> <span className='text-grad'>Loading</span></button> */}
        {/* <button className="btn-link" onClick={handleCloseTransation}><span className='text-grad'>View Etherscan</span> <img src={ArrowLink} href={txId} target="_blank" rel="noreferrer" className='ms-2' alt="arrowLink" /></button>     */}
      </div>

    </Modal.Body>
  </Modal>
    )
    
  }
  
  
}

export const SuccessModal = ({showTr,handleCloseTransation,imgSrc,txId})=>{
  
  
}

//----------------------------Transaction pending Modal--------------------------------------

//----------------------------Epoch to date and Time converter------------------------------------

export const convertEpochToDateTime = async(secondsLeft) => {
  const now = new Date(); // Current date and time
  const targetEpoch = now.getTime() + secondsLeft * 1000; // Calculate target epoch time

  const date = new Date(targetEpoch); // Convert target epoch time to Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};


export const convertFullEpochToDateTime = async(correctTime) => {
  // const now = new Date(); // Current date and time
  // const targetEpoch = now.getTime() + secondsLeft * 1000; // Calculate target epoch time

  const date = new Date(correctTime*1000); // Convert target epoch time to Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};


//----------------------------epoch to date and time converter--------------------------------

//------------------------------Price fetching----------------------------------

export const getJokerPrice = async() => {
  try {
     

      const tokenContract = new ethers.Contract(JOKER_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
      const Price = await tokenContract.getChainlinkDataFeedLatestAnswer();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readablePrice = ethers.utils.formatUnits(Price, 0); // Assuming token has 18 decimals
      console.log(readablePrice);

      return readablePrice;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
export const getDimePrice = async() => {
  try {
  

      const tokenContract = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
      const Price = await tokenContract.getChainlinkDataFeedLatestAnswer();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readablePrice = ethers.utils.formatUnits(Price, 0); // Assuming token has 18 decimals
      console.log(readablePrice);

      return readablePrice;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
export const getCreditsPrice = async() => {
  try {
      

      const tokenContract = new ethers.Contract(CREDITS_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
      const Price = await tokenContract.getChainlinkDataFeedLatestAnswer();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readablePrice = ethers.utils.formatUnits(Price, 0); // Assuming token has 18 decimals
      console.log(readablePrice);

      return readablePrice;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
export const getDAIPrice = async() => {
  try {

      const tokenContract = new ethers.Contract(DAI_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
      const Price = await tokenContract.getChainlinkDataFeedLatestAnswer();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readablePrice = ethers.utils.formatUnits(Price, 0); // Assuming token has 18 decimals
      console.log(readablePrice);

      return readablePrice;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
export const getTAUPrice = async() => {
  try {
     
      const tokenContract = new ethers.Contract(TAU_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
      const Price = await tokenContract.getChainlinkDataFeedLatestAnswer();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readablePrice = ethers.utils.formatUnits(Price, 0); // Assuming token has 18 decimals
      console.log(readablePrice);

      return readablePrice;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
//------------------------------Price fetching--------------------------------


//------------------------------Supply fetching--------------------------------
export const getJokerSupply = async() => {
    try {
       
        const tokenContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
        const Supply = await tokenContract.totalSupply();
        // const balancetx = await balance.wait();

        // Convert balance to a readable format (e.g., Ether or token units)
        const readableSupply = ethers.utils.formatUnits(Supply, 0); // Assuming token has 18 decimals
        console.log(readableSupply);

        return readableSupply;
    } catch (error) {
        console.error("Error fetching token balance:", error);
        return 0; // Return NaN in case of error
    }
}

export const getDimeSupply = async() => {
  try {
     
      const tokenContract = new ethers.Contract(DIME_Token_Address, DIME_Token_ABI, provider);
      const Supply = await tokenContract.totalSupply();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableSupply = ethers.utils.formatUnits(Supply, 0); // Assuming token has 18 decimals
      console.log(readableSupply);

      return readableSupply;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}

export const getCreditsSupply = async() => {
  try {
     
      const tokenContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
      const Supply = await tokenContract.totalSupply();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableSupply = ethers.utils.formatUnits(Supply, 0); // Assuming token has 18 decimals
      console.log(readableSupply);

      return readableSupply;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}

export const getTauSupply = async() => {
  try {
      
      const tokenContract = new ethers.Contract(TAU_Token_Address, TAU_Token_ABI, provider);
      const Supply = await tokenContract.totalSupply();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableSupply = ethers.utils.formatUnits(Supply, 0); // Assuming token has 18 decimals
      console.log(readableSupply);

      return readableSupply;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}

export const getDaiSupply = async() => {
  try {
      

      const tokenContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
      const Supply = await tokenContract.totalSupply();
      // const balancetx = await balance.wait();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableSupply = ethers.utils.formatUnits(Supply, 0); // Assuming token has 18 decimals
      console.log(readableSupply);

      return readableSupply;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
//------------------------------Supply fetching--------------------------------





//------------------------------Joker contract fetches--------------------------------
export const getJokerContractDetails = async(functionName) => {
  try {
    
      const tokenContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
      const value = await tokenContract[functionName]();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableValue = ethers.utils.formatUnits(value, 0); // Assuming token has 18 decimals
      console.log(readableValue);

      return readableValue;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
export const getDimeContractDetails = async(functionName) => {
  try {
    
      const tokenContract = new ethers.Contract(DIME_Token_Address, DIME_Token_ABI, provider);
      const value = await tokenContract[functionName]();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableValue = ethers.utils.formatUnits(value, 0); // Assuming token has 18 decimals
      console.log(readableValue);

      return readableValue;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
//------------------------------Joker contract fetches--------------------------------

//------------------------------Joker contract Time Fetch--------------------------------

export const getTimeLeftForJoker = async(userAddress) => {
  try {
     
      const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);

      let secondsleft = ethers.utils.formatUnits(await JOKERContract._secondsLeft(userAddress), 0);
      let secondsLeftInText = await convertEpochToDateTime(secondsleft);
      let timePercentage = (parseInt((secondsleft / 86400) * 100));

      return {secondsleft, secondsLeftInText, timePercentage};
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
  }
}

//------------------------------Joker contract Time Fetch--------------------------------

//------------------------------Token Transactions fetch--------------------------------
export const getTokenTransactions = async(tokenContractAddress, walletAddress, limit, pageNumber) => {
  try {
      const apiKey = "EFI94DQAT8AX3RAWG998TS5S3725PXB226";

      const networkMainnet = 'api';
      const networkSepolia = 'api-sepolia';

      const url = `https://${networkSepolia}.etherscan.io/api?module=account&action=tokentx&contractaddress=${tokenContractAddress}&address=${walletAddress}&startblock=0&endblock=999999999&sort=desc&apikey=${apiKey}&offset=${limit}&page=${pageNumber}`;

      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return false;
      }
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
  }
}
//------------------------------Token Transactions fetch--------------------------------

//------------------------------Token Transactions Length fetch--------------------------------
export const getTokenTransactionsLength = async(tokenContractAddress, walletAddress) => {
  try {
      const apiKey = "EFI94DQAT8AX3RAWG998TS5S3725PXB226";

      const networkMainnet = 'api';
      const networkSepolia = 'api-sepolia';

      const url = `https://${networkSepolia}.etherscan.io/api?module=account&action=tokentx&contractaddress=${tokenContractAddress}&address=${walletAddress}&startblock=0&endblock=999999999&sort=asc&apikey=${apiKey}`;

      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        return data.result.length;
      } else {
        return false;
      }
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
  }
}
//------------------------------Token Transactions fetch--------------------------------

//------------------------------Epoch to time string ago format----------------------------------

export const convertEpochToTimeAgo = (epoch) => {
  const milliseconds = new Date().getTime() - epoch * 1000;
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}

//------------------------------Epoch to time string ago format----------------------------------
//------------------------------Joker Staking contract fetches--------------------------------
export const getJokerStakingContractDetails = async(functionName) => {
  try {
     
      const tokenContract = new ethers.Contract(JOKER_Staking_Address, STAKING_ABI, provider);
      const value = await tokenContract[functionName]();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableValue = ethers.utils.formatUnits(value, 0); // Assuming token has 18 decimals
      console.log(readableValue);

      return readableValue;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
//------------------------------Joker contract fetches--------------------------------
//------------------------------Joker Staking contract fetches--------------------------------
export const getDIMEStakingContractDetails = async(functionName) => {
  try {
      
      
      const tokenContract = new ethers.Contract(DIME_LP_Staking_Address, STAKING_ABI, provider);
      const value = await tokenContract[functionName]();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableValue = ethers.utils.formatUnits(value, 0); // Assuming token has 18 decimals
      console.log(readableValue);

      return readableValue;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
//------------------------------Joker contract fetches--------------------------------
//------------------------------Joker Staking contract fetches--------------------------------
export const getCreditsStakingContractDetails = async(functionName) => {
  try {
      

      const tokenContract = new ethers.Contract(CREDITS_LP_Staking_Address, STAKING_ABI, provider);
      const value = await tokenContract[functionName]();

      // Convert balance to a readable format (e.g., Ether or token units)
      const readableValue = ethers.utils.formatUnits(value, 0); // Assuming token has 18 decimals
      console.log(readableValue);

      return readableValue;
  } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0; // Return NaN in case of error
  }
}
//------------------------------Joker contract fetches--------------------------------

//------------------------------Joker dashboard txn fetches--------------------------------
export const retrieveTxnByPage = async (address, transactionsPerPage, pageNumber) => {
      let JokerTransaction = await getTokenTransactions(JOKER_Token_Address, address, transactionsPerPage, pageNumber);
      return JokerTransaction.result;
}
//------------------------------Joker dashboard txn fetches--------------------------------


export const NumberAbbreviation = ({ number }) => {
  const formattedNumber = numeral(number).format('0.00a');
  return <span>{formattedNumber.toUpperCase()}</span>;
};

//------------------------------Price fetched from pancakeswap oracle-----------------------

export const fetchPrice = async () => {
  try {
    // const url = "https://base-sepolia-rpc.publicnode.com";
    // const provider = new ethers.providers.JsonRpcProvider(url);
    
    const oracleContract = new ethers.Contract(pancakeOracleAddress, pancakeOracleABI, provider);   
    const jokerprice = ethers.utils.formatUnits(await oracleContract.getJokerPrice(), 0);
    const dimeprice = ethers.utils.formatUnits(await oracleContract.getDimePrice(), 0);
    const creditsPrice = ethers.utils.formatUnits(await oracleContract.getCreditsPrice(), 0);
    return [jokerprice, dimeprice, creditsPrice];
  } catch (error) {
    console.error(error);
    return [0, 0, 0]; // Returning an array of zeros to match the expected structure
  }
};

export const DecimalPointer = ({ number }) =>{
  try{
    let resultString = number.toString();
    
    // Find the index of the decimal point
    let decimalIndex = resultString.indexOf('.');

    // Handle cases where there might be no decimal point (e.g., an integer result)
    if (decimalIndex === -1) {
        // If there's no decimal point, add '.000'
        return <span>{resultString + '.00'}</span>;
    } else {
        // Ensure there are at least three decimal places
        if (resultString.length < decimalIndex + 4) {
            // If the string length is less than needed for three decimal places, append zeros
            resultString += '0'.repeat(decimalIndex + 4 - resultString.length);
        }
        // Extract up to three decimal places
        return <span>{resultString.substring(0, decimalIndex + 3)}</span>;
    }
   
  }catch(err){
    
    return <span>0</span>
  }
 
  
}

export const fetchDimeMarketcap = async () => {
  try {
    // const url = "https://base-sepolia-rpc.publicnode.com";
    // const provider = new ethers.providers.JsonRpcProvider(url);
    
    const equilibriumContract = new ethers.Contract(ADSB_1_Address, ADSB_1_ABI, provider);   
    const dimeMarketCap = ethers.utils.formatUnits(await equilibriumContract.getDimeMarketcap(), 0);
    
    return dimeMarketCap;
  } catch (error) {
    console.error(error);
    return 0; // Returning an array of zeros to match the expected structure
  }
};

export const fetchjokerMarketcap = async () => {
  try {
    // const url = "https://base-sepolia-rpc.publicnode.com";
    // const provider = new ethers.providers.JsonRpcProvider(url);
    
    const equilibriumContract = new ethers.Contract(ADSB_1_Address, ADSB_1_ABI, provider);   
    const dimeMarketCap = ethers.utils.formatUnits(await equilibriumContract.getJokerMarketcap(), 0);
    
    return dimeMarketCap;
  } catch (error) {
    console.error(error);
    return 0; // Returning an array of zeros to match the expected structure
  }
};

export const fetchECOReservebalance = async(address,abi) =>{
  try{
    const Contract = new ethers.Contract(address, abi, provider);   
    const contractBalance = ethers.utils.formatUnits(await Contract.balanceOf(ECO_Reserve_Treasury_Address), 0); 
    return contractBalance;
  }catch(err){
    console.log("error",err);
    return 0;
    
  }
}

export const fetchDsPrinciple = async() =>{
  try{
    const Contract = new ethers.Contract(ADSB_2_Address, ADSB2_ABI, provider);   
    const contractBalance = ethers.utils.formatUnits(await Contract.findNextEquilibirium(), 0); 
    return contractBalance;
  }catch(err){
    console.log("error",err);
    return 0;
    
  }
}