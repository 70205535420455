import { Badge, Col, ProgressBar, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import StackIcon from '../../asserts/IMAGES/stack-icon.svg'
import UnstackIcon from '../../asserts/IMAGES/unstack-icon.svg'
import RewardExplainedIcon from '../../asserts/IMAGES/reward-explained-icon.svg'
import SnxIcon from '../../asserts/IMAGES/snx-icon.svg'
import HelpIcon from '../../asserts/IMAGES/help-icon.svg'
import AdjustWeeklyRewards from '../../asserts/IMAGES/adjust-weekly-rewards.svg'
import ArrowDown from '../../asserts/IMAGES/arrowDown.svg'
import Tooltip from "../../Snippets/Tooltip";

function WithdrawUnlocked({balances}) {
    return (  
        <LayoutAfterLogin menuActive={"carbonix"} balances={balances}>
            <div className="p-24 app-contain">
                <Row className="g-4 g-md-3">
                    <Col md={9}>
                        <div className="box mb-20 d-flex flex-column p-3">
                            <Row className="g-3 align-items-end mb-4">
                                <Col>
                                    <h2 className="mb-2">Collateralization Ratio Health</h2>
                                    <h6 className="text-gray mb-0">In order to claim weekly rewards, your C-Ratio must be at or above the target.</h6>
                                </Col>
                                <Col md={'auto'}>
                                    <h3 className="text-green-light">507%</h3>
                                </Col>
                            </Row>

                            <div className="d-flex align-items-center text-gray justify-content-between">
                                <span>Liquidate &lt; 180%</span>
                                <span>Target &lt; 540% <Tooltip pos="right" text="Texting" /></span>
                            </div>
                            <ProgressBar className="progress-shadow my-4" variant="green-light" now={90} />
                        </div>

                        <Row className="g-3 justify-content-center">
                            <Col md={4} sm={6}>
                                <div className="box h-100 d-flex flex-column p-3">
                                    <div className="flex mb-20 justify-content-between">
                                        <h1 className="text-grad mb-0">1</h1>
                                        <img src={StackIcon} alt="StackIcon" />
                                    </div>

                                    <div className="flex-grow-1 mb-3">
                                        <h4>Stake</h4>
                                        <h6 className="text-gray">Deposit  Joker-USDC LP  </h6>
                                    </div>

                                    <button className="btn btn-grad w-100">Start Staking</button>
                                </div>
                            </Col>
                            <Col md={4} sm={6}>
                                <div className="box h-100 d-flex flex-column p-3">
                                    <div className="flex mb-20 justify-content-between">
                                        <h1 className="text-red mb-0">2</h1>
                                        <img src={UnstackIcon} alt="StackIcon" />
                                    </div>

                                    <div className="flex-grow-1 mb-3">
                                        <h4>Withdraw Ratio </h4>
                                        <h6 className="text-gray">Use the burn feature to maintain your c-ratio or completely pay off debt and unstake your SNX.</h6>
                                    </div>
                                    <Badge bg="green-light" className="me-auto mb-3">Adjust to collect Weekly Rewards <img src={AdjustWeeklyRewards} alt="AdjustWeeklyRewards" /></Badge>
                                    <button className="btn btn-dark w-100"><span>C-Ratio explained</span></button>
                                </div>
                            </Col>
                            <Col md={4} sm={6}>
                                <div className="box h-100 d-flex flex-column p-3">
                                    <div className="flex mb-20 justify-content-between">
                                        <h1 className="text-gray mb-0">3</h1>
                                        <img src={RewardExplainedIcon} alt="StackIcon" />
                                    </div>

                                    <div className="flex-grow-1 mb-3">
                                        <h4>Collect Rewards</h4>
                                        <h6 className="text-gray">Maintain your target C-Ratio to collect your weekly rewards.</h6>
                                    </div>

                                    <Row className="justify-content-between mb-3">
                                        <Col xs={'auto'}>
                                            <h6>Your Rewards <Tooltip pos="right" text="Lorem Ipsum" /></h6>
                                            <h5 className="text-green-light">$0.45</h5>
                                        </Col>
                                        <Col xs={'auto'}>
                                            <h6>Your Fees <Tooltip pos="right" text="Lorem Ipsum" /></h6>
                                            <h5 className="text-green-light">$0.10</h5>
                                        </Col>
                                    </Row>

                                    <button className="btn btn-grad w-100">Reward Explained</button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <div className="swap-card mb-3 text-end">
                            <h6 className="text-white flex g-2 justify-content-end mb-2">Estimated APR <Tooltip text="Lorem Ipsum" pos="right" /></h6>
                            <h3 className="mb-0">20.86%</h3>
                        </div>
                        <div className="swap-card mb-3 flex justify-content-between g-2">
                            <div className="flex-grow-1">
                                <h6 className="text-white mb-1">Staked amount</h6>
                                <h6 className="text-white mb-1">20.92</h6>
                                <p className="text-gray">$44.45</p>
                                <hr />
                                <h6 className="text-white flex justify-content-between mb-1"><span>Staked</span><span>20.92</span></h6>
                                <p className="text-gray flex justify-content-between"><span>Transferrable</span><span>0.00</span></p>

                                <div className="text-center d-flex justify-content-center">
                                    <button className="btn-reset text-16 font-700 flex g-2"><span className="text-grad">Show all Balances</span> <img src={ArrowDown} alt="ArrowDown" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="swap-card mb-3 flex justify-content-between g-2">
                            <div className="flex-grow-1">
                                <h6 className="text-white mb-1">SNX Bridge</h6>
                                <p className="text-gray">Transfer Asset between blockchains</p>
                            </div>
                            <div>
                                <img src={SnxIcon} alt="SnxIcon" />
                            </div>
                        </div>
                        <div className="swap-card mb-3 flex justify-content-between g-2">
                            <div className="flex-grow-1">
                                <h6 className="text-white mb-1">Staked amount</h6>
                                <p className="text-gray">Buy dSNX to hedge</p>
                            </div>
                            <div>
                                <input type="text" className="input-reset" style={{maxWidth: '50px'}} placeholder="0.00" />
                            </div>
                        </div>
                        <div className="swap-card mb-3 flex justify-content-between g-2">
                            <div className="flex-grow-1">
                                <h6 className="text-white mb-1">Help</h6>
                                <p className="text-gray">Learn more about Joker Protocol</p>
                            </div>
                            <div>
                                <img src={HelpIcon} alt="HelpIcon" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </LayoutAfterLogin>
    );
}

export default WithdrawUnlocked;