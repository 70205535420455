import { Badge, Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import Preview from "../asserts/IMAGES/loginImage.jpg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg'
import bgBackgroundImage from '../asserts/IMAGES/top-bg.svg';
import { ConnectWallet } from "../abi/CommonSolFunctions";
import { AddWallet, checkWalletEligible } from "../abi/firebasecode";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import jokerlogo from '../asserts/IMAGES/jokerRedLogo.svg';
import cvImage from '../asserts/IMAGES/coverimage1.png';




function LogIn({connectWallet,setEligibile}) {
    const [eligible, seteligibile] = useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    
    const checkeliglibile = async() =>{
        let eligible1 = await connectWallet();
        setEligibile(eligible1)
        seteligibile(eligible1);
    }

   



    
    return ( 
        // <LayoutBeforeLogin>
        <Container fluid className="p-0" style={{ backgroundImage: `url(${cvImage})`, backgroundSize: 'contain',backgroundAttachment:'fixed', backgroundRepeat: 'no-repeat' , backgroundPosition:'right', minHeight: '100vh' }}>
        <div className="px-md-0 px-3">
            <Row className="app-blogin-row align-items-center justify-content-center"  style={{ minHeight: '100vh' }}>
                <Col md={12}>
                <h2 className="text-center" >Welcome to JOKER PROTOCOL</h2>
                <Button variant="grad" className="d-block  mb-2" onClick={() => checkeliglibile()}>{isConnected ? 'Click Here' : 'Click Here'}</Button>

                    <div className="app-blogin-inner">
                       
    
                        
                       
                    </div>
                </Col>
                
            </Row>
            <div class="position-absolute bottom-50 start-10 translate-middle-x">Stick to the bottom on viewports sized XXL (extra-extra-large) or wider</div>

        </div>
    </Container>
    
    
        // </LayoutBeforeLogin>
    )
}

export default LogIn;