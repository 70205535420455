import { Col, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import SNXLogo from '../../asserts/IMAGES/snx-logo.svg'
import BackIcon from '../../asserts/IMAGES/back-white-icon.svg'
import ArrowDown from '../../asserts/IMAGES/arrowDown.svg'
import ArrowRight from '../../asserts/IMAGES/arrowRight.svg'
import HedgeDebtI from '../../asserts/IMAGES/hedgeDebt-1.svg'
import HedgeDebt2 from '../../asserts/IMAGES/hedgeDebt-2.svg'
import { Link } from "react-router-dom";
import Timer from "../Dashboard/Snippets/Timer";

function StakeBorrow({balances}) {
    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <div className="p-24 px-xl-5 app-contain">
                <div className="mb-4 mb-lg-5">
                    <Link className="flex g-2 mb-3 text-white"><img src={BackIcon} alt="icon" /> Back</Link>
                    <Row>
                        <Col md={6} xl={8}>
                            <h2 className="mb-2">Stake SNX By Minting sUSD</h2>
                            <h6 className="text-gray mb-0">Mint sUSD by staking your SNX. SNX stakers earn weekly staking rewards in exchange for managing their Collateralization Ratio and debt. Your staked SNX will be locked for 7 days.</h6>
                        </Col>
                    </Row>
                </div>

                <Row className="g-2 mb-20 justify-content-between">
                    <Col xs={'auto time-green'}>
                        <h5 className="flex g-2 text-gray mb-2">Estimated APR <Tooltip text="Lorem Ipsum" /></h5>
                        <Timer count={"2024-03-24T01:02:03"} />
                    </Col>
                    <Col xs={'auto'} className="text-end">
                        <h5 className="text-gray mb-2">SNX Bridge</h5>
                        <h3 className="text-green-light mb-0">$2.42</h3>
                    </Col>
                </Row>

                <Row className="g-lg-4 g-3 justify-content-center">
                    <Col md={8} sm={6}>
                        <div className="bg-body rounded-4 px-3 py-3">
                            <h5 className="flex g-1">How much SNX do you want to stake?  <Tooltip text="Lorem Ipsum" /></h5>
                            <div className="swap-card mb-20">
                                <div className="mb-20 d-flex  flex-wrap justify-content-between align-items-center">
                                    <h3 className="flex mb-0 g-2">
                                        <img src={SNXLogo} alt="SNXLogo" />
                                        SNX
                                    </h3>
                                    <div className="text-end ms-auto">
                                        <h3 className="mb-1">Enter Amount</h3>
                                        <h6 className="text-gray mb-0">Unstaked SNX 20.92</h6>
                                    </div>
                                </div>

                                <Row className="g-2">
                                    <Col>
                                        <button className="btn btn-toggle active w-100"><span>25%</span></button>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-toggle w-100"><span>50%</span></button>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-toggle w-100"><span>75%</span></button>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-toggle w-100"><span>100%</span></button>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mb-20 p-lg-4 p-3 bg-dark rounded-3">
                                <div className="mb-2 flex justify-content-between">
                                    <h4>Total SNX</h4>
                                    <h4>20.92</h4>
                                </div>

                                <div className="mb-1 flex justify-content-between">
                                    <h6 className="flex text-gray g-1 mb-0">Gas Price  <Tooltip text="Lorem Ipsum" /></h6>
                                    <h6 className="flex text-gray g-2 mb-0">0.00 <img src={ArrowRight} alt="ArrowRight" /> 20.93</h6>
                                </div>
                                <div className="mb-3 flex justify-content-between">
                                    <h6 className="flex text-gray g-1 mb-0">Transferable  <Tooltip text="Lorem Ipsum" /></h6>
                                    <h6 className="flex text-gray g-2 mb-0">20.93 <img src={ArrowRight} alt="ArrowRight" /> 0.00</h6>
                                </div>

                                <div className="mb-3 flex justify-content-between">
                                    <h6 className="flex g-1 mb-0">C-Ratio  <Tooltip text="Lorem Ipsum" /></h6>
                                    <h6 className="flex g-2 mb-0">0% <img src={ArrowRight} alt="ArrowRight" /> 499.93%</h6>
                                </div>
                                <div className="mb-3 flex justify-content-between">
                                    <h6 className="flex g-1 mb-0">sUSD Balance  <Tooltip text="Lorem Ipsum" /></h6>
                                    <h6 className="flex g-2 mb-0">0.00 <img src={ArrowRight} alt="ArrowRight" /> 8.96</h6>
                                </div>
                                <div className="flex justify-content-between">
                                    <h6 className="flex g-1 mb-0">Active Debt  <Tooltip text="Lorem Ipsum" /></h6>
                                    <h6 className="flex g-2 mb-0">0.00 <img src={ArrowRight} alt="ArrowRight" /> 8.96</h6>
                                </div>
                            </div>

                            <div className="mb-3 flex justify-content-between g-2">
                                <h5 className="flex g-1 mb-0">Gas Price  <Tooltip text="Lorem Ipsum" /></h5>
                                <button className="btn text-gray flex g-2 btn-reset">$0.00 <img src={ArrowDown} alt="ArrowDown" /></button>
                            </div>

                            <button type="submit" className="btn mb-3 btn-grad w-100">Mint</button>
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <div className="swap-card flex mb-20 p-3 p-md-4">
                            <h4 className="mb-0 font-600">Hedge Debt</h4>
                            <img src={HedgeDebtI} className="ms-auto" alt="HedgeDebtI" />
                        </div>
                        <div className="swap-card flex mb-20 p-3 p-md-4">
                            <h4 className="mb-0 font-600">Hedge Debt</h4>
                            <img src={HedgeDebt2} className="ms-auto" alt="HedgeDebtI" />
                        </div>
                    </Col>
                </Row>
            </div>
        </LayoutAfterLogin>
    );
}

export default StakeBorrow;