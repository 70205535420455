import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const formatYAxisLabel = (value) => {
  const absValue = Math.abs(value);
  const trillion = 1000000000000;
  const billion = 1000000000;
  const million = 1000000;

  if (absValue >= trillion) {
    return `$${(value / trillion).toFixed(0)}T`; // Trillion
  } else if (absValue >= billion) {
    return `$${(value / billion).toFixed(0)}B`; // Billion
  } else if (absValue >= million) {
    return `$${(value / million).toFixed(0)}M`; // Million
  } else {
    return `$${value}`; // Default format
  }
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
    },
  },
  aspectRatio: 3,
  scales: {
    x: {
      ticks: {
        color: "#979797",
        stepSize: 1,
        beginAtZero: true,
      },
      display: true,
      grid: {
        color: '#333333',
      },
    },
    y: {
      ticks: {
        color: "#979797",
        stepSize: 1,
        beginAtZero: true,
        callback: (value) => formatYAxisLabel(value), // Custom tick formatting
      },
      display: true,
      grid: {
        color: '#333333',
      },
    },
  },
};

// const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// const createBarGradient = (startColor, endColor) => {
//     const gradient = document.createElement('canvas').getContext('2d');
//     gradient.canvas.height = 1;
//     gradient.canvas.width = 100;

//     const barGradient = gradient.createLinearGradient(0, 0, 100, 0);
//     barGradient.addColorStop(0, startColor);
//     barGradient.addColorStop(1, endColor);

//     return barGradient;
//   };

// export const data = {
//   labels,
//   datasets: [
//       {
//         type: 'line',
//         label: 'Joker',
//         data: [45,60,58,45,60,30,43,70,35,40,35,65],
//         borderColor: '#B7EDE0',
//         backgroundColor: '#FFFFFF',
//         tooltip: {
//           callbacks: {
//             label: function(context) {
//               // 'DS'+index.dataIndex+1
//               let label = 'DS'+ (context.dataIndex + 1) || '';
//               // let label = context.dataset.label || '';

//               // if (label) {
//               //   label += ': ';
//               // }
//               // if (context.parsed.y !== null) {
//               //   label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
//               // }
//               return label;
//             }
//           }
//         }
//       },
//     {
//       type: 'bar',
//       label: 'Dime',
//       data: [60,30,40,35,65,43,70,35,45,65,58,45],
//       backgroundColor: '#00C2FF',

//     },
//     {
//         type: 'bar',
//       label: 'Credit',
//       data: [43,70,35,45,60,58,45,60,30,40,35,65],
//       backgroundColor: '#20E2D0',
//     }
//   ],
// };

export default function BarLinedata({labels,data1,data2}) {

  const data = {
    labels,
    datasets: [
     /**   {
          type: 'line',
          label: 'Joker',
          data: [45,60,58,45,60,30,43,70,35,40,35,65],
          borderColor: '#B7EDE0',
          backgroundColor: '#FFFFFF',
          tooltip: {
            callbacks: {
              label: function(context) {
                // 'DS'+index.dataIndex+1
                let label = 'DS'+ (context.dataIndex + 1) || '';
                // let label = context.dataset.label || '';
  
                // if (label) {
                //   label += ': ';
                // }
                // if (context.parsed.y !== null) {
                //   label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                // }
                return label;
              }
            }
          }
        },
      {
        type: 'bar',
        label: 'JOKER',
        data: data2,
        backgroundColor: '#00C2FF',
  
      },
      {
          type: 'bar',
        label: 'DIME + CREDITS',
        data: data1,
        backgroundColor: '#20E2D0',
      }*/ 
      {
        type: 'line', // Change type to 'line' for area chart
        label: 'JOKER', // Label for the dataset
        data: data2, // Data points for the line
        borderColor: 'red', // Red border color for Joker
        backgroundColor: 'rgba(255, 0, 0, 0.3)', // Transparent red fill color for Joker
        fill: true, // Enable area fill under the line
      },
      {
        type: 'line', // Change type to 'line' for area chart
        label: 'DIME + CREDITS', // Label for the dataset
        data: data1, // Data points for the line
        borderColor: 'cyan', // Cyan border color for DIME + CREDITS
        backgroundColor: 'rgba(0, 255, 255, 0.3)',// Transparent black fill color for DIME + CREDITS
        fill: true, // Enable area fill under the line
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
