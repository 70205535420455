import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Logo from '../asserts/IMAGES/logo-icon.svg';
import Icon1 from '../asserts/IMAGES/header-icon-1.svg';
import Icon2 from '../asserts/IMAGES/header-icon-2.svg';
import Icon3 from '../asserts/IMAGES/header-icon-3.svg';
import Icon4 from '../asserts/IMAGES/header-icon-4.svg';
import jokerLogo from '../asserts/IMAGES/jokerRedLogo.svg';
import dimeLogo from '../asserts/IMAGES/dimeCyanLogo.svg';
import creditsLogo from '../asserts/IMAGES/creditsBlackLogo.svg';
import DangerIcon from '../asserts/IMAGES/DangerIcon.svg';
import Icon5 from '../asserts/IMAGES/header-icon-5.svg';
import JokerIcon from '../asserts/IMAGES/joker-icon-logo.svg'
import MetamaskIcon from '../asserts/IMAGES/metamask-icon.svg'
import ExplorerIcon from '../asserts/IMAGES/explorerIcon.svg'
import Dots from '../asserts/IMAGES/dots.svg'
import Menu from '../asserts/IMAGES/menu-icon.svg'
import CopyIcon from '../asserts/IMAGES/copy-icon.svg'
import TranssationPending from '../asserts/IMAGES/transsationPending.svg'
import ArrowLink from '../asserts/IMAGES/arrowLink.svg'
import Sidebar from './Sidebar';
import headerLogo from '../asserts/IMAGES/joker-app-header-logonew.svg';
import Cartoon from '../asserts/IMAGES/cartoon_light.png';

import { useEffect, useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { ChangeNetwork, ConnectWallet, config } from '../abi/CommonSolFunctions';
import { useWeb3ModalAccount, useWeb3ModalProvider, useDisconnect  } from '@web3modal/ethers5/react';
import topbgImage from '../asserts/IMAGES/top-bg.svg';

function Header({menuActive, balances, checkeligibility}) {
    const { walletProvider } = useWeb3ModalProvider();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { disconnect } = useDisconnect();
    const navigate = useNavigate();
    
    const [showSidebar, SetShowSidebar] = useState(false);
    const handleToggle = () => {
      SetShowSidebar(!showSidebar);
    }

    const [show, setShow] = useState(false);
    const [copy, setCopy] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCopied = async () => {
      setCopy(true);
      await sleep(2000);
      setCopy(false);
    }

    const connectWallet = async () => {
      await ConnectWallet();
    }

    const changeNetwork = async () => {
      await ChangeNetwork({walletProvider});
    }

    const disconnectWallet = async () => {
      try {
        disconnect();
        handleClose();
        navigate('/login')
        // console.log('Wallet disconnected successfully.');
      } catch (error) {
        // console.error('Error disconnecting wallet:', error);
      }
    };

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [showTr, setShowTr] = useState(false);

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    const [Cartoonshow, setCartoonShow] = useState(false);
    const handle = () => {setCartoonShow(!Cartoonshow); localStorage.setItem('elemCartn', true);};

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => {setShow1(false); localStorage.setItem('elemCartn', true);}

    useEffect(() => {
        if(!localStorage.getItem('elemCartn')){
            setTimeout(() => {
                setCartoonShow(true);
            }, 1000);
            setTimeout(() => {
                setShow1(true);
            }, 2000);
        }
    }, [])

 

    return ( 
      <>
        <header className="header-bar sticky-top">
        <Link className="header-logo" to="/"><img src={headerLogo} alt="logo" height="40px"/></Link>

          <button onClick={() => handleToggle()} className='btn p-0 ms-auto ms-md-3 order-md-1 btn-menu'>
            <img src={Menu} alt='Menu' />
          </button>

          <div className='header-controls ms-sm-auto'>
          {isConnected ? <>
            <div className='headerlogo-item'>
              <img src={jokerLogo} alt="icon" height={'19px'} />
              {parseFloat(balances.joker).toFixed(2)} 
            </div>
            <div className='headerlogo-item'>
              <img src={dimeLogo} alt="icon" height={'19px'} />
              {parseFloat(balances.dime).toFixed(2)} 
            </div>
            <div className='headerlogo-item' >
              <img src={creditsLogo} alt="icon" height={'19px'}/>
              {parseFloat(balances.credits).toFixed(2)} 
            </div>
          </> : <></>}

  

            <button type='button' className='header-item' onClick={(!address ? connectWallet : handleShow)}>
              <img src={Icon4} alt="icon" />
                {!address ? 'Connect Wallet' : `${address.substring(0, 2)}...${address.substring(address.length - 2)}`}
            </button>
        

            {chainId !== config.chainId && isConnected && (
              <button type='button' className='header-item  btn btn-red' style={{ background: '#C70039' }} onClick={changeNetwork}>
                <img src={DangerIcon} alt="icon" />
                Change Network
              </button>
            )}

            {/* <Dropdown className='d-none d-sm-block'>
              <Dropdown.Toggle variant="toggle" id="dropdown-basic">
                <img src={Dots} alt="Dots" />
              </Dropdown.Toggle> */}

              {/* <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu> */}
            {/* </Dropdown> */}
          </div>
        </header>     

        <Sidebar menuActive={menuActive} view={showSidebar} />   

        <Modal show={show} centered onHide={handleClose}>
            <Modal.Header className='pb-0' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <div className="mb-4">
                        <img src={JokerIcon} height={'40px'} className="mb-20" alt="JokerIcon" />
                        <h3 style={{ fontSize: '1rem' }}>{!address ? 'Connect Wallet' : `${address.substring(0, 4)}...${address.substring(address.length - 4)}`}</h3>
                    </div>

                    <div className='d-flex justify-content-center align-items-center mb-4'>
                      <div className='header-item'>
                        <img src={Icon5} alt="icon" />
                        {parseFloat(balances.eth).toFixed(6)} ETH
                      </div>
                    </div>

                    <Row className='g-3'>
                      <Col xs={6}>
                        <button type='button' className='flex btn-black swap-card g-3 w-100 p-3 justify-content-center' 
                        onClick={() =>
                          {
                              navigator.clipboard.writeText(address);
                              handleCopied();
                          }}>
                          <img src={CopyIcon} className='m-0' alt="Icon1" />
                          {!copy ? 'Copy Address' : 'Address Copied!'}
                        </button>
                      </Col>
                      <Col xs={6}>
                        <a href={`${config.explorerUrl}/address/${address}`} target="_blank" rel="noopener noreferrer" type='button' className='flex btn-black swap-card g-3 w-100 p-3 justify-content-center'>
                          <img src={ExplorerIcon} className='m-0' alt="Icon1" />
                          View on explorer
                        </a>
                      </Col>
                    </Row>
                </div>
                <hr />
                <h4 className='mb-3'>Connected with MetaMask <img src={MetamaskIcon} className='ms-2' alt="MetamaskIcon" /></h4>
                <Row className='g-3 align-items-center'>
                <Col xs={6}>
                    <select className='form-select'>
                      <option>Testnet</option>
                      <option>Mainnet</option>
                    </select>
                  </Col>
                  <Col xs={6}>
                    <button type='button' className='flex btn btn-red w-100 p-3 justify-content-center' onClick={disconnectWallet}>
                      Disconnect
                    </button>
                  </Col>
                </Row>

                {/* <button className="btn btn-grad w-100">Button</button>     */}
            </Modal.Body>
        </Modal>

        <Modal show={showTr} centered onHide={handleCloseTransation}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img src={TranssationPending} width={80} className="mb-3" alt="JokerIcon" />
                <h3 className='mb-1'>Transaction Pending</h3>
                <p className='mb-4'><small>Follow the Metamask prompts to authorize the transaction.</small></p>
                <button className='btn btn-black border-0 flex justify-content-center  w-100 g-2 mb-3'><Spinner size='sm' animation="border" variant="blue " /> <span className='text-grad'>Loading</span></button>
                <button className="btn-link" onClick={handleCloseTransation}><span className='text-grad'>View Etherscan</span> <img src={ArrowLink} className='ms-2' alt="arrowLink" /></button>    
              </div>

            </Modal.Body>
        </Modal>

        <Modal show={show1} size="lg" centered  className='modal-dashboard'  onHide={handleClose1}>
                <Modal.Header closeButton className='align-items-start'>
                    <Modal.Title><h2 className='m-0'>
                      Welcome to JOKER PROTOCOL
                      </h2></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <div className="text-center">
                    <Row className='mb-3 align-items-center'>
                        <Col md={12} className="align-items-center" >
                            <p>JOKER Protocol revolutionizes DeFi by pioneering the first three-dimensional orthogonal asset class, where sell pressure acts as a catalyst, not a detriment. This self-governing smart asset leverages a groundbreaking fusion of four algorithmic intertwined models (Reflection, POL, Seigniorage, and Elastic) to autonomously balance supply, purchasing power, and market stability. In doing so, it combats economic fluctuations and creates a truly adaptive protocol-driven stability game theory, offering a compelling proposition for investors seeking a dynamic and resilient investment strategy.</p>
                            {/* <p>TAU is the first non-dilutive Algorithmic Fractional Stablecoin DeFi Protocol on blockchains to solve the so-called ‘Stablecoin Trilemma’ through its novel stabilization algorithm called Autonomous Demand Supply Balancer (ADSB). The price stability is orthogonally regulated through elastic supply adjustments, burn, and bonding mechanics powered by an ecosystem of optimized interconnected DeFi Apps. In addition, the protocol adds more value to the token holders through yield benefits and arbitrage opportunities.</p> */}
                        </Col>
                        {/* <Col md={4} className="text-center">
                            <img src={Logo} alt="image" className='img-fluid w-75 rounded' /> 
                        </Col> */}
                    </Row>
                    <Row className='text-center '>
                        <Col sm="4" className='py-sm-2 py-1'>
                        <a href="https://twitter.com/Jok3rFi" target="_blank" rel="noopener noreferrer">
                        <button  className="btn btn-grad btn-min px-5"   >Twitter</button></a>
                        </Col>
                        <Col sm="4" className='py-sm-2 py-1'>
                        <a href="https://t.me/Jokerprotocol" target="_blank" rel="noopener noreferrer">
                          <button  className="btn btn-grad btn-min px-5"  >Telegram</button></a>
                        </Col>
                        <Col sm="4" className='py-sm-2 py-1'>
                        <a href="https://github.com/JOK3RFI" target="_blank" rel="noopener noreferrer">
                          <button  className="btn btn-grad btn-min px-5"  >Github</button></a>
                        </Col>
                    </Row>
</div>
                    <hr className='mb-3' />

                    <Row className='align-items-center text-sm-start text-center'>
                        <Col sm={6}>
                            {/* <div className='d-inline-block'>
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`dnot-show`}
                                    label={`Don't show again`}
                                />
                            </div> */}
                            <div>©2024 Joker Foundation.All Rights Reserved</div>
                        </Col>
                        <Col sm={6} className="d-none d-md-block text-end">
                            <img src={Logo} alt="image" className='img-fluid' /> 
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
{Cartoonshow &&             <img src={Cartoon} onClick={handle} alt="Cartoon" className={`footer-cartoon ${Cartoonshow ? '' : 'c-hide'}`} />
}
         
      </>
     );
}

export default Header;
