import { Col, ProgressBar, Row, Table } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import DaiIcon from '../../asserts/IMAGES/dai.svg'
import AlpacaIcon from '../../asserts/IMAGES/alpacaFinance-logo.png'
import YearnIcon  from '../../asserts/IMAGES/yearnFinance-logo.png'
import { CircularProgressbar } from "react-circular-progressbar";
import ChartIcon from '../../asserts/IMAGES/chatJokericon.svg';
import SecureIcon from '../../asserts/IMAGES/secure-icon.svg';
import creditsLogo from '../../asserts/IMAGES/creditsBlackLogo.svg';
import creditslogo from '../../asserts/IMAGES/creditsBlackLogo.svg';
import jokerIcon from '../../asserts/IMAGES/jokerRedLogo.svg'

import TotalTransaction from "./snippet/TotalTransaction";
import { AreaChartPlan } from "../Dashboard/Snippets/AreaChartPlan";
import { useState, useEffect } from "react";

import { CheckAllowance, NumberAbbreviation, getTokenBalance } from "../../abi/CommonSolFunctions";
import { CHAIN_URL,  Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, Carbonix_Finance_Address, Carbonix_Finance_ABI, Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI,  DAI_Chainlink_Oracle_Address,  CREDITS_Token_Address, CREDITS_Token_ABI, CREDITS_Chainlink_Oracle_Address, API_KEY, Network_Name } from "../../abi/RefinedABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { getCOllateralOneYear, recordUserVisits, updateCollateralValue } from "../../abi/firebasecode";
import TreasuryCard from "../Dashboard/Snippets/TreasuryCard";
import CarbonixCard from "../Dashboard/Snippets/Carbonixcard";
import ChartComponent from "../Dashboard/Snippets/PieChart2";
import ChartComponentCarbonix from "../Dashboard/Snippets/PieChartCarbonix";


function Carbonix({balances}) {

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    const[daiBalanceContract,setdaiBalanceContract] = useState("");
    const[depositedCredits, setdepositedCredits] = useState("");
    const[stabilizableDai, setstabilizableDai] = useState("");
    const[totalCreditsDeposited,settotalCreditsDeposited] = useState("");
    const[alpacaDeposit,setalpacaDeposit] = useState("");
    const[depositedDai, setdepositedDai] = useState("");
    const[borrowedCredits, setborrowedCredits] = useState("");
    const[borrowedCreditsInUSD, setborrowedCreditsInUSD] = useState("");
    const[depositedDaiInUSD, setdepositedDaiInUSD] = useState("");
    const[daiPrice,setdaiPrice] = useState("");
    const[creditsPrice,setcreditsPrice] = useState("");
    const[AvailableRewards,setAvailableRewards] = useState("");
    const[AprVal,setAprVal] = useState("");

    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);

    const getAssetDetails = async() =>{
        
       
            
                console.log("useeffect");
                try{
                const StabiliserContract = new ethers.Contract(Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI, provider);
                const CarbonixContract = new ethers.Contract(Carbonix_Finance_Address, Carbonix_Finance_ABI, provider);
                const DaiPriceContract = new ethers.Contract(DAI_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
                const CreditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
                const CreditsPriceContract = new ethers.Contract(CREDITS_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
      
                let daiBalanceContract = await getTokenBalance(Carbonix_Stabiliser_Address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
                setdaiBalanceContract(daiBalanceContract);
                if(isConnected){
                let depositedCredits = ethers.utils.formatUnits(await StabiliserContract.depositedCfTokens(address),0);
                setdepositedCredits(depositedCredits);
                let stabilizableDai = ethers.utils.formatUnits(await StabiliserContract.tokensInBucket(address),0);
                setstabilizableDai(stabilizableDai);
                await recordUserVisits(address, `Carbonix Dashboard`);
            }
                let totalCreditsDeposited = ethers.utils.formatUnits(await StabiliserContract.totalSupplyCftokens(),0);
                settotalCreditsDeposited(totalCreditsDeposited);
                let alpacaDeposit = ethers.utils.formatUnits(await StabiliserContract.getVaultTotalDeposited(0),0);
                setalpacaDeposit(alpacaDeposit);
      
                let depositedDai = ethers.utils.formatUnits(await CarbonixContract.totalDeposited(),0);
                setdepositedDai(depositedDai);
                let borrowedCredits = ethers.utils.formatUnits(await CreditsContract.hasMinted(Carbonix_Finance_Address),0);
                setborrowedCredits(borrowedCredits);
      
                let daiprice = ethers.utils.formatUnits(await DaiPriceContract.getChainlinkDataFeedLatestAnswer(),0);
                setdaiPrice(daiprice);
                let creditsprice = ethers.utils.formatUnits(await CreditsPriceContract.getChainlinkDataFeedLatestAnswer(),0);
                setcreditsPrice(creditsprice);
                let depositedDaiInUSD = (depositedDai/1e18) * daiprice;
                setdepositedDaiInUSD(depositedDaiInUSD);
                let borrowedCreditsInUSD = (borrowedCredits/1e18) * creditsprice;
                setborrowedCreditsInUSD(borrowedCreditsInUSD);
                await updateCollateralValue(depositedDaiInUSD/1e8)

                let tokentodistribute = ethers.utils.formatUnits(await CarbonixContract.tokensToDistribute(),0);
                let tokendistributed = ethers.utils.formatUnits(await CarbonixContract.totalRewardsDistributed(),0);
                let availablerewards = Math.abs(parseFloat(tokentodistribute)-parseFloat(tokendistributed));
                setAvailableRewards(Math.abs(parseFloat(tokentodistribute)-parseFloat(tokendistributed)))

                let distributionduration = ethers.utils.formatUnits(await CarbonixContract.distributionDuration(),0);
                let aprcal = (((availablerewards/(depositedDai/1e9)))/1e9 * (1/(distributionduration/31556926)))*100;
                setAprVal(aprcal);
            }catch(err){
                console.log(err);
            }
             
        
        
    }

    useEffect(()=>{getAssetDetails()},[address, isConnected]);


    const getallvalues = async() =>{
        let oneyearvalue = await getCOllateralOneYear()
        // setOneyearvalue(oneyearvalue)
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);        
        setOneyearprices(prices)

    }

    useEffect(() =>{getallvalues()},[])

    const chartData = {
        labels: ['Collateral', 'Repaid','Debt'],
        values: [depositedDaiInUSD ? (depositedDaiInUSD/1e8).toFixed(2) : "10",borrowedCreditsInUSD ? (borrowedCreditsInUSD/1e8).toFixed(2) : "5",(depositedDaiInUSD && borrowedCreditsInUSD) ? ((depositedDaiInUSD - borrowedCreditsInUSD)/1e8).toFixed(2) : "3"],
        colors: ['cyan', '#FF9800','Red'],
      };

    return (  
        <LayoutAfterLogin menuActive={"carbonix"} balances={balances}>
            <div className="p-24 app-contain">
                <Row className="g-3">
                <Col md={12}>
                        <div className="box p-3">
                            <Row className="mb-2 g-2">
                                <Col lg={12}>
                                    <Row>
                                        <Col sm={3}>
                                            <h5 className="text-center mb-1">
                                                {/* <span className="dot" style={{backgroundColor: '#FE8D52'}}> */}
                                                    <img src={DaiIcon} alt="DaiIcon" style={{ width: "20px", height: "20px" ,marginRight:'10px'}} />
                                                    {/* </span>  */}
                                                    Total Collateral Value </h5>
                                            <h4 className="mb-0 text-center">$ <NumberAbbreviation number={depositedDaiInUSD ? (depositedDaiInUSD/1e8).toFixed(4) : "0"} /> </h4>
                                        </Col>
                                        <Col sm={3} className="border-start">
                                            <h5 className="text-center mb-1">
                                                {/* <span className="dot" style={{backgroundColor: '#00C2FF'}}></span>  */}
                                                <img src={creditsLogo} alt="DaiIcon" style={{ width: "20px", height: "20px" ,marginRight:'10px'}} />
                                                Total Borrowed </h5>
                                            <h4 className="mb-0 text-center">$ <NumberAbbreviation number={borrowedCreditsInUSD ? (borrowedCreditsInUSD/1e8).toFixed(4) : "0"}/> </h4>
                                        </Col>
                                        <Col sm={3} className="border-start">
                                            <h5 className="text-center mb-1"><img src={DaiIcon} alt="DaiIcon" style={{ width: "20px", height: "20px" ,marginRight:'10px'}} />Collateral Reserve </h5>
                                            <h4 className="mb-0 text-center">$ <NumberAbbreviation number={(depositedDaiInUSD && borrowedCreditsInUSD) ? ((depositedDaiInUSD - borrowedCreditsInUSD)/1e8).toFixed(4) : "0.00"}/> </h4>
                                        </Col>
                                        <Col sm={3} className="border-start">
                                            <h5 className="text-center mb-1"><img src={jokerIcon} alt="DaiIcon" style={{ width: "20px", height: "20px" ,marginRight:'10px'}} />Available Rewards </h5>
                                            <h4 className="mb-0 text-center"> {AvailableRewards? parseFloat(AvailableRewards/1e9).toFixed(2):'0.0'}</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col lg={3}>
                                    <p className="mb-0 text-end"><small>DAI Price: {daiPrice ? (daiPrice/1e8).toFixed(4) : "0"} USD <br />CREDITS Price: {creditsPrice ? (creditsPrice/1e8).toFixed(4) : "0"} USD</small></p>
                                </Col> */}
                            </Row>

                        </div>
                    </Col>
                    <Col md={6}>
                    <div className="box p-3 ">
                    <h5 className="text-center mb-3">Deposited vs Borrowed</h5>
            {/* <Progress className="mt-1" variant="grad" /> */}
            <p className="flex justify-content-between mb-1"><small>0%</small><small>25%</small><small>50%</small><small>75%</small><small>100%</small></p>
            
            <ProgressBar  now={((parseFloat(borrowedCreditsInUSD)/parseFloat(depositedDaiInUSD)))*100} label={ borrowedCreditsInUSD && `${(((parseFloat(borrowedCreditsInUSD)/parseFloat(depositedDaiInUSD)))*100).toFixed(0)}%`} style={{ color: 'red' }} className="mt-1" variant="grad" />
            <p className="flex justify-content-between mb-2 mt-4"><small>Total Borrowed :  <NumberAbbreviation number={((parseFloat(borrowedCreditsInUSD)/parseFloat(depositedDaiInUSD)))*100}/> %</small><small>Total Deposited : $ <NumberAbbreviation number={depositedDaiInUSD ? ((depositedDaiInUSD/1e8).toFixed(2)) : "0"}/></small></p>

        </div>
                    </Col>

                    <Col md={6}>
                    <div className="box p-3 mt-0">
                    <h5 className="text-center mb-3">Available to Borrow</h5>
            {/* <Progress className="mt-1" variant="grad" /> */}
            <p className="flex justify-content-between mb-1"><small>0%</small><small>25%</small><small>50%</small><small>75%</small><small>100%</small></p>
            {/* {(parseFloat((borrowedCreditsInUSD/1e8))/(parseFloat(((parseFloat((borrowedCreditsInUSD/1e8)) - parseFloat((depositedDaiInUSD)/1e8)))/2)))*100} */}
            <ProgressBar  now= {(parseFloat((borrowedCreditsInUSD/1e8))/(parseFloat(Math.abs((parseFloat((borrowedCreditsInUSD/1e8)) - parseFloat((depositedDaiInUSD)/1e8)))/2)))*100} label={ borrowedCreditsInUSD && `${((parseFloat((borrowedCreditsInUSD/1e8))/(parseFloat(Math.abs((parseFloat((borrowedCreditsInUSD/1e8)) - parseFloat((depositedDaiInUSD)/1e8)))/2)))*100).toFixed(0)}%`} style={{ color: 'red' }} className="mt-1" variant="grad" />
            <p className="flex justify-content-between mb-2 mt-4"><small>Available  : $ <NumberAbbreviation number={Math.abs((parseFloat((borrowedCreditsInUSD/1e8)) - parseFloat((depositedDaiInUSD)/1e8)))/2}  /> </small><small>Total Borrowed : $ <NumberAbbreviation number={((parseFloat(borrowedCreditsInUSD)/1e8))}/></small></p>

        </div>
                    </Col>

                   
                    <Col md={6}>
                    <div className="box d-flex p-3 p-md-4">
                            <div className="flex-grow-1 d-flex flex-column justify-content-between">
                                <h4 className="mb-0 mt-5"><img src={DaiIcon} alt="DaiIcon" style={{ width: "30px", height: "30px" ,marginRight:'10px'}} />  DAI</h4>
                                {/* <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <h2 className="mb-0">50 %</h2>
                                    <span className="mb-1 text-muted" style={{ marginLeft: '0.5rem' }}>of Deposit</span>
                                </div> */}
                            </div>

                            <div className="border-start col-md-5 ps-4">
                                <div className="mb-md-4 mb-3">
                                    <h6 className="mb-1 text-muted">Deposited in Alpaca</h6>
                                    <h5>$ <NumberAbbreviation number={(depositedDaiInUSD && borrowedCreditsInUSD) ? ((depositedDaiInUSD - borrowedCreditsInUSD)/1e8).toFixed(3) : "0.000"}/></h5>
                                </div>
                                <div>
                                    <h6 className="mb-1 text-muted">Available for Stabilization</h6>
                                    <h5 className="mb-0"><NumberAbbreviation number={daiBalanceContract ? (daiBalanceContract/1e18).toFixed(4) : "0.00"}/></h5>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="box d-flex p-3 p-md-4 mt-3">
                            <div className="flex-grow-1 d-flex flex-column justify-content-between">
                                <h4 className="mb-0 mt-5"><img src={creditsLogo} alt="DaiIcon" style={{ width: "30px", height: "30px" ,marginRight:'10px'}} />  Percentage</h4>
                            
                            </div>

                            <div className="border-start col-md-5 ps-4">
                                <div className="mb-md-4 mb-3">
                                    <h6 className="mb-1 text-muted">Yield Percentage</h6>
                                    <h5>{AprVal? parseFloat(AprVal).toFixed(2) :"0.0"}% APR</h5>
                                </div>
                                <div>
                                    <h6 className="mb-1 text-muted">Borrow Percentage</h6>
                                    <h5 className="mb-0">50% <span className="mb-1 text-muted" style={{ marginLeft: '0.5rem' }}>of Deposit</span></h5>
                                    
                                </div>
                            </div>
                        </div>
                        {/* <div className="box p-3 p-md-4 mb-3 mt-3">
                            <div className="flex mb-md-4 mb-3 justify-content-between">
                                <h4 className="mb-0"> Deposited in Alpaca</h4>

                                <div className="box-dropdown">
                                    <button
                                        className="btn box-dropdown text-muted"
                                        type="button"
                                        aria-expanded="false"
                                    >
                                        <img src={DaiIcon} alt="DaiIcon" style={{ width: "20px", height: "20px" }} /> DAI
                                    </button>
                                </div>
                            </div>

                            <h2 className="mb-1">${(depositedDaiInUSD && borrowedCreditsInUSD) ? ((depositedDaiInUSD - borrowedCreditsInUSD)/1e8).toFixed(3) : "0.000"} </h2>
                            <h6 className="text-muted mb-0">${depositedDaiInUSD ? (depositedDaiInUSD/1e8).toFixed(4) : "0"}</h6>
                        </div> */}
                       
                    </Col>

                   
                    <Col md={6}>
                        <div className="box swap-value">
                            <div className="p-lg-4 p-3 border-bottom">
                                <div className="mb-3 flex">
                                    <div>
                                        <h4 className="mb-2">Carbonix Funds</h4>
                                        <h5 className="text-gray mb-1"><span className="dot" style={{backgroundColor: '#20E2D0'}}></span> Deposited fund: $ <NumberAbbreviation number={depositedDaiInUSD ? (depositedDaiInUSD/1e8).toFixed(4) : "0"}/></h5>
                                        <h5 className="text-gray mb-0"><span className="dot" style={{backgroundColor: '#EE6A5F'}}></span> Borrowed fund: $ <NumberAbbreviation number={borrowedCreditsInUSD ? (borrowedCreditsInUSD/1e8).toFixed(4) : "0"}/></h5>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="circle-timer circle-timer-pie">
                                            <CircularProgressbar value={`${100 - ((borrowedCreditsInUSD/ (depositedDaiInUSD))*100)}`} />
                                                <img src={creditsLogo}  height={'42px'} style={{minWidth: '42px'}}  alt='icon' />    
                                        </div>
                                    </div>
                                </div>
                                <h2 className="mb-0"><NumberAbbreviation number={borrowedCredits ? (borrowedCredits/1e18).toFixed(4) : "0.00"}/>  <img src={creditsLogo}  height={'25px'} style={{minWidth: '25px'}}  alt='icon' />   </h2>
                            </div>
                            <div className="py-3 px-4">
                                <div className="flex mb-20 justify-content-between">
                                    <h4 className="mb-0">Borrowed Funds</h4>
                                    <h5 className="mb-0 text-muted">Liquidation Alert</h5>
                                </div>
                                <p className="flex justify-content-between mb-1"><small>0%</small><small>25%</small><small>50%</small><small>75%</small><small>100%</small></p>
                                <ProgressBar style={{height: '8px'}} className="mt-1" variant="grad" now={(borrowedCreditsInUSD/ (depositedDaiInUSD))*100} />
                                <p className="flex justify-content-between mb-0 mt-4"><small>Liquidation DAI Price: $ <NumberAbbreviation number={daiPrice ? (daiPrice/1e8).toFixed(4) : "0"}/></small><small>Borrow Limit: $ <NumberAbbreviation number={depositedDaiInUSD ? ((depositedDaiInUSD/1e8).toFixed(2))/2 : "0"}/></small></p>
                            </div>
                        </div>
                    </Col>
                    
                    <Row className="g-3 mb-24">
                    <Col md={6}>
                        <CarbonixCard Oneyeardates={Oneyeardates?Oneyeardates:'0'} Oneyearprices={Oneyearprices?Oneyearprices:'0'} />
</Col>
                            {/* <AreaChartPlan labels={Oneyeardates?Oneyeardates:'0'} data1={Oneyearprices?Oneyearprices:'0'} /> */}
                            <Col md={6}>
  <div  style={{ width: '100%'}}>
    
        <ChartComponentCarbonix data={chartData} />
      </div>
                    </Col>
                        </Row>
                 


                    
                   
                    <Col md={12}>
                        <div className="box p-3">
                            <h4 className="mb-1">Markets</h4>

                            <div className="flex g-2 mb-20 flex-wrap">
                                {/* <button type="button" className="btn btn-grad py-2 px-md-4 px-3">Yearn Finance</button> */}
                                <button type="button" className="btn btn-grad outline py-2 px-md-4 px-3" ><img src={AlpacaIcon} alt="AlpacaIcon" style={{ width: "25px", height: "25px" }} /><span> Alpaca Finance</span></button>
                                {/* <button type="button" className="btn btn-grad outline py-2 px-md-4 px-3" disabled><span>BIFI (Coming Soon)</span></button> */}
                                {/* <button type="button" className="btn btn-grad outline py-2 px-md-4 px-3" disabled><span>Swip (Coming Soon)</span></button> */}
                            </div>

                            <h5 className="text-muted mb-1">Total Value Locked </h5>
                            <h4 className="mb-3">${(depositedDaiInUSD && borrowedCreditsInUSD) ? ((depositedDaiInUSD - borrowedCreditsInUSD)/1e8).toFixed(3) : "0.000"} <img src={SecureIcon} className="ms-1" alt="SecureIcon" /></h4>

                            <div className="mb-4">
                                <Table responsive striped hover>
                                    <thead>
                                        <tr>
                                            <th>Market</th>
                                            <th>Liquidity</th>
                                            <th>Token Name</th>
                                            <th>Rewards</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                <img src={YearnIcon} alt="AlpacaIcon" style={{ width: "25px", height: "25px" }} />
                                                Yearn
                                                </div>
                                            </td>
                                            <td>{alpacaDeposit ? (alpacaDeposit/1e18).toFixed(7) : "0.0000000"} DAI</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                <img src={DaiIcon} alt="DaiIcon" style={{ width: "25px", height: "25px" }} />
                                                    DAI
                                                </div>
                                            </td>
                                            <td className="text-green-light">0.0000000</td>
                                        </tr> */}
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                <img src={AlpacaIcon} alt="AlpacaIcon" style={{ width: "25px", height: "25px" }} />
                                                Alpaca
                                                </div>
                                            </td>
                                            <td>${(depositedDaiInUSD && borrowedCreditsInUSD) ? ((depositedDaiInUSD - borrowedCreditsInUSD)/1e8).toFixed(3) : "0.000"}  DAI</td> 
                                            <td>
                                                <div className="d-flex align-items-center">
                                                <img src={DaiIcon} alt="DaiIcon" style={{ width: "25px", height: "25px" }} />
                                                    DAI
                                                </div>
                                            </td>
                                            <td className="text-green-light">0.0000000</td>
                                        </tr>
                                        {/* <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                <img src={DaiIcon} alt="DaiIcon" style={{ width: "25px", height: "25px" }} />
                                                    DAI
                                                </div>
                                            </td>
                                            <td>0.000000 DAI</td>
                                            <td>anchor</td>
                                            <td className="text-green-light">0.0000000</td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </LayoutAfterLogin>
    );
}

export default Carbonix;
//<Tooltip text="Lorem Ipsum" pos="right" />