import { Col, Row, Table } from "react-bootstrap";
import Icon1 from '../../asserts/IMAGES/jokerRedLogo.svg';
import Icon3 from '../../asserts/IMAGES/jokerethlp.svg';
import AddIcon from '../../asserts/IMAGES/add-icon.svg';
import SwapBtn from '../../asserts/IMAGES/swap-btn.svg';
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import EthereumIcon from '../../asserts/IMAGES/Ethereum-icon.svg';
import Tooltip from "../../Snippets/Tooltip";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { API_KEY, CHAIN_URL, DAI_TOKEN_ABI, DAI_TOKEN_Address, JOKER_ETH_LP_Token_Address, JOKER_Token_ABI, JOKER_Token_Address, LP_Token_ABI, Network_Name, TXN_ID, WETH_TOKEN_Address, joker_DAI_LP_Address } from "../../abi/RefinedABI&ContractAddress";
import { CheckAllowance, PendingModal, convertEpochToDateTime, getGasBalance, getTokenBalance } from "../../abi/CommonSolFunctions";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import ProgressBar from "../Dashboard/Snippets/CircleTimer";
import Timer from "../Dashboard/Snippets/Timer";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
import { NumericFormat } from "react-number-format";
import daiLogo from '../../asserts/IMAGES/dai.svg';
import dai_joker from '../../asserts/IMAGES/dai_joker.svg';

/* global BigInt */
function AddLiquidity({selectedTab, balanceOfTokens}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    //usestate values
    const [jokerBalance, setJokerBalance] = useState("");
    const [etherBalance, setEtherBalance] = useState("");
    const [jokerAmount,setJokeramount] = useState("");
    const [OutputValue,setOutputValue] = useState("");
    const [jokerAllowance,setJokerAllowance] = useState("");
    const [daiAllowance,setdaiAllowance] = useState("");
    const [secondsLeft,setSecondsLeft] = useState("");
    const [lockedTrue,setLockedTrue] = useState("");
    const [LpBalance,setLpBalance] = useState("");
    const [liquidityValue,setliquidityValue] = useState("");
    const [exchangeRate,setExchangeRate] = useState([]);
    const [timePercentage, setTimePercentage] = useState(0);
    const [daiBalance, setDaiBalance] = useState("");

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);
   
    

    const getAllBalanceFunction = async() =>{
        if(selectedTab === 'Add Liquidity')
            await recordUserVisits(address, "Controlled Swap - Add Liquidity");
     
            if(isConnected){
                try{
                const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
                console.log("useeffect addliquidity");
                let jokerBalance = await getTokenBalance(address,JOKER_Token_Address,JOKER_Token_ABI);
                console.log("jokerBalance",jokerBalance)
                let etherBalance = await getGasBalance(address);
                setJokerBalance(jokerBalance);
                setEtherBalance(etherBalance);
    
                let jokerallowance = await CheckAllowance(JOKER_Token_Address,JOKER_Token_ABI,address,address);
                setJokerAllowance(jokerallowance);

                let daiallowance = await CheckAllowance(DAI_TOKEN_Address,DAI_TOKEN_ABI,address,JOKER_Token_Address);
                setdaiAllowance(daiallowance);
    
                let secondsleft = ethers.utils.formatUnits(await JOKERContract._secondsLeft(address),0);
                setSecondsLeft(await convertEpochToDateTime(secondsleft))
                setLockedTrue(secondsleft)
                console.log("Seconds left",secondsleft)
                setTimePercentage(parseInt((secondsleft / 86400) * 100));

                let daibalance = await getTokenBalance(address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
                setDaiBalance(daibalance)
    
                let lpBalance = await getTokenBalance(address,joker_DAI_LP_Address,LP_Token_ABI);
                console.log("jokerBalance",lpBalance)
                setLpBalance(lpBalance);
    
                let value = await JOKERContract.checkLiquidityValue(1*1e9);
                let ethAmounts = ethers.utils.formatUnits(value,0);
                let lpvalues = ethers.utils.formatUnits(await JOKERContract.checkLpValue(1e9,ethAmounts),0);
                let exchange ={"ethamount":(ethAmounts/1e18),"lpvalue":(lpvalues/1e18)}
                setExchangeRate(exchange);
            }catch(err){
                console.log(err)
            }
    
            }
       
       
        
    }

    useEffect(() =>{getAllBalanceFunction()},[address,isConnected, timePercentage, selectedTab]);

    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const changeinput = async(e) => {
        setJokeramount(e);
        
        try{
            const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
            console.log("values",e*1e18*exchangeRate.ethamount )
            let value = parseFloat(e*1e18*exchangeRate.ethamount).toFixed(0)
            // let value = await JOKERContract.checkLiquidityValue(e*1e9);
            // let ethAmount = ethers.utils.formatUnits(value,0);
            let ethValue = ethers.utils.formatUnits(await JOKERContract.checkLpValue(e*1e9,BigInt(value)),0);
            setliquidityValue(value/1e18);
            let feesdeductedvalue = (parseFloat(ethValue)*2/100)/1e18;
            setOutputValue((ethValue/1e18) - feesdeductedvalue)
        }
        catch(err){
            console.log(err)
            setOutputValue(0);
        }
         
    }
    const maxCall = async() =>{
        if(jokerBalance > 0){
            changeinput(jokerBalance/1e9)
        }
    }

    const approve = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const approveTxn = await JOKERContract.approve(address,BigInt(1000000000 * 1e9));
                
                await approveTxn.wait();
                await TxnId_PopUp(approveTxn.hash);

            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }

    const approvedai = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const approveTxn = await JOKERContract.approve(JOKER_Token_Address,BigInt(1000000000 * 1e18));
                
                await approveTxn.wait();
                await TxnId_PopUp(approveTxn.hash);

            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const addliquidity = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const addliquiditytxn = await JOKERContract.addLiquidityUser(BigInt(jokerAmount * 1e9), parseFloat(liquidityValue*1e18).toFixed(0));
                
                await addliquiditytxn.wait();
                await TxnId_PopUp(addliquiditytxn.hash);
                await balanceOfTokens();
                await createTxn("Joker",addliquiditytxn.hash,"Add liquidity",address,JOKER_Token_Address);
                await resetState();
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAllBalanceFunction();
    }
    const resetState = async() =>{
        setJokeramount("");
        setOutputValue("");
        setliquidityValue("");
        await balanceOfTokens();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const allowanceTrue = parseInt(jokerAllowance) >= parseInt(jokerAmount*1e9);

    const allowanceTrue1 = parseInt(daiAllowance) >= parseInt(liquidityValue*1e18);

    return ( 
        <div className="p-24 table-box app-contain">
              <Row className="justify-content-center">
                {/* <div className="box p-3 px-md-4"> */}
            {/* <Row className="g-3 g-lg-4 mb-24"> */}
                <Col md={12} style={{maxWidth: '800px'}}>
                <Table responsive striped hover>
                    <thead>
                        
                        <tr>
                            <th>Disclaimer:  Get exclusive access to unlock liquidity by contributing 2% to burnVault.</th>
                            {/* <th>Dime</th>
                            <th>Bond</th>
                            <th>Min Claim limit</th>
                            <th>Min Claim time </th> */}
                        </tr>
                    </thead>
                   
                </Table>
                    <div className="box p-3 p-md-4">
                        <div className="swap-card d-flex align-items-center">
                            <div>
                                <img src={Icon1} alt="Icon1" height={'42px'}/>
                            </div>
                            <div>
                                <h5>JOKER</h5>
                                <h6>Bal: {parseFloat(jokerBalance) === 'NaN' ? '0.00' :  formatValues((jokerBalance/1e9),2)}</h6>
                            </div>
                            
                            <div className="flex-grow-1 py-2">
                            <NumericFormat value={jokerAmount} placeholder="0.00" className="input-reset" allowNegative={false} onChange={(e) => changeinput(e.target.value)}/>
                            </div>

                            <button className="btn btn-grad py-2" onClick={()=>maxCall()}>Max</button>
                        </div>
                        <div className="py-2 text-center">
                            <button type="button" className="btn-reset"><img src={AddIcon} alt="SwapBtn" /></button>
                        </div>
                        <div className="swap-card d-flex align-items-center">
                            <div>
                                <img src={daiLogo} height={'42px'} alt="Icon1" />
                            </div>
                            <div>
                                <h5>DAI</h5>
                                <h6>Bal:  {parseFloat(daiBalance) === 'NaN' ? '0.00' :  formatValues((daiBalance/1e18),2)}</h6>
                            </div>
                            <div className="flex-grow-1 py-2">
                            <input readonly disabled  placeholder="0.00" className="input-reset" value={parseFloat(liquidityValue) ? formatValues(liquidityValue,9) : '0.00'}/>

                            </div>
                        </div>
                        <div className="py-3 text-center">
                            <button type="button" className="btn-reset"><img src={SwapBtn} alt="SwapBtn" /></button>
                        </div>
                        <div className="swap-card mb-4 d-flex align-items-center">
                            <div>
                                <img src={dai_joker} alt="Icon1" height={'42px'}/>
                            </div>
                            <div>
                                <h5>LP</h5>
                                <h6>Bal: {parseFloat(LpBalance) === 'NaN' ? '0.00' :  formatValues((LpBalance/1e18),2)}</h6>
                            </div>
                            <div className="flex-grow-1 py-2">
                            <input readonly disabled  placeholder="0.00" className="input-reset" value={parseFloat(OutputValue) ? formatValues(OutputValue,13) : '0.00'}/>

                            </div>
                        </div>

                       
{/* 
                        <div className="text-center" >
                            <p className="text-gray mb-0">Disclaimer: Get exclusive access to unlock liquidity by contributing 2% to burnVault.
                             <Tooltip text="Lorem Ipsum Content." />
                             </p>
                        </div> */}
                       {/* <div className="burn-card p-3 px-md-4"> */}
                    <div className="box swap-value border-top">
                        <div className="p-4 border-bottom">
                            <h6>Exchange Rate</h6>
                            <h4>1 &nbsp; JOKER &nbsp; + &nbsp; {exchangeRate.ethamount? formatValues(exchangeRate.ethamount,9):'0.0'} &nbsp;DAI&nbsp; = 
                            &nbsp; {exchangeRate.lpvalue? formatValues(exchangeRate.lpvalue,9):'0.0'} &nbsp;LP</h4>
                        </div>
                        <div className="p-4 border-bottom">
                            <h6>Add Liquidity Fees</h6>
                            <h4>2% &nbsp; LP</h4>
                        </div>
                        <div className="p-4 border-bottom">
                            <h6>You Will Receive</h6>
                            <h4>{OutputValue?formatValues(OutputValue,13):"0.0"} &nbsp; LP</h4>
                        </div>
                        <div className="px-4 pb-4 pt-4">
                        {/* {allowanceTrue?(<>
                            <button type="submit" className="btn btn-grad w-100 outline py-2 "><span>Approve JOKER</span></button>
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approve()}>Approve JOKER</button>
                        </>)} */}

                        {lockedTrue <= 0 ? (<>
                        {allowanceTrue ?(<>
                            {allowanceTrue1 ? (<>
                            <button  type="submit"  className=" btn mb-20 btn-grad  w-100 " onClick={()=>addliquidity()} >
                        <span>Add Liquidity</span></button>
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approvedai()}>Approve DAI</button>
                            </>)}
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approve()}>Approve JOKER</button>
                        </>)}
                        
                        </>):(<>
                            <div className="box swap-card mb-20 d-flex align-items-center p-3 p-md-4">
                            <div>
                                <h5 className="text-gray">Wallet to be unlocked in</h5>
                                <Timer count={secondsLeft?secondsLeft:'0'} />
                            </div>
                            <div className="ms-auto">
                                <ProgressBar
                                    percentage={timePercentage}
                                    startColor="#20E2D0"
                                    endColor="#00C2FF"
                                    gradientId="progress6"
                                />
                            </div>
                        </div>
                        </>)}
                           </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Col>
                
                {/* <Col md={6}>
               
                </Col> */}
            {/* </Row> */}
            {/* </div> */}
            </Row>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </div>
     );
}

export default AddLiquidity;